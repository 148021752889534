import React, { useEffect, useState } from 'react';
import { graphql, compose } from 'react-apollo';
import get from 'lodash/get';
import { addOrReplaceUrlParam, addQualityParam } from '../../helpers/add-or-replace-url-param';
import { getItemString } from '../../helpers/cookies';
import FloorplansCardContainerView from '../../components/FloorplansCardContainerView';
import FloorplansFloorplanCardView from '../../components/FloorplansFloorplanCardView';
import { propertyGalleryQuery } from '../../graphql/queries/propertyGalleryQuery';
import styles from './styles.module.css';
import defaultBanner from '../../assets/images/default-floorplan-banner.jpg';
import Spinner from '../Spinner';

import './index.css';

const moment = require('moment');

const beds = {
  0: 'Studio',
  1: '1 Bedrooms',
  2: '2 Bedrooms',
  3: '3 Bedrooms',
  4: '4 Bedrooms',
  5: '5 Bedrooms',
};

let baths = [];

baths.push({
  label: '1 Baths',
  value: [1],
});

baths.push({
  label: '1.5 Baths',
  value: [2],
});

baths.push({
  label: '2 Baths',
  value: [3],
});

baths.push({
  label: '2.5 Baths',
  value: [4],
});

baths.push({
  label: '3 Baths',
  value: [5],
});

baths.push({
  label: '3.5 Baths',
  value: [6],
});

baths.push({
  label: '4 Baths',
  value: [7],
});

const renderSizeTitle = (size) => (!size ? size : size === 0 ? 'Studios' : `${size} Bedrooms`);

function FloorplansCardContainer(props) {
  const interiorImages = get(props, 'propertyGalleryQuery.propertyGallery.interior', []);
  const [isRefetching, setRefething] = useState(false);
  useEffect(() => {
    if (props.propertyFloorplansSummary && !props.propertyFloorplansSummary.loading) {
      setRefething(true);
      props.propertyFloorplansSummary
        .refetch({
          amliPropertyId: props.property.data.amli_id,
          propertyId: props.property.id,
          moveInDate: moment(props.filters.date).format('YYYY-MM-DD'),
        })
        .then(() => setRefething(false));
    }
  }, [props.filters.date]);

  const sessionId = getItemString('session_uuid');
  const floorplans = get(props, 'propertyFloorplansSummary.propertyFloorplansSummary') || [];
  const loading = get(props, 'propertyFloorplansSummary.loading', false);
  const size = get(props, 'filters.size');
  const showAll = get(props, 'filters.showAll');
  const homeTypes = get(props, 'filters.homeTypes');
  const bedTypes = get(props, 'filters.bedTypes') || [];
  const bathRoomTypes = get(props, 'filters.bathRoomTypes') || [];
  const min = get(props, 'attributesQuery.propertyAttributes.minBeds');
  const max = get(props, 'attributesQuery.propertyAttributes.maxBeds');
  const allSizes = Object.keys(beds).filter((key) => key >= min && key <= max);
  const primaryColor = get(props, 'property.data.primary_color');

  const allAvailableSizes = allSizes
    .map((size) =>
      floorplans
        .filter((fp) => {
          if (homeTypes.length === 0) {
            return true;
          } else {
            const units = fp.units || [];
            let found = false;

            if (units.length) {
              for (let i = 0; i < units.length; i++) {
                const unit = units[i];

                if (unit.unitTypeDesignation && homeTypes.some((r) => unit.unitTypeDesignation.includes(r))) {
                  found = true;

                  break;
                }
                continue;
              }
            }

            return found;
          }
        })
        .filter((fp) => {
          let found = false;

          if (bedTypes.length > 0) {
            if (bedTypes.length) {
              bedTypes.map((bedType) => {
                if (parseInt(bedType) == fp.bedroomMin || parseInt(bedType) == fp.bedroomMax) {
                  found = true;
                }
              });
            }

            return found;
          }

          if (bedTypes == null || bedTypes.length == 0) {
            return true;
          }
        })
        .filter((fp) => {
          let found = false;

          if (bathRoomTypes.length) {
            bathRoomTypes.map((bathRoomType) => {
              if (parseFloat(bathRoomType) === parseFloat(fp.bathroomMin)) {
                found = true;

                return found;
              }
            });
          }

          if (bathRoomTypes == null || bathRoomTypes.length == 0) {
            return true;
          }

          return found;
        })
        .filter((fp) => {
          return (fp.bedroomMax === +size || fp.bedroomMin === +size) && fp.isFloorplanActive;
        }).length > 0
        ? size
        : null
    )
    .filter((it) => !!it);

  interiorImages.map((image) => {
    const width = props.pageWidth > 500 ? props.pageWidth : props.pageWidth * 1.5;
    const url = addOrReplaceUrlParam(image.image.url, 'w', width);
    image.image.url = url;
    return image;
  });

  const getTintColor = (color) => {
    const [red, green, blue] = color
      .slice(1)
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16));
    return `rgba(${red}, ${green}, ${blue}, 0.1)`;
  };

  const studioDescription =
    get(props, 'property.data.studio_intro_text') ||
    "Our studio apartments offer efficient living space with all the modern comforts you're looking for. And the AMLI team will ensure exceptional service to make living here worry-free.";
  const oneBedroomDescription =
    get(props, 'property.data.single_bedroom_intro_text') ||
    'We have a variety of 1-bedroom floor plans, all boasting great layouts and designer interiors. You are sure to find the perfect apartment home to fit your lifestyle.';
  const twoBedroomDescription =
    get(props, 'property.data.double_bedroom_intro_text') ||
    "Our distinctive 2-bedroom luxury apartments have been built for comfort with chefs' kitchens and designer finishes that you will love coming home to.";
  const threeBedroomDescription =
    get(props, 'property.data.triple_bedroom_intro_text') ||
    'Our spacious 3-bedroom apartments are the perfect setting for living your best life. Whether working from home, relaxing, or entertaining friends, you will love calling AMLI home.';

  const floorplanDescriptions = [studioDescription, oneBedroomDescription, twoBedroomDescription, threeBedroomDescription];

  return (
    <React.Fragment>
      {loading || isRefetching ? <Spinner propertyColor={primaryColor} /> : null}
      {!loading &&
        !isRefetching &&
        (true ? (
          allAvailableSizes.map(
            (floorplanSize, index) =>
              (floorplanSize == size || showAll) && (
                <FloorplansCardContainerView
                  style={{ backgroundColor: getTintColor(primaryColor) }}
                  className="floorplan-card-container"
                  key={`card-container-${index}`}
                >
                  <banner
                    className={styles.banner}
                    src={interiorImages[floorplanSize] ? addQualityParam(interiorImages[floorplanSize].image.url, 5) : defaultBanner}
                    alt={interiorImages[floorplanSize] ? interiorImages[floorplanSize].caption : 'AMLI Interior'}
                    srcSet={interiorImages[floorplanSize] ? addQualityParam(interiorImages[floorplanSize].image.url, 5) : defaultBanner}
                  />
                  <title>
                    <floorplan-size className={styles.title}>{renderSizeTitle(+floorplanSize)}</floorplan-size>
                    <floorplan-description>{floorplanDescriptions[+floorplanSize]}</floorplan-description>
                  </title>
                  <floorplan-grid className={styles.grid}>
                    <floorplansFloorplanCardViewList0>
                      {floorplans
                        .filter((fp) => (fp.bedroomMax === +floorplanSize || fp.bedroomMin === +floorplanSize) && fp.isFloorplanActive)
                        .filter((fp) => {
                          if (homeTypes.length === 0) {
                            return true;
                          } else {
                            const units = fp.units || [];
                            let found = false;
                            if (units.length) {
                              for (let i = 0; i < units.length; i++) {
                                const unit = units[i];
                                if (unit.unitTypeDesignation && homeTypes.some((r) => unit.unitTypeDesignation.includes(r))) {
                                  found = true;
                                  break;
                                }
                                continue;
                              }
                            }
                            return found;
                          }
                        })
                        .filter((fp) => {
                          let found = false;

                          if (bathRoomTypes.length) {
                            bathRoomTypes.map((bathRoomType) => {
                              if (parseFloat(bathRoomType) === parseFloat(fp.bathroomMin)) {
                                found = true;

                                return found;
                              }
                            });
                          }

                          if (bathRoomTypes == null || bathRoomTypes.length == 0) {
                            return true;
                          }

                          return found;
                        })
                        .filter((fp) => {
                          let found = false;

                          if (bathRoomTypes.length) {
                            bathRoomTypes.map((bathRoomType) => {
                              if (parseFloat(bathRoomType) === parseFloat(fp.bathroomMin)) {
                                found = true;

                                return found;
                              }
                            });
                          }

                          if (bathRoomTypes == null || bathRoomTypes.length == 0) {
                            return true;
                          }

                          return found;
                        })
                        .sort((a, b) => +a.displayOrder - +b.displayOrder)
                        .map((floorplan) => (
                          <FloorplansFloorplanCardView.Controller
                            floorplan={floorplan}
                            key={floorplan.floorplanId}
                            moveInDate={props.filters.date}
                            property={props.property}
                            size={floorplanSize}
                            sessionId={sessionId}
                          />
                        ))}
                    </floorplansFloorplanCardViewList0>
                  </floorplan-grid>
                </FloorplansCardContainerView>
              )
          )
        ) : (
          <FloorplansCardContainerView>
            <banner
              className={styles.banner}
              src={interiorImages[size] ? addQualityParam(interiorImages[size].image.url, 5) : defaultBanner}
              alt={interiorImages[size] ? interiorImages[size].caption : 'Interior View'}
              srcSet={interiorImages[size] ? addQualityParam(interiorImages[size].image.url, 5) : defaultBanner}
            />
            <title className={styles.title}>{renderSizeTitle(size)}</title>
            <floorplan-grid className={styles.grid}>
              <floorplansFloorplanCardViewList0>
                {floorplans
                  .sort((a, b) => +a.displayOrder - +b.displayOrder)
                  .filter((fp) => (fp.bedroomMax === +size || fp.bedroomMin === +size) && fp.isFloorplanActive)
                  .filter((fp) => {
                    if (homeTypes.length === 0) {
                      return true;
                    } else {
                      const units = fp.units || [];
                      let found = false;
                      if (units.length) {
                        for (let i = 0; i < units.length; i++) {
                          const unit = units[i];
                          if (unit.unitTypeDesignation && homeTypes.some((r) => unit.unitTypeDesignation.includes(r))) {
                            found = true;
                            break;
                          }
                          continue;
                        }
                      }
                      return found;
                    }
                  })
                  .map((floorplan) => (
                    <FloorplansFloorplanCardView.Controller
                      floorplan={floorplan}
                      key={floorplan.floorplanId}
                      moveInDate={props.filters.date}
                      property={props.property}
                      size={size}
                    />
                  ))}
              </floorplansFloorplanCardViewList0>
            </floorplan-grid>
          </FloorplansCardContainerView>
        ))}
    </React.Fragment>
  );
}

export default compose(
  graphql(propertyGalleryQuery, {
    name: 'propertyGalleryQuery',
    skip: (props) => !props || !props.property.id,
    options: (props) => ({
      variables: {
        id: props.property.id,
      },
    }),
  })
)(FloorplansCardContainer);
