import React from "react";
import styles from "./styles.module.css";
import CloseWithBackground from "../../assets/icons/close_with_Background.svg"
import { canUseDOM } from "../../helpers/utils";

const ToolTip = props => {
    let rootElem = null;
    if (canUseDOM) {
        //const rootElem = document.body; // this does not work?
        rootElem = document.getElementById("root");
    }
    const [isShown, setIsShown] = React.useState(false);
    const showToolTip = () => {
        setIsShown(true);
        if (canUseDOM) {
            //rootElem.classList.add("noScroll"); // this does not work?
            if (props.isMobile) rootElem.style.position = "fixed";
        }
    }
    const hideToolTip = (e) => {
        e.stopPropagation();
        setIsShown(false);
        if (canUseDOM) {
            //rootElem.classList.remove("noScroll"); // this does not work?
            rootElem.style.position = "";
        }
    }

    return (
        <div 
            className={styles.toolTipContainer}
            onMouseEnter={props.isMobile ? null : showToolTip}
            onMouseLeave={props.isMobile ? null : hideToolTip}
        >
        <div onClick={showToolTip}>
            {props.toolTipElement}
        </div>
        {isShown && (
            <>
                <div className={props.isMobile ? styles.toolTipTextMobile : (props.toolTipPosition == 'top' ? styles.toolTipTextTop : styles.toolTipTextBottom)}>
                    <img
                        alt="close"
                        src={CloseWithBackground}
                        tabIndex={0}
                        className={styles.toolTipClose}
                        onClick={hideToolTip}                        
                    />
                    {props.toolTipText}
                </div>
                {!props.isMobile && (
                    <div className={props.toolTipPosition == 'top' ? styles.toolTipArrowTop : styles.toolTipArrowBottom} />
                )}
                {props.isMobile && (
                    <div className={styles.overlay}></div>
                )}
            </>
        )}
        </div>
    );
}
  
export default ToolTip;