const RemoveBetterbot = () => {
    const removeAllFromDOM = id => {
      let elem = document.getElementById(id);
      while(!!elem) {
        elem.remove();
        elem = document.getElementById(id);
      }
    }
    const removeAllClassFromDOM = (elms) => elms.forEach(el => el.remove());
    try {
        removeAllFromDOM('betterbot_bot-chat');
        removeAllFromDOM('betterbot_myCss');
        removeAllClassFromDOM(document.querySelectorAll(".betterbot"));
        removeAllClassFromDOM(document.querySelectorAll(".betterbot_button-wrapper"));
        removeAllClassFromDOM(document.querySelectorAll(".betterbot_bot-container"));
    } catch(ex) {
    }    
};
export default RemoveBetterbot;