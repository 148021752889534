import React from 'react';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import Helmet from 'react-helmet-async';
import { graphql, compose } from 'react-apollo';
import { Carousel } from 'react-responsive-carousel';
import { withRouter } from 'react-router';
import ReactPlayer from 'react-player';
import PropertyHeaderView from '../../components/PropertyHeaderView';
import queryString from 'query-string';
import { setItem, getItem, getItemString } from '../../helpers/cookies';
import styles from './styles.module.css';
import { propertyAttributesQuery, propertyGalleryQuery } from '../../graphql/queries';
import { formatDollar } from '../../helpers/utils';
import { composeApplyLink } from '../../helpers/property/apply';
import MultiLineString from '../../helpers/mutli-line-string';
import TourModal from '../TourModalController';
import SelfTourModal from '../SelfTourModalController';
import getDniPhone from '../../helpers/get-dni-phone';
import { handlePrismicLink } from '../../helpers/link-helper';
import { canUseDOM } from '../../helpers/utils';
import RemoveBetterbot from '../../helpers/remove-betterbot-scripts';
import { EventViewMedia } from '../../helpers/plugins/guestAnalytics.js';
import { addOrReplaceUrlParam, addQualityParam } from '../../helpers/add-or-replace-url-param';
import { personaBackgroundColorStyle } from '../../helpers/property/persona';
import Info from '../../assets/icons/help.png';
import './index.css';
import StarRatings from 'react-star-ratings';
import ToolTip from '../ToolTip/index';

const moment = require('moment-timezone');

const _renderConfiguration = attributes => {
  if (!attributes.minBeds && !attributes.maxBeds) {
    return null;
  }
  if (attributes.minBeds === attributes.maxBeds) {
    return attributes.minBeds === 0 ? 'Studio' : `${attributes.minBeds} Bedrooms`;
  } else {
    return `${attributes.minBeds === 0 ? 'Studio' : `${attributes.minBeds}`} - ${attributes.maxBeds} Bedrooms`;
  }
};
class PropertyHeader extends React.Component {
  _isMounted = false;
  intervalChecked = null;
  constructor(props) {
    super(props);
    if (canUseDOM) {
      RemoveBetterbot();
    }
    this.thumbsRef = React.createRef();
    this.playerRef = React.createRef();
    this.state = {
      settings: {
        showThumbs: false,
        showArrows: true,
        showIndicators: false,
        infiniteLoop: true,
        showStatus: false,
        centerSlidePercentage: 60,
        useKeyboardArrows: true,
        swipeScrollTolerance: 20
      },
      showThumbs: false,
      showTourModal: false,
      showSelfTourModal: false,
      tourSelected: 0,
      playTour: false,
      selected: 0,
      phone: get(props, 'property.data.phone'),
      showDisclaimer: false,
      showCustomCloseButton: false,
      showBetterbotPixel: get(props, 'property.data.show_betterbot_pixel')
    };

    this.toggleThumbs = this.toggleThumbs.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    let switchCode;
    let referralSite;
    const { switch_code } = queryString.parse(this.props.location.search);
    if (switch_code) {
      setItem('switch_code', switch_code);
    }
    referralSite = getItemString('referralSite');
    switchCode = getItem('switch_code');
    if (!switchCode && !referralSite && typeof document !== 'undefined') {
      referralSite = document.referrer;
    }
    if (!!switchCode || !!referralSite) {
      getDniPhone(get(this.props, 'property.data.entrataPropertyId'), switchCode, referralSite).then(phone => {
        if (this._isMounted) {
          if (phone) {
            this.setState({ phone });
          }
        }
      });
    }
    if (canUseDOM) {
      window.dataLayer.push({
        event: 'propertyHeaderDidMount'
      });
    }
    this.intervalChecked = setInterval(() => {
      this.checkForEntrataContainer();
    }, 1000);
  }

  componentWillUnmount() {
    if (this.intervalChecked) {
      clearInterval(this.intervalChecked);
    }
    this._isMounted = false;
    if (canUseDOM) {
      RemoveBetterbot();
    }
  }

  componentDidUpdate() {
    this.playerPause();
  }

  checkForEntrataContainer() {
    if (canUseDOM) {
      const container = document.getElementById('ppcb_container');
      const height = get(container, 'style.height', '');
      if (container && height !== '0px') {
        this.setState({ showCustomCloseButton: true });
        clearInterval(this.intervalChecked);
      }
    }
  }

  playerPause = () => {
    if (
      this.playerRef &&
      this.playerRef.current &&
      this.playerRef.current.player.isPlaying &&
      this.state.selected !== this.playerRef.current.player.props.index
    ) {
      this.playerRef.current.player.player.pause();
    }
  };

  getImages = () => {
    const gallery = get(this.props, 'propertyGalleryQuery.propertyGallery');
    const images = [
      get(gallery, 'exterior', []).filter(img => !!img && !!img && !!img.image && !!img.image.url),
      get(gallery, 'interior', []).filter(img => !!img && !!img && !!img.image && !!img.image.url),
      get(gallery, 'videos', []).filter(vid => !!vid && !!vid && !!vid.video && !!vid.video.embed_url)
    ];
    const mainImage = get(this.props, 'property.data.primary_property_image');
    if (!!mainImage) {
      images.unshift({
        image: mainImage
      });
    }
    return images;
  };

  resizeImages = (images, size) => {
    const resizedImages = images.map(image => {
      if (image && image.image && image.image.url) {
        const newImage = Object.assign({}, image);
        const newChildImage = Object.assign({}, image.image);
        newImage.image = newChildImage;
        const url = addOrReplaceUrlParam(newImage.image.url, 'w', size);
        const new_url = addOrReplaceUrlParam(url, 'h', 'auto');
        newImage.image.url = new_url;
        return newImage;
      }
      return image;
    });
    return resizedImages;
  };

  scrollThumbs = (index, imgs) => {
    const images = imgs || this.getImages();
    if (this.thumbsRef && this.thumbsRef.current && this.thumbsRef.current.getBoundingClientRect) {
      const slack = index === images.length - 1 ? 0 : 25;
      this.thumbsRef.current.scrollTo({
        top: Math.max(0, index) * 150 - slack,
        left: Math.max(0, index) * 275,
        behavior: 'smooth'
      });
    }
  };

  changedSlide = async selected => {
    await this.setState({ selected });
    this.scrollThumbs(selected);
  };

  openWidget = () => {
    if (!!window && window.ppcb) {
      window.ppcb.open('schedule-tour');
    }
  };

  toggleThumbs() {
    this.setState({
      ...this.state,
      showThumbs: !this.state.showThumbs
    });
  }

  renderCategories = (imagesDeep, property, images, firstInteriorIndex, firstVideoIndex, mobileOnly = false) => {
    return (!mobileOnly || this.state.showThumbs) &&
      ((imagesDeep[1] && imagesDeep[1].length > 0) || (imagesDeep[2] && imagesDeep[2].length > 0) || (imagesDeep[3] && imagesDeep[3].length > 0)) ? (
      <div
        style={personaBackgroundColorStyle(property)}
        className={[styles.galleryCategoryRow, mobileOnly ? styles.categoryMobile : styles.categoryDesktop].join(' ')}
      >
        {imagesDeep[1] && imagesDeep[1].length > 0 ? (
          <button
            style={personaBackgroundColorStyle(property)}
            onClick={() => {
              this.setState({ selected: 0 });
              this.scrollThumbs(0, images);
            }}
            className={styles.galleryCategoryButton}
          >
            Amenities
          </button>
        ) : null}
        {imagesDeep[2] && imagesDeep[2].length > 0 ? (
          <button
            style={personaBackgroundColorStyle(property)}
            onClick={() => {
              this.setState({ selected: firstInteriorIndex });
              this.scrollThumbs(firstInteriorIndex, images);
            }}
            className={styles.galleryCategoryButton}
          >
            Interiors
          </button>
        ) : null}
        {imagesDeep[3] && imagesDeep[3].length > 0 ? (
          <button
            style={personaBackgroundColorStyle(property)}
            onClick={() => {
              this.setState({ selected: firstVideoIndex });
              this.scrollThumbs(firstVideoIndex, images);
            }}
            className={styles.galleryCategoryButton}
          >
            Videos
          </button>
        ) : null}
      </div>
    ) : null;
  };

  render() {
    const customPhoneNumber = get(this.props, 'property.data.custom_phone_number');
    const customPhoneNumberText = get(this.props, 'property.data.custom_phone_number_text');

    const hideEntrataWidget = get(this.props, 'property.data.hide_entrata_widget');
    const { property } = this.props;
    const tours = get(this.props, 'property.data.3d_tours', []).filter(tour => !!tour && !!tour.enabled && tour.enabled === 'True');
    const hasTourLinks = tours.find(tour => tour.enabled === 'True' && tour.tour_link && tour.tour_link.url);
    const imagesDeep = this.getImages();
    const images = flatten(imagesDeep);
    const mobileWidth = this.props.pageWidth < 500 ? '1300' : '1650';
    const width = this.props.pageWidth > 1600 ? '2000' : mobileWidth;
    const mediaGalleryImages = this.resizeImages(images, '500');
    const headerImages = this.resizeImages(images, width);
    const extImagesLength = imagesDeep[1] && imagesDeep[1].length;
    const intImagesLength = imagesDeep[2] && imagesDeep[2].length;
    const firstInteriorIndex = extImagesLength + (imagesDeep[0] ? 1 : 0);
    const firstVideoIndex = extImagesLength + intImagesLength + (imagesDeep[0] ? 1 : 0);

    const subregions = get(this.props, 'property.data.sub-regions', []);
    const subregion = subregions.length
      ? this.props.appdata.subregions.find(sr => !!subregions[0]['sub-region'] && sr.uid === subregions[0]['sub-region'].uid)
      : null;
    const address = get(this.props, 'property.data.address');
    const name = get(this.props, 'property.data.name');
    const phone = this.state.phone;
    const movein = get(this.props, 'property.data.move_in_date');
    const attributes = get(this.props, 'attributesQuery.propertyAttributes') || {};

    const engrainId = get(this.props, 'property.data.engrain_id');
    const sightmapBaseURL = 'https://sightmap.com/embed/';

    const sitePlan = engrainId ? sightmapBaseURL + engrainId : null;

    const minPrice = get(this.props, 'attributesQuery.propertyAttributes.minPrice');
    const maxPrice = get(this.props, 'attributesQuery.propertyAttributes.maxPrice');

    const region = subregion
      ? this.props.appdata.regions.find(reg => !!subregion && !!subregion.data && !!subregion.data.region && reg.uid === subregion.data.region.uid)
      : null;
    let subRegionBreadCrumb;
    const referralPage = get(this.props, 'referralPage');
    if (!!referralPage && referralPage.type === 'sub-region' && referralPage.data.region.uid === region.uid) {
      subRegionBreadCrumb = referralPage;
    } else if (!!referralPage && referralPage.type !== 'sub-region') {
      subRegionBreadCrumb = null;
    } else {
      subRegionBreadCrumb = subregion;
    }
    const breadcrumbs = [
      { label: 'AMLI Home', value: '/' },
      region ? { label: region.data.name, value: `/apartments/${region.uid}` } : null,
      subRegionBreadCrumb
        ? {
            label: subRegionBreadCrumb.data.name,
            value: `/apartments/${get(subRegionBreadCrumb, 'data.region.uid')}/${subRegionBreadCrumb.uid}-apartments`
          }
        : null,
      { label: `${name} Apartments`, value: this.props.location.pathname }
    ].filter(item => !!item && !!item.label);

    const primaryColor = get(this.props, 'page.primary_color') || get(this.props, 'property.data.primary_color');
    const secondaryColor = get(this.props, 'page.secondary_color') || get(this.props, 'property.data.secondary_color');
    const rating = get(this.props, 'attributesQuery.propertyAttributes.reviews.rating');
    const reviews = get(this.props, 'attributesQuery.propertyAttributes.reviews.reviews');
    const specials =
      (get(this.props, 'page.specials') || []).filter(special =>
        moment
          .tz(special.expiration_date, 'MM/DD/YYYY', 'America/Chicago')
          .endOf('day')
          .isAfter(moment().tz('America/Chicago'))
      ).length > 0;
    const specialsList = (get(this.props, 'page.specials') || []).filter(special =>
      moment
        .tz(special.expiration_date, 'MM/DD/YYYY', 'America/Chicago')
        .endOf('day')
        .isAfter(moment().tz('America/Chicago'))
    );
    const currentImage = images[this.state.selected];
    let user = getItem('user');

    const buttonText = get(this.props, 'page.buttontext') || get(this.props, 'property.data.buttontext') || 'Schedule a Tour';

    const goDark = get(property, 'data.go_dark', '');
    const selfGuidedTourType = get(property, 'data.self_guided_tour_type') || 'None';
    const enableSelfGuidedTours = selfGuidedTourType !== 'None';
    const propertyUid = get(property, 'uid');
    const smartRentId = get(property, 'data.smart_rent_id');

    let betterbotData = 'AMLI';
    if (this.state.showBetterbotPixel) {
      try {
        betterbotData = get(this.props, 'property.data.betterbotlink.url');
      } catch (e) {}
    }

    return (
      <React.Fragment>
        <Helmet>
          <script type="application/ld+json">{`
            {
              "@context": "http://schema.org/",
              "@type": "LocalBusiness",
              "additionalType": "ApartmentComplex",
              "name": "${name}",
              "address": "${address}",
              "telephone": "${phone}",
              "image": "${property.data.logo.url}",
              "priceRange": "${minPrice} - ${maxPrice}",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "${rating}",
                "reviewCount": "${reviews}"
              }
          }
        `}</script>
        </Helmet>
        <PropertyHeaderView>
          <slider
            style={{ backgroundImage: 'none', zIndex: 1 }}
            className={[this.state.showThumbs ? 'd-flex' : '', styles.container].join(' ')}
            topelement={() => (
              <>
                <TourModal
                  tours={tours}
                  selected={this.state.tourSelected}
                  open={this.state.showTourModal}
                  onRequestClose={() => this.setState({ showTourModal: false })}
                  play={this.state.playTour}
                  setSelected={selected => this.setState({ tourSelected: selected, playTour: false })}
                  startPlay={() => this.setState({ playTour: true })}
                  title={name}
                />
                <SelfTourModal
                  open={this.state.showSelfTourModal}
                  onRequestClose={() => this.setState({ showSelfTourModal: false })}
                  smartRentId={smartRentId}
                  tourProvider={selfGuidedTourType === 'Smart Rent' ? 'smartrent' : 'tour24'}
                  propertyUid={propertyUid}
                  title="Self-Guided Tours"
                />
                <Carousel
                  className="property-header-full-carousel"
                  {...this.state.settings}
                  selectedItem={this.state.selected}
                  onChange={this.changedSlide}
                >
                  {headerImages.map((item, index) => (
                    <div className={styles.image} key={`image_${index}`}>
                      {item.image ? (
                        <img className={styles.sliderImage} src={item.image.url} alt="property" />
                      ) : (
                        <ReactPlayer
                          ref={this.playerRef}
                          index={index}
                          className={styles.galleryVideo}
                          controls={true}
                          url={item.video.embed_url}
                          width="100%"
                          height="100%"
                        />
                      )}
                    </div>
                  ))}
                </Carousel>
                <div
                  className={[styles.imageCaption, this.state.showThumbs ? styles.captionThumbs : null, movein ? styles.captionMoveIn : null].join(
                    ' '
                  )}
                >
                  {currentImage.caption ? currentImage.caption : currentImage.video ? currentImage.video.title : name}
                  <span className={styles.imageCounter}>
                    {this.state.selected + 1}/{images.length}
                  </span>
                </div>
                {this.renderCategories(imagesDeep, property, images, firstInteriorIndex, firstVideoIndex, true)}
                <div
                  className={[styles.thumbContainer, this.state.showThumbs ? styles.thumbContainerOpen : ''].join(' ')}
                  style={this.state.showThumbs ? {} : { flex: '0 0 0px' }}
                  ref={this.thumbsRef}
                >
                  {this.renderCategories(imagesDeep, property, images, firstInteriorIndex, firstVideoIndex)}
                  {mediaGalleryImages.map((item, index) => {
                    if (extImagesLength > 0 && index === 0) {
                      return (
                        <div className={styles.thumbWithHeaderContainerFirst}>
                          <div className={styles.thumbHeaderFirst} style={{ color: secondaryColor }}>
                            Amenities
                          </div>
                          <div
                            tabIndex={0}
                            onKeyDown={async e => {
                              if (e.keyCode !== 13) {
                                return;
                              }
                              await this.setState({ selected: index });
                              this.scrollThumbs(index, images);
                            }}
                            onClick={async () => {
                              await this.setState({ selected: index });
                              this.scrollThumbs(index, images);
                            }}
                            className={styles.thumbItem}
                            key={`thumb_${index}`}
                          >
                            <img
                              className={[styles.sliderThumb, this.state.selected !== index ? styles.inactiveThumb : ''].join(' ')}
                              src={item.image ? addQualityParam(item.image.url, 5) : addQualityParam(item.video.thumbnail_url, 5)}
                              alt={(item.image ? item.image.alt : item.video.title) || ''}
                            />
                          </div>
                        </div>
                      );
                    } else if (index === firstInteriorIndex) {
                      return (
                        <div className={styles.thumbWithHeaderContainer}>
                          <div style={{ color: secondaryColor }} className={styles.thumbHeader}>
                            Interiors
                          </div>
                          <div
                            tabIndex={0}
                            onKeyDown={async e => {
                              if (e.keyCode !== 13) {
                                return;
                              }
                              await this.setState({ selected: index });
                              this.scrollThumbs(index, images);
                            }}
                            onClick={async () => {
                              await this.setState({ selected: index });
                              this.scrollThumbs(index, images);
                            }}
                            className={styles.thumbItem}
                            key={`thumb_${index}`}
                          >
                            <img
                              className={[styles.sliderThumb, this.state.selected !== index ? styles.inactiveThumb : ''].join(' ')}
                              src={item.image ? addQualityParam(item.image.url, 5) : addQualityParam(item.video.thumbnail_url, 5)}
                              alt={(item.image ? item.image.alt : item.video.title) || ''}
                            />
                          </div>
                        </div>
                      );
                    } else if (imagesDeep[3] && imagesDeep[3].length > 0 && index === firstVideoIndex) {
                      return (
                        <div className={styles.thumbWithHeaderContainer}>
                          <div style={{ color: secondaryColor }} className={styles.thumbHeader}>
                            Video
                          </div>
                          <div
                            tabIndex={0}
                            onKeyDown={async e => {
                              if (e.keyCode !== 13) {
                                return;
                              }
                              await this.setState({ selected: index });
                              this.scrollThumbs(index, images);
                            }}
                            onClick={async () => {
                              await this.setState({ selected: index });
                              this.scrollThumbs(index, images);
                            }}
                            className={styles.thumbItem}
                            key={`thumb_${index}`}
                          >
                            <img
                              className={[styles.sliderThumb, this.state.selected !== index ? styles.inactiveThumb : ''].join(' ')}
                              src={item.image ? addQualityParam(item.image.url, 5) : addQualityParam(item.video.thumbnail_url, 5)}
                              alt={(item.image ? item.image.alt : item.video.title) || ''}
                            />
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          tabIndex={0}
                          onKeyDown={async e => {
                            if (e.keyCode !== 13) {
                              return;
                            }
                            await this.setState({ selected: index });
                            this.scrollThumbs(index, images);
                          }}
                          onClick={async () => {
                            await this.setState({ selected: index });
                            this.scrollThumbs(index, images);
                          }}
                          className={styles.thumbItem}
                          key={`thumb_${index}`}
                        >
                          <img
                            className={[styles.sliderThumb, this.state.selected !== index ? styles.inactiveThumb : ''].join(' ')}
                            src={item.image ? addQualityParam(item.image.url, 5) : addQualityParam(item.video.thumbnail_url, 5)}
                            alt={(item.image ? item.image.alt : item.video.title) || ''}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
              </>
            )}
          >
            {!this.state.showThumbs && (
              <property-breadcrumb className={styles.breadcrumbContainer}>
                <span style={{ position: 'absolute', top: -10, height: 0 }} ref={el => (this.instance = el)} />
                {breadcrumbs
                  .filter(itm => !!itm.label)
                  .map((item, index) => (
                    <span key={`link_${index}`} className={styles.crunchContainer}>
                      {index !== 0 && <div className="af-class-breadcrumb-divider" />}
                      <a
                        href={item.value}
                        onClick={e => {
                          if (!!e && !!e.preventDefault) {
                            e.preventDefault();
                          }
                          this.props.history.push(item.value);
                        }}
                        className="af-class-phase-2-breadcrumb-link"
                      >
                        {item.label}
                      </a>
                    </span>
                  ))}
              </property-breadcrumb>
            )}
            {!this.state.showThumbs && (
              <property-infot-banner
                className={styles.bannerContainer}
                topelement={() => (
                  <div className="af-class-property-info-card-wrapper property-details-card-wrapper-mobile">
                    {!!movein && (
                      <div className="af-class-move-in-date-wrapper" style={personaBackgroundColorStyle(property)}>
                        <div className="af-class-text-block-33" style={personaBackgroundColorStyle(property)}>
                          We'll be move-in ready
                        </div>
                        <div className="af-class-text-block-34" style={personaBackgroundColorStyle(property)}>
                          {movein && movein}
                        </div>
                        <div className="af-class-move-in-date-dash-line" />
                      </div>
                    )}
                    <div className="af-class-property-info-card-details-mobile">
                      <div className="mobile-property-card-top-row">
                        <h2 className="af-class-property-phase--property-name">{name}</h2>
                        {!!reviews && !!rating ? (
                          <div className={`af-class-star-rating-wrapper ${styles.starsContainer}`}>
                            <StarRatings
                              rating={rating}
                              starRatedColor="#ecd925"
                              starDimension="19px"
                              starSpacing="1px"
                              numberOfStars={5}
                              name="rating"
                            />
                            <span className={styles.reviews}>({reviews || 0})</span>
                          </div>
                        ) : null}
                        {specials && (
                          <ToolTip
                            toolTipElement={
                              <div className={`af-class-div-block-157 mobile-specials-stamp ${styles.specials}`}>
                                <div>Current Special&nbsp;</div>
                                <img src="/images/icon-special-fire3x.svg" alt="specials icon" className="af-class-image-28" />
                              </div>
                            }
                            toolTipText={specialsList.map(special => (
                              <div>
                                <div className={styles.specialText}>{special.special_text}</div>
                                <br />
                                {/*<div className={styles.specialExpDate}>Expires {moment(special.expiration_date).format('MM/DD/YY')}</div>*/}
                              </div>
                            ))}
                            isMobile={true}
                          />
                        )}
                      </div>
                      <div className="mobile-property-card-middle-row">
                        <div className="left-column">
                          <div className="af-class-text-block-39">{MultiLineString(address)}</div>
                          <div className="af-class-text-block-1000 mobile-card-phone-heading">
                            {customPhoneNumber && phone ? customPhoneNumberText : ''}
                          </div>
                          <div className="af-class-text-block-38 mobile-card-phone">{phone ? <a href={`tel:${phone}`}>{phone}</a> : phone}</div>
                        </div>
                        <div className="af-class-div-block-130">
                          <div className="af-class-text-block-35">{_renderConfiguration(attributes)}</div>
                          <div className="af-class-text-block-37">Available Units Start at</div>
                          <div className={`af-class-text-block-36 ${styles.pricingWrapper}`}>
                            {minPrice && `$${formatDollar(minPrice, 0)}`}
                            <ToolTip
                              toolTipElement={<img tabIndex={0} src={Info} alt="more info" className={styles.infoIcon} />}
                              toolTipText={
                                <div className={styles.infoText}>
                                  "Available Units Start at” reflects pricing for the lowest rent offered of an available unit at the Community
                                  regardless of the number of bedrooms. Unavailable units may come online with lower rent, so check back often.
                                </div>
                              }
                              isMobile={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={['mobile-property-card-bottom-row', styles.bottomRowMobile].join(' ')}
                        onClick={() => this.setState({ showCustomCloseButton: true })}
                      >
                        <a
                          style={{ backgroundColor: secondaryColor }}
                          className={styles.visit}
                          {...handlePrismicLink(this.props, {}, { propertyData: property }, goDark !== 'Yes' ? buttonText : 'goDark')}
                        >
                          <div className={styles.visitLabel}>{buttonText}</div>
                          <img
                            src="/images/icon-arrow--right-no-shadow3x.png"
                            alt="Arrow icon."
                            className={['af-class-boss-right-arrow', styles.buttonArrow].join(' ')}
                          />
                        </a>
                        {goDark !== 'Yes' ? (
                          <a
                            href={property && composeApplyLink(property.data) ? composeApplyLink(property.data) : '#'}
                            onClick={e => {
                              if (e) {
                                e.preventDefault();
                              }
                              if (composeApplyLink(property.data) && composeApplyLink(property.data) === '/create-profile') {
                                this.props.history.push('/create-profile', {
                                  property
                                });
                              } else if (composeApplyLink(property.data)) {
                                window.open(composeApplyLink(property.data));
                              }
                            }}
                            className={['af-class-button-7 w-button', styles.apply].join(' ')}
                            style={{ backgroundColor: primaryColor }}
                          >
                            {user ? 'My Account/Apply' : 'Apply'}
                          </a>
                        ) : null}
                        {enableSelfGuidedTours && (
                          <div className={styles.selfTourWrapperMobile}>
                            <a
                              className={styles.selfTour}
                              href={
                                selfGuidedTourType === 'Smart Rent'
                                  ? `https://selftournow.com/property/${smartRentId}`
                                  : `https://app.tour24now.com/tour/${propertyUid.replace(/\-/g, '')}`
                              }
                              onClick={e => {
                                e.preventDefault();
                                this.setState({ showSelfTourModal: true });
                              }}
                            >
                              Schedule a Self-Guided Tour
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              >
                {enableSelfGuidedTours && (
                  <p>
                    <a
                      className={styles.selfTour}
                      href={
                        selfGuidedTourType === 'Smart Rent'
                          ? `https://selftournow.com/property/${smartRentId}`
                          : `https://app.tour24now.com/tour/${propertyUid.replace(/\-/g, '')}`
                      }
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ showSelfTourModal: true });
                      }}
                    >
                      Schedule a Self-Guided Tour
                    </a>
                  </p>
                )}
                {!!movein && (
                  <move-in style={personaBackgroundColorStyle(property)}>
                    <property-info-bar-slogan style={personaBackgroundColorStyle(property)} />
                    <property-info-bar-date style={personaBackgroundColorStyle(property)}>{movein && movein}</property-info-bar-date>
                  </move-in>
                )}
                <property-info-bar-top-row className={styles.topWrapper}>
                  <h2 className={`af-class-property-phase--property-name ${styles.name}`}>{name}</h2>
                  {!!reviews && !!rating ? (
                    <div className={`af-class-star-rating-wrapper ${styles.starsContainer}`}>
                      <StarRatings rating={rating} starRatedColor="#ecd925" starDimension="19px" starSpacing="3px" numberOfStars={5} name="rating" />
                      <span className={styles.reviews}>({reviews || 0})</span>
                    </div>
                  ) : null}
                </property-info-bar-top-row>
                <property-info-bar-property-address className={styles.address}>{MultiLineString(address)}</property-info-bar-property-address>
                <property-info-bar-property-number-heading>
                  {customPhoneNumber && phone ? customPhoneNumberText : ''}
                </property-info-bar-property-number-heading>
                <property-info-bar-property-number className={styles.phone}>
                  {phone ? <a href={`tel:${phone}`}>{phone}</a> : phone}
                </property-info-bar-property-number>
                <property-info-card-right-wrapper
                  className={styles.rightWrapper}
                  topelement={() =>
                    specials && (
                      <ToolTip
                        toolTipElement={
                          <div className={`af-class-div-block-157 ${styles.specials}`}>
                            <div>Current Special&nbsp;</div>
                            <img src="/images/icon-special-fire3x.svg" alt="specials icon" className="af-class-image-28" />
                          </div>
                        }
                        toolTipText={specialsList.map(special => (
                          <div>
                            <div className={styles.specialText}>{special.special_text}</div>
                            <br />
                            {/*<div className={styles.specialExpDate}>Expires {moment(special.expiration_date).format('MM/DD/YY')}</div>*/}
                          </div>
                        ))}
                        isMobile={false}
                        toolTipPosition={'top'}
                      />
                    )
                  }
                >
                  <property-info-bar-property-rooms>{_renderConfiguration(attributes)}</property-info-bar-property-rooms>
                  <property-info-bar-property-starting-label>Available Units Start at</property-info-bar-property-starting-label>
                  <property-info-bar-property-starting-at className={styles.pricingWrapper}>
                    {minPrice && `$${formatDollar(minPrice, 0)}`}
                    <ToolTip
                      toolTipElement={<img tabIndex={0} src={Info} alt="more info" className={styles.infoIcon} />}
                      toolTipText={
                        <div className={styles.infoText}>
                          "Available Units Start at” reflects pricing for the lowest rent offered of an available unit at the Community regardless of
                          the number of bedrooms. Unavailable units may come online with lower rent, so check back often.
                        </div>
                      }
                      isMobile={false}
                      toolTipPosition={'top'}
                    />
                    <p onClick={() => this.setState({ showCustomCloseButton: true })}>
                      <a
                        style={{ backgroundColor: secondaryColor }}
                        className={styles.visit}
                        {...handlePrismicLink(this.props, {}, { propertyData: property }, goDark !== 'Yes' ? buttonText : 'goDark')}
                      >
                        <img
                          src="/images/icon-arrow--right-no-shadow3x.png"
                          alt="Arrow icon."
                          className={['af-class-boss-right-arrow', styles.buttonArrow].join(' ')}
                        />
                        <span className={styles.visitLabel}>{buttonText}</span>
                      </a>
                    </p>
                  </property-info-bar-property-starting-at>
                </property-info-card-right-wrapper>
                {enableSelfGuidedTours && (
                  <self-guided-tour className={styles.selfTourWrapper}>
                    <a
                      className={styles.selfTour}
                      href={
                        selfGuidedTourType === 'Smart Rent'
                          ? `https://selftournow.com/property/${smartRentId}`
                          : `https://app.tour24now.com/tour/${propertyUid.replace(/\-/g, '')}`
                      }
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ showSelfTourModal: true });
                      }}
                    >
                      <img
                        src="/images/icon-arrow--right-no-shadow3x.png"
                        alt="Arrow icon."
                        className={['af-class-boss-right-arrow', styles.buttonArrow].join(' ')}
                      />
                      <span className={styles.selfTourLabel}>Schedule a Self-Guided Tour</span>
                    </a>
                  </self-guided-tour>
                )}
                <button-container className={[styles.buttonContainer, !sitePlan && !hasTourLinks && styles.oneButton].join(' ')}>
                  <all-photos
                    className={`view-all-photos ${styles.button}`}
                    onClick={e => {
                      e.preventDefault();
                      this.toggleThumbs();
                      const { amliPropertyId } = this.props.property.data;
                      EventViewMedia(amliPropertyId, 'gallery');
                    }}
                    topelement={() => (
                      <svg width="19" height="13" viewBox="0 0 19 13" xmlns="http://www.w3.org/2000/svg">
                        <g id="Property-Phases-Media-Gallery-Icon" fill="none" fillRule="evenodd">
                          <g className="all-photos-cube" transform="translate(-56 -474)" fill="#383838" fillRule="nonzero">
                            <g transform="translate(45 474)">
                              <g transform="translate(11)">
                                <path d="M4,0 L14.6904762,0 C15.2427609,0 15.6904762,0.44771525 15.6904762,1 L15.6904762,12 C15.6904762,12.5522847 15.2427609,13 14.6904762,13 L4,13 C3.44771525,13 3,12.5522847 3,12 L3,1 C3,0.44771525 3.44771525,0 4,0 Z M5,2 L5,11 L13.6904762,11 L13.6904762,2 L5,2 Z M0,2 L1.99876321,2 L2,12 L0,12 L0,2 Z M16.6904762,2 L18.6892394,2 L18.6904762,12 L16.6904762,12 L16.6904762,2 Z" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    )}
                  >
                    <view-photos-button>
                      <style
                        dangerouslySetInnerHTML={{
                          __html: `\n .af-view .af-class-link-w-icon.w-inline-block:hover {\n background-color: ${primaryColor};}\n`
                        }}
                      />
                      Media Gallery
                    </view-photos-button>
                  </all-photos>
                  {hasTourLinks && (
                    <tour
                      className={`tour-modal-button ${styles.button}`}
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ showTourModal: true, playTour: false });
                        const { amliPropertyId } = this.props.property.data;
                        EventViewMedia(amliPropertyId, 'virtual tour');
                      }}
                    >
                      <div>Virtual Tours</div>
                      <svg width="17" height="20" viewBox="0 0 17 20" xmlns="http://www.w3.org/2000/svg">
                        <g id="Property-Phases-3D-Tour-Icon" fill="none" fillRule="evenodd">
                          <g id="tour-cube" className="tour-button-cube" transform="translate(-304 -470)" fill="#383838" fillRule="nonzero">
                            <g transform="translate(274 470)">
                              <g transform="translate(30)">
                                <path d="M3.02955208,5.53827324 L8.5,8.86878802 L13.8956509,5.49427502 L8.5,2.3203627 L3.02955208,5.53827324 Z M15,6.81619902 L9.38819608,10.1820805 L9.38819608,17.157169 L15,13.8561079 L15,6.81619902 L15,6.81619902 Z M7.61180392,17.157169 L7.61180392,10.1820805 L2,6.92569373 L2,13.8561079 L7.61180392,17.157169 L7.61180392,17.157169 Z M0.492979873,4.71001184 L7.99297987,0.298247133 C8.30592899,0.11415942 8.69407101,0.11415942 9.00702013,0.298247133 L16.5070201,4.71001184 C16.8124588,4.88968166 17,5.21758178 17,5.57194605 L17,14.4280539 C17,14.7824182 16.8124588,15.1103183 16.5070201,15.2899882 L9.00702013,19.7017529 C8.69407101,19.8858406 8.30592899,19.8858406 7.99297987,19.7017529 L0.492979873,15.2899882 C0.187541178,15.1103183 0,14.7824182 0,14.4280539 L0,5.57194605 C0,5.21758178 0.187541178,4.88968166 0.492979873,4.71001184 Z" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </tour>
                  )}
                  {sitePlan ? (
                    <site-plan-button
                      className={`site-plan-button ${styles.button}`}
                      onClick={e => {
                        e.preventDefault();
                        if (window) {
                          window.open(sitePlan);
                          const { amliPropertyId } = this.props.property.data;
                          EventViewMedia(amliPropertyId, 'site plan');
                        }
                      }}
                    >
                      <div>View Site Plan</div>
                      <svg width="18" height="18" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fillRule="evenodd">
                          <g className="view-site-plan-icon" transform="translate(-1 -1)" fill="#383838" fillRule="nonzero">
                            <path d="M20,13 L17.8810262,13 C17.3287415,13 16.8810262,12.5522847 16.8810262,12 C16.8810262,11.4477153 17.3287415,11 17.8810262,11 L20,11 L20,3 L15,3 L15,8 C15,8.55228475 14.5522847,9 14,9 C13.4477153,9 13,8.55228475 13,8 L13,3 L3,3 L3,11.4302411 L7,11.4302411 L7,10.1453616 C7,9.59307688 7.44771525,9.14536163 8,9.14536163 C8.55228475,9.14536163 9,9.59307688 9,10.1453616 L9,14.7151205 C9,15.2674053 8.55228475,15.7151205 8,15.7151205 C7.44771525,15.7151205 7,15.2674053 7,14.7151205 L7,13.4302411 L3,13.4302411 L3,20 L13,20 L13,16.8604822 C13,16.3081974 13.4477153,15.8604822 14,15.8604822 C14.5522847,15.8604822 15,16.3081974 15,16.8604822 L15,20 L20,20 L20,13 Z M1,2 C1,1.44771525 1.44771525,1 2,1 L21,1 C21.5522847,1 22,1.44771525 22,2 L22,21 C22,21.5522847 21.5522847,22 21,22 L2,22 C1.44771525,22 1,21.5522847 1,21 L1,2 Z" />
                          </g>
                        </g>
                      </svg>
                    </site-plan-button>
                  ) : null}
                </button-container>
              </property-infot-banner>
            )}
          </slider>
        </PropertyHeaderView>
        {hideEntrataWidget && this.state.showCustomCloseButton && (
          <div
            className={styles.widgetClose}
            onClick={() => {
              window.ppcb.close();
              this.setState({ showCustomCloseButton: false });
            }}
          >
            Close X
          </div>
        )}
        <div>
          {this.state.showThumbs && (
            <div
              className={['close-icon', styles.close].join(' ')}
              onClick={evt => {
                evt.preventDefault();
                this.toggleThumbs();
              }}
            >
              X Close
            </div>
          )}
        </div>
        {this.state.showBetterbotPixel ? (
          <Helmet>
            <script className="betterbot" src={`${betterbotData}`} defer="defer"></script>
          </Helmet>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(
  compose(
    graphql(propertyAttributesQuery, {
      name: 'attributesQuery',
      options: props => ({
        variables: {
          id: String(props.property.data.amli_id),
          review_push_id: String(props.property.data.review_push_id),
          reputationdotcom_id: String(props.property.data.reputationdotcom_id),
          reputationdotcom_reviews_enabled: Boolean(props.property.data.reputationdotcom_reviews_enabled)
        }
      })
    }),
    graphql(propertyGalleryQuery, {
      name: 'propertyGalleryQuery',
      skip: props => !props || !props.property || !props.property.id,
      options: props => ({
        variables: {
          id: props.property.id
        }
      })
    })
  )(PropertyHeader)
);
