import * as Sentry from '@sentry/browser';
import get from 'lodash/get';

export const DataValidator = async (query) => {
    for (let retries = 0; ; retries++)
    {
        const data = await query;

        try
        {
            JSON.parse(JSON.stringify(data));
            return data;
        }
        catch (e)
        {
            if (retries < 1)
            {
                continue;
            }
            else
            {
                const obj = get(data, "data", []);
                const keys = Object.keys(obj);
                Sentry.captureException(`Invalid data is returning from Prismic as part of the ${keys[0]} object.`)
                throw new DataValidatorError('Invalid data');
            }
        }
    }
}

export class DataValidatorError extends Error {
    constructor(args) {
        super(args);
        this.name = "DataValidatorError"
    }
}
