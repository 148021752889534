import React, { useEffect } from "react";
import get from "lodash/get";
import PropertyIntroductionWithFloorplansView from "../../components/PropertyIntroductionWithFloorplansView";
import FloorplanCardListView from "../../components/FloorplanCardListView";
import PrismicDom from "prismic-dom";
import BackgroundImageHandler from "../../helpers/components-data-handlers/background-image-handler";
import { getFadedPersonaColor, personaColorStyle } from "../../helpers/property/persona";
import { htmlSerializerFunc } from "../../helpers/prismic/richTextField";
import "./index.css";
import { addOrReplaceUrlParam } from "../../helpers/add-or-replace-url-param";
import CapitalizeFirsts from "../../helpers/capitalize-first-letter";
import { LazyLoaderElement, LazyLoader } from "../../helpers/lazy-loader";
import styles from "./styles.module.css";

export default props => {
    const { data } = props;
    const subtitle = get(props, "slice.primary.subtitle");
    const bigImage = BackgroundImageHandler(get(data, "primary.big_image"), false);
    const smallImage = get(data, "primary.small_image.url");
    const altText = get(data, "primary.small_image.small_image_alt_text");
    const title = get(props, "page.data.tagline");
    const textBlurb = get(props, "page.data.about_blurb", []);
    const property = get(props, 'property') || get(props, 'page.data');
    const thirdColor = getFadedPersonaColor(property, 'primary', 0.2);
    const subregionName = CapitalizeFirsts(get(props, "page.data.sub-regions[0].sub-region.slug", ""));

    const floorplanSlice = property.body.find(item => item.slice_type === "floor_plan_highlights");
    const lowerSubtitle = get(floorplanSlice, "primary.subtitle");
    const lowerTitle = get(floorplanSlice, "primary.title");
    const lowerBlurb = get(floorplanSlice, "primary.floorplan_blurb", []);

    const secondaryColor = personaColorStyle(property, 'secondary');
    const htmlSerializer = htmlSerializerFunc(secondaryColor.color);
    const textBlurbHtml = PrismicDom.RichText.asHtml(textBlurb, undefined, htmlSerializer);
    const lowerBlurbHtml = PrismicDom.RichText.asHtml(lowerBlurb, undefined, htmlSerializer);

    let lazyLoaderElementList = [];

    useEffect(() => {
        var lazyloadSmallImage = resizeImage();

        //#region SETUP lazy loading

        let lazyLoaderElementItem = new LazyLoaderElement();

        //STORE images TO lazy load
        lazyLoaderElementItem = new LazyLoaderElement();
        lazyLoaderElementItem.Id = "property_introduction_with_floorplan_small_image";
        lazyLoaderElementItem.Src = lazyloadSmallImage;
        lazyLoaderElementItem.SrcSet = lazyloadSmallImage;

        if (!lazyloadSmallImage) {
            lazyLoaderElementItem.Src = "/images/lofts-amenity-exterior-clubroom2x.jpg";
            lazyLoaderElementItem.SrcSet = "images/lofts-amenity-exterior-clubroom2x-p-500.jpeg 500w, images/lofts-amenity-exterior-clubroom2x-p-1080.jpeg 1080w, images/lofts-amenity-exterior-clubroom2x.jpg 1366w";
        }

        lazyLoaderElementList.push(lazyLoaderElementItem);

        lazyLoaderElementItem = new LazyLoaderElement();
        lazyLoaderElementItem.Id = "property_introduction_with_floorplan_big_image";
        lazyLoaderElementItem.BackgroundImage = bigImage.style.backgroundImage;
        lazyLoaderElementItem.BackgroundPosition = bigImage.style.backgroundPosition;
        lazyLoaderElementItem.BackgroundSize = bigImage.style.backgroundSize;

        lazyLoaderElementList.push(lazyLoaderElementItem);

        const lazyLoader = new LazyLoader();
        lazyLoader.LazyLoad(lazyLoaderElementList);

        //#endregion
    }, [])

    const resizeImage = () => {
        const width = props.pageWidth < 768 ? props.pageWidth : props.pageWidth * 0.6
        let smallImageResized = addOrReplaceUrlParam(smallImage, 'w', width);
        return smallImageResized
    }
    const smallImageResized = resizeImage();

    return (
        <PropertyIntroductionWithFloorplansView {...props}>
            <subtitle style={personaColorStyle(property, 'secondary')}>{subtitle || subregionName}</subtitle>
            <title style={personaColorStyle(property)}>{title}</title>
            <text-blurb>
                <wrapper>
                    <div className={styles.bodyContainer} dangerouslySetInnerHTML={{ __html: textBlurbHtml }} />
                </wrapper>
            </text-blurb>
            {
                smallImage ?
                <small-image
                alt={altText ? altText : 'AMLI Apartment Lounge'}
                />
                : <small-image />
            }
            <big-image />
            <body-wrapper style={{backgroundColor: thirdColor}}>
                <empty  />
                <title-wrapper>
                    <lower-subtitle style={personaColorStyle(property, 'secondary', "#383838")}>{lowerSubtitle}</lower-subtitle>
                    <lower-title className={styles.lowerTitle} style={personaColorStyle(property)}>{lowerTitle}</lower-title>
                    <lower-blurb>
                        <wrapper>
                            <div className={styles.bodyContainer} dangerouslySetInnerHTML={{ __html: lowerBlurbHtml }} />
                        </wrapper>
                    </lower-blurb>
                </title-wrapper>
                <FloorplanCardListView.Controller data={data} {...props} />
            </body-wrapper>
        </PropertyIntroductionWithFloorplansView>
    );
};
