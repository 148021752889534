import React, { useState, useEffect } from "react";
import get from "lodash/get";
import * as moment from "moment";
import { withApollo } from "react-apollo";
import QuoteSummaryColumnPrintView from "../../components/QuoteSummaryColumnPrintView";
import QuoteSummaryColumnItemPrintView from "../../components/QuoteSummaryColumnItemPrintView";
import usePhone from "../../helpers/hooks/usePhone";
import { formatDollar, canUseDOM } from "../../helpers/utils";
import getPropertyDataFromAppData from "../../helpers/property/getDataFromProps";
import styles from "./styles.module.css";
import "./index.css";
import { applyWithQuote } from "../../helpers/property/apply";

const items = [
  {
    field: "leaseTerm",
    formatter: text => `${text} Months`,
    label: "Lease Length"
  },
  {
    field: "startDate",
    formatter: date => moment(date).format("M/DD/YY"),
    label: "Move-in date"
  },
  {
    field: "totalRent",
    formatter: rent => `$${formatDollar(rent, 0)}`,
    label: "Monthly Payment"
  },
  {
    field: "realPageQuoteId",
    formatter: id => id,
    label: "Quote Number"
  },
  {
    field: "quoteExpirationDate",
    formatter: date => moment(date).format("MMM D, YYYY"),
    label: "Quote Expiration"
  }
];

function QuoteSummaryColumnPrint(props) {
  return (
    <div className={styles.wrapper}>
      <QuoteSummaryColumnPrintView {...props}>
        <details-list>
          <quoteSummaryColumnItemViewList0 className={styles.itemsContainer}>
            {items.map((item, index) =>
              props.quote[item.field] ? (
                <QuoteSummaryColumnItemPrintView.Controller
                  key={`item_summary_${index}`}
                  label={item.label}
                  value={item.formatter(props.quote[item.field])}
                />
              ) : null
            )}
          </quoteSummaryColumnItemViewList0>
        </details-list>
        <tabs-container>
          <tabs className="payment-summary-tabs"/>
        </tabs-container>
      </QuoteSummaryColumnPrintView>
    </div>
  );
}

export default withApollo(QuoteSummaryColumnPrint);
