import React, { useEffect } from "react";
import get from 'lodash/get';
import { withRouter } from "react-router-dom";
import ContentImageBlurbCtaView from '../../components/ContentImageBlurbCtaView';
import { handlePrismicLink } from "../../helpers/link-helper";
import { addOrReplaceUrlParam, addQualityParam } from "../../helpers/add-or-replace-url-param";
import styles from './styles.module.css';
import { LazyLoaderElement, LazyLoader } from "../../helpers/lazy-loader";

export default withRouter((props) => {
    const { data } = props;
    const linkData = get(data, 'primary.cta_link1');
    const imageUrl = get(data, 'primary.image.url');
    const subtitle = get(data, 'primary.subtitle');
    const textBlurb = get(data, 'primary.text_blurb');
    const ctaText = get(data, 'primary.cta_text1');
    const link = handlePrismicLink(props, linkData);
    const url = imageUrl && addOrReplaceUrlParam(data.primary.image.url, 'w', 1500);
    const image = imageUrl && addOrReplaceUrlParam(url, 'h', 'auto')
    
    let lazyLoaderElementList = [];

    useEffect(() => {
        //#region SETUP lazy loading

        if (imageUrl) {
            let lazyLoaderElementItem = new LazyLoaderElement();

            //STORE images TO lazy load
            lazyLoaderElementItem = new LazyLoaderElement();
            lazyLoaderElementItem.Id = "lazy_content_image_and_blurb_cta";
            lazyLoaderElementItem.BackgroundImage = `url(${addQualityParam(image, 5)})`;

            lazyLoaderElementList.push(lazyLoaderElementItem);

            const lazyLoader = new LazyLoader();
            lazyLoader.LazyLoad(lazyLoaderElementList);
        }

        //#endregion
    }, []);

    return (
        <ContentImageBlurbCtaView>
            <image
                className={styles.imageContainer}
            >
                <home-careers-container className={styles.homeCareersContainer}>
                    <subtitle className={styles.subtitle}>{subtitle}</subtitle>
                    <text-blurb className={styles.textBlurb}>{textBlurb}</text-blurb>

                    <link-url className={styles.button} {...link}>
                        <link-text className={styles.buttonText}>{ctaText}</link-text>
                        <link-arrow className={styles.buttonArrow} alt="Right arrow." />
                    </link-url>
                </home-careers-container>
            </image>
        </ContentImageBlurbCtaView>
    );
});
