import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.css";
import { get, xor } from "lodash";
import icon from "../../assets/icons/arrow-chevron.svg";
import Checkbox from "../FloorplanHeaderFiltersController/checkbox";
import { getPersonaColor } from "../../helpers/property/persona";
import GetUnitTypes from "../../helpers/components-data-handlers/get-unit-types";

const toggle = (array, item) => xor(array, [item]);

let listCurrentState = [];

function BathroomFilters(props) {
  let bathList = [];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [localFilters, setLocalFilters] = useState(props.filters.bathRoomTypes);
  const [size, setSize] = useState(props.filters.size);

  const hasSize = () => {
    return props.location.search.includes('size');
  }

  const { property, propertyFloorplansSummary, filters, setFilters } = props;

  let skipCode = false;

  useEffect(() => {
    if (props.filters.closeBathType) {
      setDropdownOpen(false);
    }

    setFilters({
      ...filters,
      closeHomeType: false,
      closeBedType: false,
      closeBathType: false,
    });

    skipCode = true;
  }, [props.filters.closeBathType]);

  useEffect(() => {
    const filteredValues = {
      dropDownID: "bathroomFilters",
      dropDownValues: localFilters,
    };

    setFilters({
      ...filters,
      bathRoomTypes: localFilters,
      filteredValues: filteredValues,
    });
  }, [localFilters]);

  useEffect(() => {
    if (!skipCode) {
      setFilters({ ...filters, closeBedType: true, closeHomeType: true });
    }
  }, [dropdownOpen]);

  const floorplanData = get(
    propertyFloorplansSummary,
    "propertyFloorplansSummary",
    []
  );

  const convertBathsToCommonObject = (bathList) => {
    const bathListEntries = bathList;

    let availableBaths = [];
    let newList = [];

    const floorplanData = get(
      propertyFloorplansSummary,
      "propertyFloorplansSummary",
      []
    );

    if (floorplanData.length > 0) {
      floorplanData.map((fp) => {
        if (!availableBaths.includes(fp.bathroomMin)) {
          if (hasSize()) {
            if (fp.bedroomMin === size) {
              availableBaths.push(fp.bathroomMin);
            }
          } else {
            availableBaths.push(fp.bathroomMin);
          }
          
        }
      });
    }

    if (availableBaths.length > 0) {
      bathListEntries.map((item) => {
        availableBaths.map((bath) => {
          if (item.value[0] == bath) {
            newList.push({
              label: item.label,
              value: [item.value[0]],
            });
          }
        });
      });
    }

    return newList;
  };

  bathList = props.baths;
  bathList = convertBathsToCommonObject(bathList);

  const filterDropDownList = (bathListUnFiltered) => {
    let filteredBathList = [];

    if (
      props.filters.filteredValues &&
      props.filters.filteredValues.dropDownID &&
      props.filters.filteredValues.dropDownValues.length > 0
    ) {
      floorplanData.map((floorplan) => {
        switch (props.filters.filteredValues.dropDownID) {
          case "additionalFilters":
            if (floorplan.units) {
              floorplan.units.map((floorplanUnit) => {
                if (
                  floorplanUnit.unitTypeDesignation ==
                  props.filters.filteredValues.dropDownValues[0]
                ) {
                  bathListUnFiltered.map((bath) => {
                    if (floorplan.bathroomMin == bath.value[0]) {
                      if (!filteredBathList.includes(bath)) {
                        filteredBathList.push(bath);
                      }
                    }
                  });
                }
              });
            }

            break;

          case "bedroomFilters":
            props.filters.filteredValues.dropDownValues.map((bedRoomValue) => {
              if (floorplan.bedroomMax == bedRoomValue) {
                bathListUnFiltered.map((bath) => {
                  if (floorplan.bathroomMin == bath.value[0]) {
                    if (!filteredBathList.includes(bath)) {
                      filteredBathList.push(bath);
                    }
                  }
                });
              }
            });

            break;
        }
      });

      if (filteredBathList.length == 0) {
        bathList = props.baths;
        filteredBathList = convertBathsToCommonObject(bathList);
      }
    } else {
      bathList = props.baths;
      filteredBathList = convertBathsToCommonObject(bathList);
    }

    return filteredBathList;
  };

  if (props.filters.filteredValues.dropDownID == "bathroomFilters") {
    bathList = listCurrentState;
  } else {
    bathList = filterDropDownList(bathList);
    listCurrentState = bathList;
  }

  if (!bathList || !bathList.length) {
    return null;
  }

  return (
    <div
      id="bathroomFilters"
      style={{ position: "relative" }}
      tabIndex={-1}
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setDropdownOpen(false);
        }
      }}
    >
      <div
        className={styles.filtersDropdown}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <span style={{ fontSize: 16 }}>Bathrooms</span>
        <img
          src={icon}
          alt="dropdown arrow"
          className={dropdownOpen ? styles.dropdownOpen : styles.dropdownClosed}
        />
      </div>
      {dropdownOpen && (
        <div className={styles.dropdownContainer}>
          {/*<p style={{ fontSize: 12 }}>HOME TYPE</p>*/}
          {bathList.map((type, index) => {
            return (
              <Checkbox
                key={`${type.value.join("-")}-${index}`}
                label={type.label}
                onChange={async (e) => {
                  const newBaths = toggle(localFilters, type.value.join(","));

                  setLocalFilters(newBaths);
                }}
                isChecked={(localFilters || []).includes(type.value.join(","))}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default BathroomFilters;
