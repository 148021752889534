import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import FloorplanPoliciesItemView from '../../components/FloorplanPoliciesItemView';
import FloorplanPoliciesTabView from '../../components/FloorplanPoliciesTabView';
import FloorplanPoliciesView from '../../components/FloorplanPoliciesView';
import { LazyLoader, LazyLoaderElement } from '../../helpers/lazy-loader';
import { personaColorStyle } from '../../helpers/property/persona';
import { canUseDOM } from '../../helpers/utils';
import './index.css';
import styles from './styles.module.css';

function FloorplanPolicies(props) {
  const orderSort = (a, b) => (a.sortOrder > b.sortOrder ? 1 : -1);
  const [tab, setTab] = useState(0);
  const property = get(props, 'property');

  const isDenver = canUseDOM ? window.location.href.toLowerCase().includes('denver') : false;

  const upfront_fees = get(props, 'property.data.addOns', [])
    .filter(a => a.category === 'Upfront fees')
    .sort(orderSort);
  const pets = get(props, 'property.data.addOns', [])
    .filter(a => a.category === 'Pets')
    .sort(orderSort);
  const parking = get(props, 'property.data.addOns', [])
    .filter(a => a.category === 'Parking')
    .sort(orderSort);
  const storage = get(props, 'property.data.addOns', [])
    .filter(a => a.category === 'Storage')
    .sort(orderSort);
  const other = get(props, 'property.data.addOns', [])
    .filter(a => a.category === 'Other')
    .sort(orderSort);
  const tabsdata = [upfront_fees, pets, parking, storage, other];
  const tabs = ['Upfront Costs', 'Pets', 'Parking', 'Storage', 'Other'];

  const hasData = tabsdata.slice(0, -1).filter(items => !!items && items.length > 0).length > 0 || true;
  if (!hasData) {
    return null;
  }

  const getDisclaimer = currentTab => {
    if (tabs[currentTab] === 'Pets') {
      return isDenver ? '* Per household and 2 pet maximum.' : '* Per pet and 2 pet maximum.';
    }
    return tabs[currentTab] === 'Other' ? '* Prices may vary by unit and are subject to change.' : '* Prices may vary by unit.';
  };

  const getOtherTabContent = () => (
    <div className={styles.otherTabContent}>
      <div className={styles.otherSection}>
        <p style={personaColorStyle(property, 'primary')}>
          Monthly utility billing methodology will be outlined in lease agreement. Any additional questions regarding billing please contact our
          onsite leasing team.
        </p>
      </div>
      <div className={styles.otherSection}>
        <p style={personaColorStyle(property, 'primary')} className={styles.sectionTitle}>
          Renters Insurance:
        </p>
        <p style={personaColorStyle(property, 'primary')}>
          Renter's Insurance is required for all leaseholders and must have a minimum liability coverage of $100,000.
        </p>
      </div>
    </div>
  );

  useEffect(() => {
    //#region SETUP lazy loading

    let lazyLoaderElementList = [];

    let lazyLoaderElementItem = new LazyLoaderElement();

    //STORE images TO lazy load
    lazyLoaderElementItem.Id = 'floorplan_policy_image';
    lazyLoaderElementItem.ClassName = 'af-class-div-block-200 ' + styles.backgroundImage;

    lazyLoaderElementList.push(lazyLoaderElementItem);

    const lazyLoader = new LazyLoader();
    lazyLoader.LazyLoad(lazyLoaderElementList);

    //#endregion
  }, []);

  return (
    <FloorplanPoliciesView>
      <header style={personaColorStyle(property, 'secondary')}>Additional Costs & Add-Ons</header>
      <title style={personaColorStyle(property, 'primary')} />
      <image-wrapper />
      <floorplanPoliciesTabViewList0>
        {tabs.map((item, index) =>
          (tabsdata[index] && tabsdata[index].length > 0) || item === 'Other' ? (
            <FloorplanPoliciesTabView.Controller
              property={property}
              title={item}
              key={`${tabs[tab]}_tab_${index}`}
              active={tab === tabs.indexOf(item)}
              onClick={() => setTab(tabs.indexOf(item))}
            />
          ) : null
        )}
      </floorplanPoliciesTabViewList0>
      <floorplanPoliciesItemViewList4>
        {tabsdata[tab] &&
          tabsdata[tab].map((item, index) => (
            <FloorplanPoliciesItemView.Controller property={props.property} key={`${tabs[tab]}_item_${index}`} item={item} />
          ))}
        {tabs[tab] === 'Other' && getOtherTabContent()}
        <div className={styles.disclaimer}>{getDisclaimer(tab)}</div>
      </floorplanPoliciesItemViewList4>
    </FloorplanPoliciesView>
  );
}

export default FloorplanPolicies;
