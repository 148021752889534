import React, { useState, useEffect } from "react";
import get from "lodash/get";
import truncate from "lodash/truncate";
import { withRouter } from "react-router";
import { withApollo } from "react-apollo";
import NewsCardView from "../../components/NewsCardView";
import BackgroundImageHandler from "../../helpers/components-data-handlers/background-image-handler";
import { addOrReplaceUrlParam } from "../../helpers/add-or-replace-url-param";
import styles from "./styles.module.css";
import "./index.css";
import { authorQuery } from "../../graphql/queries/authorQuery";
import { LazyLoaderElement, LazyLoader } from "../../helpers/lazy-loader";

const moment = require("moment");

function NewsCard(props) {
    const [author, setAuthor] = useState(null);
    let lazyLoaderElementList = [];

    useEffect(() => {
        const id = get(props, "data.author.id");
        if (!author && !!id) {
            props.client
                .query({
                    query: authorQuery,
                    variables: { id }
                })
                .then(auth => setAuthor(get(auth, "data.author")));
        }       

       //#region SETUP lazy loading

        //STORE images TO lazy load

 
        const lazyLoader = new LazyLoader();
        lazyLoader.LazyLoad(lazyLoaderElementList);

        //#endregion
    });

    const bgimage = BackgroundImageHandler(
        get(props, "data.post_main_image")
    );
    const bgImageUrl = get(bgimage, "style.backgroundImage", "");
    const bgimageResized = bgImageUrl.substring(4, bgImageUrl.length - 1);
    const url = addOrReplaceUrlParam(bgimageResized, 'w', '700');
    const new_url = addOrReplaceUrlParam(url, 'h', 'auto');
    if (bgImageUrl) {
        bgimage.style.backgroundImage = `url(${new_url})`;
    }

    if (!props || !props.data) {
        return null;
    }
    let summary = get(props, "data.summary");
    if (summary && summary.length > 0) {
        summary = summary[0].text;
    }
    const truncatedSummary = truncate(summary, {
        length: 180,
        separator: ' '
    });

    const avatar = BackgroundImageHandler(get(author, "data.image"));
    let date = get(props, "data.published_date");
    if (date) {
        date = moment(date);
    }

    let lazyLoaderElementItem = new LazyLoaderElement();
    lazyLoaderElementItem.Id = `news_card_image_${props.data.id}`;
    lazyLoaderElementItem.BackgroundImage = bgimage.style.backgroundImage;
    lazyLoaderElementList.push(lazyLoaderElementItem);

    return (
        <NewsCardView>
            <card-wrapper className={styles.cardWrapper}>
                <image id={`news_card_image_${props.data.id}`}  />
                <title>{props.data.title}</title>
                <body>
                    {truncatedSummary}
                    <a
                        href={`/blog/${props.data.uid}`}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                window.location = window.location.origin + `/blog/${props.data.uid}`
                            }
                        }}
                        onClick={() => {
                            window.location = window.location.origin + `/blog/${props.data.uid}`
                        }}
                        tabIndex={0}
                        className={styles.readMore}
                        aria-label={`Read more about ${props.data.title}`}
                    >
                        Read More
            <svg
                            className={styles.buttonArrow}
                            xmlns="http://www.w3.org/2000/svg"
                            width="8px"
                            height="13px"
                            viewBox="0 0 13.13 22.02"
                        >
                            <g fill={"#333"}>
                                <polygon
                                    points="0 19.82 2.19 22.02 13.13 11.01 2.19 0 0 2.2 8.68 11.01 0 19.82"
                                    className="af-class-arrow--chevron"
                                />
                            </g>
                        </svg>
                    </a>
                </body>
                <avatar {...avatar} className={avatar ? "" : "visibility_hidden"} />
                {author && <author>{get(author, "data.name")}</author>}
                {date && <date>{date.format("M/D/YY")}</date>}
            </card-wrapper>
        </NewsCardView>
    );
}

export default withRouter(withApollo(NewsCard));
