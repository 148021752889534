import client from "./apollo-client";
import { locationScoreQuery } from "../graphql/queries/locationScoreQuery";

async function getLocationScore(format, address, lat, lon, transit, bike) {
  try {
    const data = await client.query({
      query: locationScoreQuery,
      variables: {
        format: format ? format.toString() : "",
        address: address ? address.toString() : "",
        lat: lat ? parseFloat(lat) : 0,
        lon: lon ? parseFloat(lon) : 0,
        transit: transit ? parseInt(transit) : 0,
        bike: bike ? parseInt(bike) : 0
      },
      fetchPolicy: 'network-only'
    });

    const result = data &&
      data.data.locationScore ? data.data.locationScore : undefined;

    return result;
  } catch (error) {
    console.error("Error fetching location score:", error);
    return undefined;
  }
}

export default getLocationScore;