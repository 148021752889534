import React from "react";
import get from "lodash/get";
import modal_close from "../../assets/images/modal-close.png";
import ListPerksView from "../../components/ListPerksView";
import styles from "./styles.module.css";
import { personaColorStyle } from "../../helpers/property/persona";
import downArrow from "../../assets/images/down-arrow.png";
import "./index.css";
import { addQualityParam } from "../../helpers/add-or-replace-url-param";
import { PDFDownloadLink } from '@react-pdf/renderer';
import ListPerksPdfDocument from "./react-pdf.js";
import { LazyLoaderElement, LazyLoader } from "../../helpers/lazy-loader";

export default class ListPerks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            amenityText: "",
            selectedIndex: -1,
            visibility: "hidden",
            perkTop: "25%",
            showAll: false,
            isPerksPdfOn: false
        };

        this.handlePerksPdfOn = this.handlePerksPdfOn.bind(this);
        this.hidePerkDetails = this.hidePerkDetails.bind(this);
        this.showPerkDetails = this.showPerkDetails.bind(this);
        this.parentNode = React.createRef();
        this.currentNode = [];
        this.perks = get(this.props, "slice.items", []);
    }

    handlePerksPdfOn() {
        this.setState(state => ({
            isPerksPdfOn: !state.isPerksPdfOn
        }));
    }

    hidePerkDetails() {
        this.setState({
            selectedIndex: -1,
            visibility: "hidden",
            perkTop: "25%"
        });
    }

    showPerkDetails(perk, selectedIndex) {
        if (selectedIndex === this.state.selectedIndex) {
            return null;
        }
        if (
            this.parentNode &&
            this.parentNode.current &&
            this.currentNode[selectedIndex]
        ) {
            const parentNodeBounds = this.parentNode.current.getBoundingClientRect();
            const currentNodeBounds = this.currentNode[
                selectedIndex
            ].getBoundingClientRect();
            const top = currentNodeBounds.top - parentNodeBounds.top;
            this.setState({
                selectedIndex,
                amenityText: perk.perk_text,
                visibility: "visible",
                perkTop: top
            });
        } else {
            this.setState({
                selectedIndex,
                amenityText: perk.perk_text,
                visibility: "visible"
            });
        }
    }

    componentDidMount()
    {
        let lazyLoaderElementList = [];

        //#region SETUP lazy loading

        let lazyLoaderElementItem = new LazyLoaderElement();

        //STORE images TO lazy load
        this.perks.map((perk, index) => {
            if (perk.perk_image != undefined && perk.perk_image.url != undefined) {
                lazyLoaderElementItem = new LazyLoaderElement();
                lazyLoaderElementItem.Id = `perk-logo-image-${index}`;
                lazyLoaderElementItem.Src = addQualityParam(perk.perk_image.url, 5);

                lazyLoaderElementList.push(lazyLoaderElementItem);
            }
        })

        //const lazyLoader = new LazyLoader();

        //#endregion
    }

    render() {
        const property = get(this.props, "property");
        const title = get(this.props, "slice.primary.title");
        const text_blurb = get(this.props, "slice.primary.text_blurb");
        const image = get(this.props, "slice.primary.image1.url");
        const perks = get(this.props, "slice.items", []);
        const {
            amenityText,
            selectedIndex,
            visibility,
            perkTop,
            showAll
        } = this.state;
        return (
            <React.Fragment>
                <ListPerksView>
                    <banner
                        style={
                            image
                                ? {
                                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.76), rgba(0, 0, 0, 0.22)), url(${addQualityParam(image, 5)})`
                                }
                                : null
                        }
                    >
                        <title>{title}</title>
                        <text-blurb>{text_blurb}</text-blurb>
                    </banner>
                    <perk-list-top ref={this.parentNode}>
                        {perks
                            ? perks.map((perk, index) =>
                                perk && perk.perk_image && perk.perk_image.url ? (
                                    <div
                                        ref={currentNode => (this.currentNode[index] = currentNode)}
                                        tabIndex={0}
                                        onKeyDown={e =>
                                            e.stopPropagation() ||
                                            (e.keyCode === 13 && this.showPerkDetails(perk, index))
                                        }
                                        onClick={() => this.showPerkDetails(perk, index)}
                                        className={[
                                            styles.perkWrapper,
                                            "af-class-div-block-168",
                                            index > 4 ? "af-class-last-child" : "",
                                            index > 5 && !showAll
                                                ? styles.mobileHide
                                                : index > 5 && showAll
                                                    ? styles.viewAllPerks
                                                    : ""
                                        ].join(" ")}
                                        key={`perk_${index}`}
                                        style={
                                            perks.length < 9
                                                ? { width: "15%", margin: "3px" }
                                                : { width: "19%", margin: "3px" }
                                        }
                                    >
                                        <img
                                            src={addQualityParam(perk.perk_image.url, 5)}
                                            alt={perk.perk_image.url || "Perk Logo"}
                                            className={`${styles.logoOverlay} ${index === selectedIndex
                                                ? styles.logoOverlaySelected
                                                : ""
                                                }`}
                                        />
                                        {selectedIndex === index && (
                                            <div
                                                className={styles.perkModal}
                                                style={{ visibility: visibility, top: perkTop }}
                                            >
                                                <button
                                                    className={styles.closeModal}
                                                    onKeyDown={e =>
                                                        (e.stopPropagation() ||
                                                            e.keyCode === 13) && this.hidePerkDetails()
                                                    }
                                                    onClick={this.hidePerkDetails}
                                                >
                                                    <img
                                                        tabIndex={1}
                                                        className={styles.closeImage}
                                                        src={modal_close}
                                                        style={{ width: "25px", height: "25px" }}
                                                        alt="close modal"
                                                    />
                                                </button>
                                                {perk.name ? (
                                                    <ul>
                                                        <li className={styles.perkBusinessName}><span style={{ color: "#333" }}>{perk.name}</span></li>
                                                        <li className={styles.amenityText + ' ' + styles.liListPerkItem}>{amenityText}</li>
                                                        <br />
                                                        <website>
                                                            <a
                                                                className={`af-class-text-block-116 ${styles.linktag}`}
                                                                href={perk.perk_url.url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                View Website
                                  <svg
                                                                    className={styles.buttonArrow}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="8px"
                                                                    height="13px"
                                                                    viewBox="0 0 13.13 22.02"
                                                                >
                                                                    <g fill="#b47208">
                                                                        <polygon
                                                                            points="0 19.82 2.19 22.02 13.13 11.01 2.19 0 0 2.2 8.68 11.01 0 19.82"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </a>
                                                        </website>
                                                    </ul>
                                                ) : <div className={styles.amenityText}>{amenityText}</div>
                                                }
                                            </div>
                                        )}
                                    </div>
                                ) : null
                            )
                            : null}
                        <div
                            className={styles.seeMore}
                            style={personaColorStyle(property, "primary")}
                            onClick={() => this.setState({ showAll: !showAll })}
                        >
                            <p>{showAll ? "Hide Perks" : "View All Perks"}</p>
                            <img
                                src={downArrow}
                                alt="hide/show arrow"
                                className={`${styles.arrow} ${showAll ? styles.arrowUp : ""}`}
                            />
                        </div>
                        {!this.state.isPerksPdfOn ?
                            <button onClick={this.handlePerksPdfOn} style={{ height: "50px" }}>
                                <div style={{ cursor: 'pointer' }} className="paymentsummary-print-icon">
                                    <svg
                                        className="paymentsummaryprinticon"
                                        width={35}
                                        height={42}
                                        viewBox="-97.48 -97.48 682.34 682.34"
                                        stroke="#000"
                                        strokeWidth={0}>
                                        <path d="M400.1 100.918h-9.871V11.646C390.229 5.215 385.014 0 378.582 0H108.804c-6.436 0-11.646 5.215-11.646 11.646v89.271h-9.877c-38.523 0-69.871 31.344-69.871 69.871v144.258c0 38.528 31.348 69.87 69.871 69.87h9.877v90.818c0 6.432 5.21 11.646 11.646 11.646h269.778c6.432 0 11.646-5.215 11.646-11.646v-90.818h9.871c38.523 0 69.871-31.342 69.871-69.87V170.789c.001-38.528-31.347-69.871-69.87-69.871zM120.449 23.291h246.489v77.627H120.449V23.291zm246.489 440.801H120.449V320.465h246.489v143.627zm79.744-149.046c0 25.687-20.896 46.581-46.582 46.581h-9.871v-41.162h25.186c6.436 0 11.645-5.214 11.645-11.646 0-6.43-5.209-11.645-11.645-11.645H75.421c-6.431 0-11.646 5.215-11.646 11.645 0 6.433 5.215 11.646 11.646 11.646h21.737v41.162h-9.877c-25.685 0-46.581-20.896-46.581-46.581V170.789c0-25.685 20.896-46.58 46.581-46.58H400.1c25.684 0 46.58 20.896 46.58 46.58v144.257h.002z" stroke="none" />
                                        <path d="M378.582 170.646c-8.771 0-15.893 7.119-15.893 15.893s7.119 15.893 15.893 15.893c8.775 0 15.895-7.118 15.895-15.893-.002-8.774-7.12-15.893-15.895-15.893zM147.618 360.734h192.146c6.432 0 11.646-5.213 11.646-11.646 0-6.43-5.215-11.645-11.646-11.645H147.618c-6.431 0-11.646 5.215-11.646 11.645 0 6.433 5.215 11.646 11.646 11.646zM147.618 403.926h192.146c6.432 0 11.646-5.217 11.646-11.646 0-6.432-5.215-11.645-11.646-11.645H147.618c-6.431 0-11.646 5.213-11.646 11.645s5.215 11.646 11.646 11.646z" stroke="none" />
                                    </svg>
                                </div>
                            </button> :
                            null
                        }

                        {this.state.isPerksPdfOn ?
                            <PDFDownloadLink document={<ListPerksPdfDocument data={this.perks} propertyname={property.data.name} />}
                                fileName="AmliPerks.pdf"
                                style={{
                                    textDecoration: "bold",
                                    padding: "10px",
                                    color: "#4a4a4a",
                                    backgroundColor: "#f2f2f2",
                                    border: "1px solid #4a4a4a",
                                    height: "50px"
                                }}
                            >
                                {({ blob, url, loading, error }) =>
                                    loading ? "Loading document..." : "Download Printable Version"
                                }
                            </PDFDownloadLink>
                            : null}
                    </perk-list-top>
                </ListPerksView>
            </React.Fragment>
        );
    }
}
