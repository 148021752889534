import gql from "graphql-tag";
export const socialFeedQuery = gql`
  query socialfeed($feedType: String) {
    socialfeed(feedType: $feedType) {
        socialFeedId
        mediaType
        url
        comment
        imageUrl
        isActive
        created
        lastChanged
        lastChangedBy
    }
  }
`;