import React, { Component } from 'react';
import styles from './styles.module.css';
class Checkbox extends Component {
  toggleCheckboxChange = () => {
    const { onChange, isChecked } = this.props;
    onChange(!isChecked);
  };

  render() {
    const { label, isChecked, color = '#000', isDisabled } = this.props;

    return (
      <div className={styles.checkboxContainer}>
        <label className={styles.checkboxLabel} style={{ cursor: isDisabled ? 'not-allowed' : 'pointer', color: isDisabled ? '#EBEBE4' : '#333' }}>
          <input
            className={[styles.checkboxInput, isChecked ? styles.checkboxChecked : ''].join(' ')}
            style={{
              backgroundColor: isChecked ? color : '#FFFFFF',
              cursor: isDisabled ? 'not-allowed' : 'pointer',
            }}
            type="checkbox"
            value={label}
            checked={isChecked}
            onChange={this.toggleCheckboxChange}
            disabled={isDisabled}
          />

          {label}
        </label>
      </div>
    );
  }
}

export default Checkbox;
