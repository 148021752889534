import React, { useEffect } from "react";
import { get } from "lodash";
import ApartmentFeatureView from "../../components/ApartmentFeatureView";
import ListApartmentFeaturesView from "../../components/ListApartmentFeaturesView";
import styles from "./styles.module.css";
import "./index.css";
import {
  personaBackgroundColorStyle,
  personaColorStyle
} from "../../helpers/property/persona";
import { addQualityParam } from "../../helpers/add-or-replace-url-param";
import { LazyLoaderElement, LazyLoader } from "../../helpers/lazy-loader";

export default props => {
  const title = get(props, "slice.primary.title");
  const subTitle = get(props, "slice.primary.subtitle");
  const image = get(props, "slice.primary.image.url");
  const alt = get(props, "slice.primary.image.alt");
  const features = get(props, "slice.items", []);
  const property = get(props, "property") || get(props, "page.data");

    //

useEffect(() =>
{
    let lazyLoaderElementList = [];

    //#region SETUP lazy loading

    let lazyLoaderElementItem = new LazyLoaderElement();

    //STORE images TO lazy load
    lazyLoaderElementItem = new LazyLoaderElement();
    lazyLoaderElementItem.Id = "list_apartments_features_intro_image";
    lazyLoaderElementItem.Src = addQualityParam(image, 5);
    lazyLoaderElementItem.SrcSet = `${addQualityParam(image, 5)} 500w, ${addQualityParam(image, 5)} 800w, ${addQualityParam(image, 5)} 1080w, ${addQualityParam(image, 5)} 1516w`;

    lazyLoaderElementList.push(lazyLoaderElementItem);

    const lazyLoader = new LazyLoader();
    lazyLoader.LazyLoad(lazyLoaderElementList);

    //#endregion
})
  return (
    <ListApartmentFeaturesView>
      <title
        className={styles.title}
        style={personaColorStyle(property, "primary")}
      >
        {title}
      </title>
      <body-container
        className={styles.bodyContainer}
        style={personaBackgroundColorStyle(property)}
      >
        <subtitle>{subTitle}</subtitle>
        <image
          className={styles.image}
          data-src={addQualityParam(image, 5)}
           data-srcSet={`${addQualityParam(image, 5)} 500w, ${addQualityParam(image, 5)} 800w, ${addQualityParam(image, 5)} 1080w, ${addQualityParam(image, 5)} 1516w`}
          alt={alt || ""}
        />
        <apartmentFeatureViewList0>
          {features.map((feature, index) => (
            <ApartmentFeatureView.Controller
              feature={feature.feature}
              key={`feature_${index}`}
            />
          ))}
        </apartmentFeatureViewList0>
      </body-container>
    </ListApartmentFeaturesView>
  );
};
