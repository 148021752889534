import React, { useState, useEffect } from 'react';
import Range from 'rc-slider/lib/Range';
import 'rc-slider/assets/index.css';
import RegionHeaderFilterRowView from '../../components/RegionHeaderFilterRowView';
import { formatDollar } from '../../helpers/utils';
import { EventSearchApartment } from '../../helpers/plugins/guestAnalytics.js';
import styles from './styles.module.css';
import './index.css';
import { get } from 'lodash';

const greyBackground = {
  backgroundColor: '#d2d2d2',
};
const dotStyle = {
  width: 14,
  height: 14,
  borderRadius: '50%',
  backgroundColor: '#7ba4da',
  border: 'none',
  boxShadow: '#7ba4da',
};

export default function RegionHeaderFilterRow(props) {
  const {
    filter: { tab, minvalue, maxvalue, minhousing, maxhousing },
  } = props.mapData;

  const [dirty, setDirty] = useState(false);
  const [rangeLimits, setRangeLimits] = useState([0, 7000]);
  const [values, setValues] = useState([0, 7000]);
  useEffect(() => {
    let newRanges = [...rangeLimits];
    if (!props.mapData.filter.dirtySlider && !dirty) {
      if (rangeLimits[0] <= props.mapData.filter.minvalue) {
        newRanges[0] = props.mapData.filter.minvalue;
      }
      if (
        rangeLimits[1] >= props.mapData.filter.maxvalue &&
        props.mapData.filter.maxvalue !== 0
      ) {
        newRanges[1] = props.mapData.filter.maxvalue;
      }
      setRangeLimits(newRanges);
      setValues(newRanges);
    }
  }, [props.mapData.filter, dirty]);
  const { updateState } = props;
  if (tab !== 'properties') {
    return null;
  }
  return (
    <RegionHeaderFilterRowView>
      {tab === 'properties' ? (
        <min-value>{`$${formatDollar(minvalue)}`}</min-value>
      ) : null}
      {tab === 'properties' ? (
        <max-value>{`$${formatDollar(maxvalue)}`}</max-value>
      ) : null}
      {tab === 'properties' ? (
        <value-slider className={styles.sliderContainer}>
          <Range
            trackStyle={[greyBackground]}
            handleStyle={[dotStyle, dotStyle]}
            activeDotStyle={dotStyle}
            step={100}
            dotStyle={dotStyle}
            railStyle={greyBackground}
            onAfterChange={() => {
              updateState({
                filter: {
                  ...props.mapData.filter,
                  dirtySlider: true,
                },
              });
            }}
            onChange={([min, max]) => {
              if (maxvalue !== max || minvalue !== min) {
                setDirty(true);
                setValues([min, max]);
                updateState({
                  filter: {
                    ...props.mapData.filter,
                    minvalue: min,
                    maxvalue: max,
                  },
                });
              }
              const { data, type, uid } = props.page;
              const { regions } = props.appdata;
              EventSearchApartment(
                data.amli_id ||
                  get(
                    regions.find((x) => x.uid === data.region.uid),
                    'data.amli_id'
                  ),
                type === 'sub-region' ? uid : null,
                min,
                max,
                minhousing,
                maxhousing
              );
            }}
            min={rangeLimits[0]}
            max={rangeLimits[1]}
            value={values}
          />
        </value-slider>
      ) : null}
      {tab === 'properties' ? (
        <min-housing>{minhousing || 'Studio'}</min-housing>
      ) : null}
      {tab === 'properties' ? (
        <max-housing>{maxhousing ? maxhousing : 'Studio'}</max-housing>
      ) : null}
      {tab === 'properties' ? (
        <housing-slider className={styles.sliderContainer}>
          <Range
            onChange={([min, max]) => {
              updateState({
                filter: {
                  ...props.mapData.filter,
                  minhousing: min,
                  maxhousing: max,
                },
              });
              const { data, type, uid } = props.page;
              const { regions } = props.appdata;
              EventSearchApartment(
                data.amli_id ||
                  get(
                    regions.find((x) => x.uid === data.region.uid),
                    'data.amli_id'
                  ),
                type === 'sub-region' ? uid : null,
                minvalue,
                maxvalue,
                min,
                max
              );
            }}
            handleStyle={[dotStyle, dotStyle]}
            dotStyle={dotStyle}
            activeDotStyle={dotStyle}
            trackStyle={[greyBackground]}
            railStyle={greyBackground}
            min={0}
            max={3}
            value={[minhousing, maxhousing]}
          />
        </housing-slider>
      ) : null}
    </RegionHeaderFilterRowView>
  );
}
