import React, { useState } from 'react';
import get from 'lodash/get';
import { withRouter } from 'react-router';
import PropertyModalView from '../../components/PropertyModalView';
import rightArrow from '../../assets/icons/icon-arrow--right--blue.svg';
import { formatDollar } from '../../helpers/utils';
import MultiLineString from '../../helpers/mutli-line-string';
import { addOrReplaceUrlParam, addQualityParam } from '../../helpers/add-or-replace-url-param';
import Info from '../../assets/icons/help.png';
import { Carousel } from 'react-responsive-carousel';
import { propertyAttributesQuery, propertyGalleryQuery } from '../../graphql/queries';

import styles from './styles.module.css';
import { compose, graphql } from 'react-apollo';

const _renderPricing = attributes => (!attributes ? null : `Starting at $${formatDollar(attributes.minPrice, 0)}`);

const _renderConfiguration = attributes => {
  if (!attributes) {
    return null;
  }
  if (attributes.minBeds === attributes.maxBeds) {
    return attributes.minBeds === 0 ? 'Studio' : `${attributes.minBeds} Bedrooms`;
  } else {
    return `${attributes.minBeds === 0 ? 'Studio' : `${attributes.minBeds} Bedrooms`} - ${attributes.maxBeds} Bedrooms`;
  }
};

const getImages = props => {
  const images = [
    ...get(props, 'propertyGalleryQuery.propertyGallery.exterior', [])
      .filter(img => !!img && !!img && !!img.image && !!img.image.url)
      .slice(0, 3),
    ...get(props, 'propertyGalleryQuery.propertyGallery.interior', [])
      .filter(img => !!img && !!img && !!img.image && !!img.image.url)
      .slice(0, 3)
  ];
  const mainImage = get(props, 'property.data.primary_property_image');
  if (!!mainImage) {
    images.unshift({
      image: mainImage
    });
  }
  return images;
};

function PropertyModal(props) {
  if (!props || !props.property) {
    return null;
  }
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const {
    name,
    address,
    phone,
    pets,
    smoke_free,
    leed_level,
    leed_level_target,
    highlights
    // logo,
    // specials
  } = props.property.data;

  let filteredHighlights;
  if (!!highlights) {
    filteredHighlights = highlights.filter(item => !!item && !!item.text);
  }

  const cat = pets === 'Yes';
  const dog = pets === 'Yes';
  const smoking = smoke_free === 'Yes';
  const leed = !!leed_level && !leed_level_target;
  // const logoImage = get(logo, "url");
  const images = getImages(props);
  const resizeImages = () => {
    images.map(image => {
      const url = addOrReplaceUrlParam(image.image.url, 'w', '800');
      const new_url = addOrReplaceUrlParam(url, 'h', 'auto');
      image.image.url = new_url;
      return image;
    });
  };
  resizeImages(images);

  const attributes = get(props, 'property.attributes', get(props, 'propertyAttributesQuery.propertyAttributes'));

  const [selected, setSelected] = useState(0);
  const settings = {
    showThumbs: false,
    showArrows: false,
    showIndicators: true,
    infiniteLoop: true,
    showStatus: false,
    centerSlidePercentage: 60
  };
  const showBedrooms = get(props, 'property.data.enable_pricing_availability_page', '');
  const preLeaseDate = get(props, 'property.data.pre-lease_start_date');
  const moveInDate = get(props, 'property.data.move_in_date');

  const getFullRoute = () => {
    let path = `/${props.property.uid}`;
    const page = get(props, 'page');
    const subregionUid = get(props, 'property.data.sub-regions[0].sub-region.uid');
    if (!!page && subregionUid && page.type === 'region') {
      path = `${props.location.pathname}/${subregionUid}-apartments/${props.property.uid}`;
    } else if (!!page && page.type === 'sub-region') {
      path = `${props.location.pathname.replace(page.uid, subregionUid)}/${props.property.uid}`;
    }
    return path;
  };

  return (
    <PropertyModalView>
      <modal-wrapper className={styles.wrapper}>
        <title>
          {name}
          <div className={styles.whiteTriangleDesktop} />
          <img
            alt="close"
            src="/images/Filter-Opened.png"
            tabIndex={0}
            className={styles.close}
            onKeyDown={e => e.keyCode === 13 && !!props.onClickClose && props.onClickClose()}
            onClick={() => props.onClickClose && props.onClickClose()}
          />
        </title>
        <address>{MultiLineString(address)}</address>
        {phone ? (
          <phone href={`tel:${phone}`} className={styles.phone}>
            {phone}
          </phone>
        ) : null}
        <images>
          <div className={styles.sliderWrapper}>
            <img
              src="/images/icon-arrow--slide-left3x.png"
              alt=""
              className={`${styles.sliderController} ${styles.leftArrow}`}
              onClick={() => {
                if (images && images.length) {
                  setSelected((selected + images.length - 1) % images.length);
                }
              }}
            />
            <img
              src="/images/icon-arrow--slide-right3x.png"
              alt=""
              className={`${styles.sliderController} ${styles.rightArrow}`}
              onClick={() => {
                if (images && images.length) {
                  setSelected((selected + 1) % images.length);
                }
              }}
            />
            <Carousel {...settings} selectedItem={selected} onClickItem={() => props.history.push(`${getFullRoute()}`)}>
              {images.map(image => (
                <div className={styles.slideContainer} key={image}>
                  <img key={image} src={addQualityParam(image.image.url, 5)} alt="property" className={styles.image} />
                </div>
              ))}
            </Carousel>
          </div>
        </images>
        <pricing className={styles.pricingWrapper}>
          {showBedrooms === 'Yes' ? (
            <React.Fragment>
              {_renderPricing(attributes)}
              <div className={styles.disclaimerContainer}>
                <img
                  tabIndex={0}
                  onClick={() => setShowDisclaimer(!showDisclaimer)}
                  onKeyDown={e => e.keyCode === 13 && setShowDisclaimer(!showDisclaimer)}
                  className={styles.infoIcon}
                  src={Info}
                  alt="more info"
                />
                <div className={[showDisclaimer ? styles.showDisclaimer : '', styles.priceDisclaimer].join(' ')}>
                  "Starting at” reflects pricing for the lowest rent offered of an available unit at the Community regardless of the number of
                  bedrooms. Unavailable units may come online with lower rent, so check back often.
                </div>
                <div className={[showDisclaimer ? styles.showDisclaimer : '', styles.toolTipArrow].join(' ')} />
              </div>
            </React.Fragment>
          ) : moveInDate ? (
            <div className="af-class-property-card-bedroom">
              <p style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Move In Start Date:</span>
                <span style={{ fontSize: '14px', color: '#787878' }}>{moveInDate}</span>
              </p>
            </div>
          ) : (
            ''
          )}
        </pricing>
        <configuration className={styles.configuration}>
          {showBedrooms === 'Yes' ? (
            _renderConfiguration(attributes)
          ) : preLeaseDate ? (
            <p style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Pre-Lease Start Date:</span>
              <span style={{ fontSize: '14px', color: '#787878' }}>{preLeaseDate}</span>
            </p>
          ) : (
            ''
          )}
        </configuration>
        {!props.responsive && filteredHighlights && filteredHighlights.length && (
          <highlights>
            <highlight-list className={styles.highlightList}>
              {filteredHighlights.map(({ text }, index) => {
                if (window.innerHeight <= 900 && index >= 2) return null;
                return (
                  <li className="af-class-property-card-modal-feat-list-item" key={`highlight_${index}`}>
                    {text}
                  </li>
                );
              })}
            </highlight-list>
          </highlights>
        )}
        {dog && (
          <dog>
            <dog />
          </dog>
        )}
        {cat && (
          <cat>
            <cat />
          </cat>
        )}
        {leed && (
          <leed>
            <leed />
          </leed>
        )}
        {smoking && (
          <smocking>
            <smocking />
          </smocking>
        )}
        <link
          className={styles.link}
          tabIndex={0}
          onKeyDown={e => {
            if (e && e.stopPropagation) {
              e.stopPropagation();
            }
            if (e.keyCode !== 13) {
              return null;
            }
            props.history.push(`${getFullRoute()}`);
          }}
          onTouchStart={e => {
            if (e && e.stopPropagation) {
              e.stopPropagation();
            }
            props.history.push(`${getFullRoute()}`);
          }}
          onMouseDown={e => {
            if (e && e.stopPropagation) {
              e.stopPropagation();
            }
            props.history.push(`${getFullRoute()}`);
          }}
        >
          <div className={`af-class-text-block-28 ${styles.linktag}`}>View Property</div>
          <img src={rightArrow} alt="right arrow" className={styles.arrow} />
        </link>
      </modal-wrapper>
    </PropertyModalView>
  );
}

export default compose(
  graphql(propertyGalleryQuery, {
    name: 'propertyGalleryQuery',
    skip: props => !props || !props.property || !props.property.id,
    options: props => ({
      variables: {
        id: props.property.id
      }
    })
  }),
  graphql(propertyAttributesQuery, {
    name: 'propertyAttributesQuery',
    skip: props => !props || !props.property || !props.property.data || !props.property.data.amli_id || !props.property.data.review_push_id,
    options: props => ({
      variables: {
        id: String(props.property.data.amli_id),
        review_push_id: String(props.property.data.review_push_id),
        reputationdotcom_id: String(props.property.data.reputationdotcom_id),
        reputationdotcom_reviews_enabled: Boolean(props.property.data.reputationdotcom_reviews_enabled)
      }
    })
  })
)(withRouter(PropertyModal));
