import React from "react";
import get from "lodash/get";
import Helmet from "react-helmet-async";
import PropertyContactMapView from "../../../components/PropertyContactMapView";
import PropertyContactCtaView from "../../../components/PropertyContactCtaView";
import BuildCanonicalLink from "../../build-canonical-link";
import { ExtractMetaProperty } from "../../extract-meta";

import EntrataWidget from "../../entrata-widget";

export const ContactMeta = ({ page }) => {
    const canonicalLink = BuildCanonicalLink(page, 'propertyContact:contact');
    const meta = ExtractMetaProperty(get(page, "data"), 'contact', canonicalLink);
    return (
        <Helmet>
            {meta}
        </Helmet>
    );
};

export const contactSlices = props => {
    const propertyData = get(props, "property.data");
    const property = get(props, "property");
    const ctaSlice = (
        get(props, "preview.data.body") ||
        propertyData.body ||
        []
    ).find(pd => pd.slice_type === "property_contact_cta");

    return [
        <React.Fragment>
            <EntrataWidget doNotIgnore={true} {...props} />
            <PropertyContactMapView.Controller
                slice={props.property}
                property={props.property}
            />
            <PropertyContactCtaView.Controller slice={ctaSlice} page={property} />
        </React.Fragment>
    ];
};
