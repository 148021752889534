import React, { useState } from 'react';

import styles from './styles.module.css';

const CollapsibleSection = ({ sectionTitle, startOpen, id, children }) => {
  const [isOpen, setIsOpen] = useState(!!startOpen);

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.collapsibleSection} isopen={String(isOpen)} id={id}>
      <div onClick={handleClick} className={styles.collapsibleHeader}>
        <h3>{sectionTitle}</h3>
        <div className={styles.collapsibleArrow} isopen={String(isOpen)}></div>
      </div>
      <div className={styles.collapsibleContent} isopen={String(isOpen)}>
        {children}
      </div>
    </div>
  );
};

export default CollapsibleSection;
