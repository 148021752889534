const settings =
  "scrollbars=no,toolbar=no,location=no,titlebar=no,directories=no,status=no,menubar=no";
const getPopupOffset = ({ width, height }) => {
  const wLeft = window.screenLeft ? window.screenLeft : window.screenX;
  const wTop = window.screenTop ? window.screenTop : window.screenY;

  const left = wLeft + window.innerWidth / 2 - width / 2;
  const top = wTop + window.innerHeight / 2 - height / 2;

  return { top, left };
};
const getPopupDimensions = ({ width, height }) => {
  const { top, left } = getPopupOffset({ width, height });
  return `width=${width},height=${height},top=${top},left=${left}`;
};

const pageOpener = (url, width = 1000, height = 600) =>
  window.open(url, "", `${settings},${getPopupDimensions({ width, height })}`);

export default pageOpener;
