import React, { useEffect, useLayoutEffect } from "react";
import get from "lodash/get";
import SustainabilityHighlightsExpandedView from "../../components/SustainabilityHighlightsExpandedView";
import HighlightsWrapperView from "../../components/HighlightsWrapperView";
import { personaColorStyle } from "../../helpers/property/persona";
import { addOrReplaceUrlParam, addQualityParam } from "../../helpers/add-or-replace-url-param";
import styles from './styles.module.css';
import "./index.css";
import { LazyLoaderElement, LazyLoader } from "../../helpers/lazy-loader";

export default props => {
    const { data } = props;
    const mainImageLarge = get(data, "primary.main_image.url");
    const mainImageAlt = get(data, "primary.main_image_alt_text");
    const subtitle = get(data, "primary.subtitle");
    const title = get(data, "primary.title");
    const textBlurb = get(data, "primary.text_blurb");
    const secondaryImage = get(data, "primary.secondary_image.url");
    const secondaryImageAlt = get(data, "primary.secondary_image_alt_text");
    const leed = get(data, "primary.leed_level1");
    const highlights = get(data, "items") || [];
    const leed_logo = get(props, "slice.primary.leed_logo1.url");
    const property = get(props, 'property') || get(props, 'page.data');
    const width = props.pageWidth
    const mainImage = addOrReplaceUrlParam(mainImageLarge, 'w', width > 1024 ? width * 1.25 : width * 2 );

    useLayoutEffect(() => {

            ////#region SETUP lazy loading

            //let lazyLoaderElementList = [];

            //let lazyLoaderElementItem = new LazyLoaderElement();
            ////data-src="/images/sustainable-banner2x.jpg" data-srcSet="images/sustainable-banner2x-p-500.jpeg 500w, images/sustainable-banner2x-p-800.jpeg 800w, images/sustainable-banner2x-p-2000.jpeg 2000w, images/sustainable-banner2x-p-2600.jpeg 2600w, images/sustainable-banner2x.jpg 2880w"

            ////STORE images TO lazy load
            //if (mainImage) {
            //    lazyLoaderElementItem = new LazyLoaderElement();
            //    lazyLoaderElementItem.Id = "sustainablity_highlights_image";
            //    lazyLoaderElementItem.Src = addQualityParam(mainImage, 5);
            //    lazyLoaderElementItem.SrcSet = addQualityParam(mainImage, 5);
            //    lazyLoaderElementItem.Alt = mainImageAlt || "";

            //    lazyLoaderElementList.push(lazyLoaderElementItem);

            //    const lazyLoader = new LazyLoader();
            //    lazyLoader.LazyLoad(lazyLoaderElementList);
            //}

            ////#endregion
    })
    return (
        <SustainabilityHighlightsExpandedView {...props}>
            <main-image src={mainImage} />
            <subtitle style={personaColorStyle(property, 'secondary')}>{subtitle}</subtitle>
            <title style={personaColorStyle(property)}>{title}</title>
            <text-blurb>{textBlurb}</text-blurb>
            {leed && leed.toLowerCase() !== "none" && !leed_logo ? (
                <leed-logo
                    className={styles.logo}
                    src={`./images/leed-${leed.toLowerCase()}.png`}
                    srcSet={`./images/leed-${leed.toLowerCase()}.png`}
                    alt={`LEED Logo ${leed}`}
                />
            ) : null}
            {!!leed_logo ? (
                <leed-logo
                    className={styles.logo}
                    src={addQualityParam(leed_logo, 5)}
                    srcSet={addQualityParam(leed_logo, 5)}
                    alt="LEED Logo"
                />
            ) : null}
            {secondaryImage ? (
                <secondary-image
                    src={addQualityParam(secondaryImage, 5)}
                    srcSet={addQualityParam(secondaryImage, 5)}
                    alt={secondaryImageAlt || ""}
                />
            ) : (
                <secondary-image />
            )}
            <HighlightsWrapperView.Controller data={highlights} />
        </SustainabilityHighlightsExpandedView>
    );
};
