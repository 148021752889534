import gql from 'graphql-tag';

export const propertyAttributesQuery = gql`
  query propertyAttributes($id: String!, $review_push_id: ID, $reputationdotcom_id: ID, $reputationdotcom_reviews_enabled: Boolean) {
    propertyAttributes(
      id: $id
      review_push_id: $review_push_id
      reputationdotcom_id: $reputationdotcom_id
      reputationdotcom_reviews_enabled: $reputationdotcom_reviews_enabled
    ) {
      minPrice
      maxPrice
      minBeds
      maxBeds
      reviews
      availabeUnitCount
      availablePercent
      propertyTag
      displayOrder
    }
  }
`;
