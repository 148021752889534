import React, { useEffect } from "react";
import get from "lodash/get";
import { withRouter } from "react-router-dom";
import ContentImageAndRichBlurbCtaView from "../../components/ContentImageAndRichBlurbCtaView";
import { handlePrismicLink } from "../../helpers/link-helper";
import MultiLineString from "../../helpers/mutli-line-string";
import BackgroundImageHandler from "../../helpers/components-data-handlers/background-image-handler";
import styles from './styles.module.css';
import './index.css';
import { LazyLoaderElement, LazyLoader } from "../../helpers/lazy-loader";

export default withRouter((props) => {
    const { data } = props;
    const pageName = get(props, "page.uid", "");
    const linkData = get(data, "primary.cta_link1");
    const link = handlePrismicLink(props, linkData);
    const image = BackgroundImageHandler(get(data, "primary.image"));
    const subtitle = get(data, "primary.subtitle");
    const title = get(data, "primary.title[0].text");
    const textBlurb = get(data, "primary.text_blurb[0].text");
    const ctaText = get(data, "primary.cta_text1");

    let lazyLoaderElementList = [];

    useEffect(() => {
        //#region SETUP lazy loading

        //STORE background image TO lazy load
        let lazyLoaderElementItem = new LazyLoaderElement();

        //STORE images TO lazy load
        lazyLoaderElementItem = new LazyLoaderElement();
        lazyLoaderElementItem.Id = "lazy_content_image_and_rich_blurb_cta_home_about";
        lazyLoaderElementItem.BackgroundImage = image.style.backgroundImage;
        lazyLoaderElementItem.BackgroundPosition = image.style.backgroundPosition;
        lazyLoaderElementItem.BackgroundSize = image.style.backgroundSize;

        lazyLoaderElementList.push(lazyLoaderElementItem);

        const lazyLoader = new LazyLoader();
        lazyLoader.LazyLoad(lazyLoaderElementList);

        //#endregion
    }, []);

    return (
        <ContentImageAndRichBlurbCtaView>
            <wrapper className=
                {`
        ${pageName !== 'home' ? 'other-page-wrapper' : ''}
        ${pageName === 'sustainability' ? 'sustainability-page-wrapper' : ''}
        `}
            >
                <image
                    className={pageName !== 'home' ? styles.otherImage : styles.image}

                />
                {subtitle ?
                    <subtitle className={styles.subtitle}>{subtitle}</subtitle>
                    : null
                }
                <title className={pageName === 'home' ? styles.title : ''}>
                    {MultiLineString(title)}
                </title>
                <text-blurb>{textBlurb}</text-blurb>
                <link-url
                    {...link}
                    className={styles.button}
                >
                    <link-text className={styles.buttonText}>{ctaText}</link-text>
                    <link-arrow className={styles.buttonArrow} alt="Right arrow." />
                </link-url>
            </wrapper>
        </ContentImageAndRichBlurbCtaView>
    )
});
