import React from "react";
import { withRouter } from "react-router-dom";
import { graphql } from "react-apollo";
import NotFoundView from "../../views/NotFoundView";
import GatherNavData from "../../helpers/gather-navbar-data";
import { pagesQuery } from "../../graphql/queries";

class NotFound extends React.Component {

    componentWillMount() {
        const { staticContext } = this.props;
        if (staticContext) {
            staticContext.is404 = true;
        }
    }

    render() {
        const links = GatherNavData(this.props);
        return (
            <NotFoundView links={links}>
                <try-looking
                    href="/"
                    onClick={e => {
                        if (e) {
                            e.preventDefault();
                        }
                        this.props.history.replace("/");
                    }}
                />
                <keep-exploring
                    href="/"
                    onClick={e => {
                        if (e) {
                            e.preventDefault();
                        }
                        this.props.history.replace("/");
                    }}
                />
            </NotFoundView>
        );
    }
}

export default withRouter(graphql(pagesQuery)(NotFound));
