import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { graphql, compose } from 'react-apollo';
import { amenitiesQuery } from './../../graphql/queries/amenitiesQuery';
import PropertyComparisonTable from '../PropertyComparisonTable';

import styles from './styles.module.css';

const PropertyComparisonAmenitiesSection = compose(
  graphql(amenitiesQuery, {
    name: 'amenities',
    skip: (props) => {
      return !props.property || !props.property.id;
    },
    options: (props) => ({
      variables: {
        propertyPageId: props.property.id,
      },
    }),
  })
)((props) => {
  const { link } = props;
  const moveInDate = get(props, 'property.data.move_in_date');
  const setMountedCallback = get(props, 'setMountedCallback', () => {});
  const name = get(props, 'property.data.name');
  const uid = get(props, 'property.uid');
  const amenitiesBody = get(props, 'amenities.amenities.data.body', []);
  const amenities = get(
    amenitiesBody.find((item) => {
      return item && item.primary && item.primary.subtitle === props.targetChild;
    }),
    'items',
    []
  )
    .filter((amenity) => {
      return props.amenitiesToDisplay.indexOf(amenity.amenity_type) > -1;
    })
    .sort((a, b) => {
      return (
        props.amenitiesToDisplay.indexOf(a.amenity_type) -
        props.amenitiesToDisplay.indexOf(b.amenity_type)
      );
    });

  const amenitiesGrouped = props.amenitiesToDisplay.map((type) => {
    return amenities.filter(({ amenity_type }) => amenity_type === type);
  });

  useEffect(() => {
    setMountedCallback();
  }, [props.amenities === null || (props.amenities && props.amenities.loading === false)]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateWidth = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', updateWidth);

    return window.removeEventListener('resize', updateWidth);
  });

  const getFullRoute = (property) => {
    let path = `/${property.uid}`;
    path = `${window.location.pathname}/${property.uid}`;
    return path;
  };

  return (
    <PropertyComparisonTable
      title={
        name ? name : uid ? uid.replaceAll('-', ' ').replaceAll('amli', 'AMLI') : 'AMLI Property'
      }
      link={link}
      setMountedCallback={props.setMountedCallback}
    >
      {amenities.length > 0
        ? amenitiesGrouped.map((group, index) => {
            return (
              <li key={`amenity-group-${index}`}>
                {group && group.length ? (
                  group.map(({ amenity }, subIndex) => (
                    <p key={`amenity-${subIndex}`} className={styles.amenityItem}>
                      {group.length > 1 && windowWidth <= 720 ? `-${amenity}` : amenity}
                    </p>
                  ))
                ) : (
                  <p className={styles.amenityItem}>No Comparable amenities</p>
                )}
              </li>
            );
          })
        : props.amenitiesToDisplay.map((amenity, index) => {
            return (
              <li key={`amenity-${index}`}>
                <p className={styles.amenityItem}>
                  {moveInDate ? `Coming ${moveInDate}` : 'Coming Soon'}
                </p>
              </li>
            );
          })}
    </PropertyComparisonTable>
  );
});

export default PropertyComparisonAmenitiesSection;
