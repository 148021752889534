const GetCanonicalLinkPageContext = (context) => {
  const pageContext = context.split(':')[1]
  switch (pageContext) {
    case 'amenities':
      return '/amenities';
    case 'furnished':
      return '/short-term-furnished';
    case 'location':
      return '/location';
    case 'contact':
      return '/contact'
    case 'floorplans':
      return '/floorplans';
    default:
      return '';
  }
}

export default GetCanonicalLinkPageContext;
