// import React from "react";

import GetPropertyFullRoute from './get-full-property-route';
import GetCanonicalLinkPageContext from './get-canonical-link-context';
import GetRegionSlug from './get-region-slug';
import get from "lodash/get";

const BuildCanonicalLink = (props, context) => {

  const linkPage = GetCanonicalLinkPageContext(context);
  const linkPageType = context.split(':')[0];
  const baseUrl = 'https://www.amli.com';

  let url;
  let page;

  if (linkPageType === 'default') {
    page = props.page
  } else if (linkPageType === 'property') {
    page = props.property;
  } else if (linkPageType === 'propertyContact' || linkPageType === 'propertyFloorplans') {
    page = props;
  } else if (linkPageType === 'propertyFloorplan') {
    const slug = get(props, 'location.pathname');
    return `${baseUrl}${slug}`
  }

  if (page) {
    switch (page.type) {
        case "standard_page":
            if (page.uid && page.data && page.data.parent && page.data.parent.uid){
              url = `${baseUrl}/${page.data.parent.uid}/${page.uid}`;
            } else if (page && page.type && page.uid === 'home') {
              url = baseUrl;
            } else if (page && page.type && page.uid) {
              url = `${baseUrl}/${page.uid}`;
            } else {
              url = undefined;
            }
            break;
        case "region":
            if (page.uid) {
              url = `${baseUrl}/apartments/${page.uid}`;
            } else {
              url = undefined;
            }
            break;
        case "sub-region":
            if (page.uid && page.data.region && page.data.region.uid){
                url = `${baseUrl}/apartments/${page.data.region.uid}/${page.uid}-apartments`;
            } else {
              url = undefined;
            }
            break;
        case "blog_post":
            if (page.uid) {
              url = `${baseUrl}/blog/${page.uid}`;
            } else {
              url = undefined;
            }
            break;
        case "property":
            const propertyRoute = GetPropertyFullRoute(props.property, props.appdata);
            const regionSlug = GetRegionSlug(page.data.regionTag);
            const subRegionUid = get(page, "data.sub-regions[0].sub-region.uid");
            if (propertyRoute && page.uid) {
              url = `${baseUrl}${propertyRoute}/${page.uid}${linkPage}`;
            } else if (regionSlug && subRegionUid && page.uid) {
              url = `${baseUrl}/apartments/${regionSlug}/${subRegionUid}-apartments/${page.uid}${linkPage}`;
            } else {
              url = undefined;
            }
            break;
        case "blog_list_page":
            if (page.uid) {
              url = `${baseUrl}/${page.uid}`;
            } else {
              url = undefined;
            }
            break;
        default:
            if (page.uid) {
              url = `${baseUrl}/${page.uid}`;
            } else {
              url = undefined;
            }
            break;

    }
    return url;
  }
}

export default BuildCanonicalLink;
