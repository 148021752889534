import { addOrReplaceUrlParam, addQualityParam } from "./add-or-replace-url-param";
import { canUseDOM } from "./utils";
import BackgroundImageHandler from "./components-data-handlers/background-image-handler";

import get from "lodash/get";
import $ from "jquery";

//#region VARIABLES

var _lazyloaderObserverResponseHandler = null;

//#endregion

class LazyLoader {
    //#region VARIABLES
    _lazyloaderObserverHandler = null;
    _lazyloaderObserverElementList = [];

    //#endregion

    //#region CONSTRUCTOR
    constructor() {
        _lazyloaderObserverResponseHandler = this.lazyloaderObserverResponseHandler;
    }
    //#endregion

    //#region METHODS
    LazyLoad(lazyloaderObserverElementList)
    {
        this._lazyloaderObserverElementList = lazyloaderObserverElementList;

        if (document != undefined && document != null)
        {
            if ("IntersectionObserver" in window) {
                //#region SETUP lazy loading USING Strategy A
                this._lazyloaderObserverHandler = new IntersectionObserver(this._lazyloaderObserverEventHandler, null);

                if (lazyloaderObserverElementList) {
                    lazyloaderObserverElementList.map(lazyloaderObserverElementItem => {
                        const lazyloaderObserverElement = document.getElementById(lazyloaderObserverElementItem.Id);

                        if (lazyloaderObserverElement) {
                            this._lazyloaderObserverHandler.observe(lazyloaderObserverElement);
                        }
                    });
                }
                //#endregion
            }
            else {
                //#region SETUP lazy loading USING Strategy B
                let lazyloadThrottleTimeout = null;

                const LazyLoaderSetup_OlderBrowser = () => {
                    if (lazyloadThrottleTimeout) {
                        clearTimeout(lazyloadThrottleTimeout);
                    }

                    const lazyloaderObservedElementListCopy = this._lazyloaderObserverElementList;

                    lazyloadThrottleTimeout = setTimeout(function () {
                        const lazyloadWindowTop = window.pageYOffset;

                        lazyloaderObserverElementList.map(lazyloaderObserverElementItem => {
                            const lazyloaderObserverElement = document.getElementById(lazyloaderObserverElementItem.Id);

                            if (lazyloaderObserverElement.offsetTop < (window.innerHeight + lazyloadWindowTop)) {
                                _lazyloaderObserverResponseHandler(lazyloaderObservedElementListCopy, lazyloaderObserverElement, null);
                            }
                        });

                        if (lazyloaderObserverElementList.length == 0) {
                            window.removeEventListener("scroll", LazyLoaderSetup_OlderBrowser, true);
                            window.removeEventListener("resize", LazyLoaderSetup_OlderBrowser, true);
                            window.removeEventListener("orientationChange", LazyLoaderSetup_OlderBrowser, true);
                        }
                    }, 20);
                }

                //SETUP document and window listerners
                window.addEventListener("scroll", LazyLoaderSetup_OlderBrowser, true);
                window.addEventListener("resize", LazyLoaderSetup_OlderBrowser, true);
                window.addEventListener("orientationChange", LazyLoaderSetup_OlderBrowser, true);

                //#endregion
            }
        }
    }

    //#endregion

    //#region EVENTS
    _lazyloaderObserverEventHandler = (lazyloaderObservedElementList, lazyloaderObservedHandler) => {

        const lazyloaderObservedElementListCopy = this._lazyloaderObserverElementList;

        lazyloaderObservedElementList.forEach(function (lazyloaderObservedElementItem) {
            if (lazyloaderObservedElementItem.isIntersecting) {
                const lazyloaderObservedElement = lazyloaderObservedElementItem.target;

                _lazyloaderObserverResponseHandler(lazyloaderObservedElementListCopy, lazyloaderObservedElement, lazyloaderObservedHandler);
            }
        });
    }

    lazyloaderObserverResponseHandler(lazyloaderObservedElementList, lazyloaderObservedElement, lazyloaderObservedHandler) {
        let lazyloaderObservedElementInfoMetaData = new LazyLoaderElement();
        lazyloaderObservedElementInfoMetaData = lazyloaderObservedElementList.find(lazyloaderObserverElementItem => lazyloaderObserverElementItem.Id == lazyloaderObservedElement.id);

        if (lazyloaderObservedElementInfoMetaData != null) {
            if (lazyloaderObservedElementInfoMetaData.Alt != "") {
                lazyloaderObservedElement.alt = lazyloaderObservedElementInfoMetaData.Alt;
            }

            if (lazyloaderObservedElementInfoMetaData.BackgroundImage != "") {
                lazyloaderObservedElement.style.backgroundImage = lazyloaderObservedElementInfoMetaData.BackgroundImage;
            }

            if (lazyloaderObservedElementInfoMetaData.BackgroundPosition != "") {
                lazyloaderObservedElement.style.backgroundPosition = lazyloaderObservedElementInfoMetaData.BackgroundPosition;
            }

            if (lazyloaderObservedElementInfoMetaData.BackgroundSize != "") {
                lazyloaderObservedElement.style.backgroundSize = lazyloaderObservedElementInfoMetaData.BackgroundSize;
            }

            if (lazyloaderObservedElementInfoMetaData.ClassName != "") {
                lazyloaderObservedElement.className = lazyloaderObservedElementInfoMetaData.ClassName;
            }

            if (lazyloaderObservedElementInfoMetaData.Height != "") {
                lazyloaderObservedElement.height = lazyloaderObservedElementInfoMetaData.Height;
            }

            if (lazyloaderObservedElementInfoMetaData.Role != "") {
                lazyloaderObservedElement.role = lazyloaderObservedElementInfoMetaData.Role;
            }

            if (lazyloaderObservedElementInfoMetaData.Src != "") {
                lazyloaderObservedElement.src = lazyloaderObservedElementInfoMetaData.Src;
            }

            if (lazyloaderObservedElementInfoMetaData.SrcSet != "") {
                lazyloaderObservedElement.srcset = lazyloaderObservedElementInfoMetaData.SrcSet;
            }

            if (lazyloaderObservedElementInfoMetaData.Width != "") {
                lazyloaderObservedElement.width = lazyloaderObservedElementInfoMetaData.Width;
            }
        }

        if (lazyloaderObservedHandler != null) {
            lazyloaderObservedHandler.unobserve(lazyloaderObservedElement);
        }
    }
    //#endregion
}

class LazyLoaderElement {
    //#region VARIABLES
    Alt = "";
    BackgroundImage = "";
    BackgroundPosition = "";
    BackgroundSize = "";
    ClassName = "";
    Id = "";
    Height = "";
    Role = "";
    Src = "";
    SrcSet = "";
    Width = "";

    //#endregion

    //#region CONSTRUCTOR
    constructor() {

    }
    //#endregion
}

export { LazyLoader, LazyLoaderElement };
