import gql from "graphql-tag";

export const getAllSwitchCodesQuery = gql`
  query getAllSwitchCodes {
    getAllSwitchCodes {
        provider
        code
    }
  }
`;
