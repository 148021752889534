import React from "react";
import flatten from "lodash/flatten";
import { Route } from "react-router-dom";
import PageRenderer from "../page-renderer";

import {
    composeAmenities,
    composeContact,
    composeFloorplans,
    composeFurnished,
    composeGrid,
    composeLocation,
    composePricing,
    composeProperty
} from "./property";

const getFullRoute = (page, isSiteMap = false) => {
    let url;

    switch (page.type) {
        case "standard_page":
            if (page && page.data && page.data.parent && page.data.parent.uid){
                url = `/${page.data.parent.uid}/${page.uid}`
            }
            break;
        case "region":
            url = `/apartments/${page.uid}`;
            break;
        case "sub-region":
            if (page && page.data && page.data.region && page.data.region.uid){
                url = `/apartments/${page.data.region.uid}/${page.uid}-apartments`;
            }
            break;
        case "blog_post":
            url = `/blog/${page.uid}`;
            break;
        default:
            if (page.type.toUpperCase() == "TAG" && isSiteMap) {
                return "tag";
            }
            else {
                url = `/${page.uid}`;
            }           

            break;
    }
    return url;
}
const composeRoutes = (page, data, nav, setLoaded, isSiteMap = false, filterById = "") => {
    if (!page) {
        return null;
    }
     
    const routeParams = [
        `/${page.uid}`,
        ...(page.slugs || []).map(slug => slug ? `/${slug}` : null).filter(slug => !!slug)
    ];
    const formattedRoute = getFullRoute(page, isSiteMap);

    if (formattedRoute === "tag") {
        return null;
    }

    if (formattedRoute) {
        routeParams.push(formattedRoute);
    }

    const route = (
        <Route 
            key={page.id} 
            path={page.uid === "home" ? "/" : routeParams}
            render={props => (
                <PageRenderer 
                    page={page} 
                    appdata={data} 
                    nav={nav} 
                    setLoaded={setLoaded} 
                    refresh={Math.random().toString(36).substring(7)} 
                    {...props} 
                />
            )}
            exact
        />
    );

    // If filterById is provided and matches the page UID, return only this route
    if (filterById && filterById.toUpperCase() === page.uid.toUpperCase()) {
        return route;
    }

    // If filterById is not provided or does not match, return the route
    if (!filterById) {
        return route;
    }

    // If filterById is provided but doesn't match, return null (no routes)
    return null;
};

const filterSpecificRenderers = page => {
    return !!page && !!page.data && !!page.type && page.type !== "property";
};

const Routes = (pages, setLoaded, isSiteMap = false, filterById = "") => {

    const footer = (pages || []).filter(
        page => !!page && !!page.data && page.data.show_in_footer_nav === "Yes"
    );
    const topHeader = (pages || []).filter(
        page => !!page && !!page.data && page.data.show_in_top_level_nav === "Yes"
    );
    const subHeader = (pages || []).filter(
        page => !!page && !!page.data && page.data.show_in_sub_level_nav === "Yes"
    );

    const regions = (pages || []).filter(
        page => !!page && !!page.data && !!page.type && page.type === "region"
    );

    const subregions = (pages || []).filter(
        page => !!page && !!page.data && !!page.type && page.type === "sub-region"
    );

    const properties = (pages || []).filter(
        page => !!page && !!page.data && !!page.type && page.type === "property"
    );

    const blogposts = (pages || []).filter(
        page => !!page && !!page.data && !!page.type && page.type === "blog_post"
    );

    const bloglistpages = (pages || []).filter(
        page =>
            !!page && !!page.data && !!page.type && page.type === "blog_list_page"
    );

    const tags = (pages || []).filter(
        page => !!page && !!page.data && !!page.type && page.type === "tag"
    );

    const standard_page = (pages || []).filter(
        page =>
            !!page && !!page.data && !!page.type && page.type === "standard_page"
    );

    const amenities = (pages || []).filter(
        page =>
            !!page && !!page.data && !!page.type && page.type === "amenities_page"
    );
    const location = (pages || []).filter(
        page =>
            !!page && !!page.data && !!page.type && page.type === "location_page"
    );
    const furnished = (pages || []).filter(
        page =>
            !!page && !!page.data && !!page.type && page.type === "short_term_furnished"
    );

    const nav = {
        footer,
        topHeader,
        subHeader,
        regions,
        subregions,
        properties,
        standard_page
    };

    const data = {
        regions,
        subregions,
        properties,
        blogposts,
        bloglistpages,
        tags,
        standard_page,
        amenities,
        location,
        furnished
    };

    return [
        ...pages
            .filter(filterSpecificRenderers)
            .map(page => composeRoutes(page, data, nav, setLoaded, isSiteMap, filterById)),
        ...flatten(properties.map(property => [
            composeProperty(property, { regions, subregions, properties }, nav, isSiteMap),
            composePricing(property, { regions, subregions, properties }, nav, isSiteMap),
            composeFloorplans(property, { regions, subregions, properties }, nav, isSiteMap),
            composeGrid(property, { regions, subregions, properties }, nav, isSiteMap),
            composeLocation(property, data, nav, isSiteMap),
            composeAmenities(property, data, nav, isSiteMap),
            composeFurnished(property, data, nav, isSiteMap),
            composeContact(property, { regions, subregions, properties }, nav, isSiteMap)
        ]))
    ];
};

export default Routes;
