import React, { useEffect, useState } from 'react';
import EntrataWidget from '../../helpers/entrata-widget';
import { canUseDOM } from '../../helpers/utils';
import styles from './styles.module.css';

const InteractiveCommunitiesMap = (props) => {
  const { engrainId } = props;

  const [engrainProperty, setEngrainProperty] = useState();

  const findProperty = (termToSearch) => {
    return (property) => {
      const name = property.data.name;
      return name.includes(termToSearch);
    };
  };

  const getMetrics = () => {
    const loadUnitList = () => {
      window.dataLayer.push({
        event: 'interact.engrainMap',
        subregion: props.data.name,
      });
    };
    const clickOnUnit = (event) => {
      const termToSearch = event.data.unit.unitNumber.replace(/^.*-\s/i, '');
      const property = props.properties.find(findProperty(termToSearch));
      const propertyId = property.data.amli_id;
      const floorplanId = event.data.unit.floorPlan.id;
      const unitId = event.data.unit.id;
      window.dataLayer.push({
        event: 'interact.engrainMap.unitClick',
        propertyId,
        floorplanId,
        unitId,
      });
      setEngrainProperty(property);
      window.removeEventListener('mcb-ready', handleReadyEvent);
    };
    const clickOnOutbound = (event) => {
      const termToSearch = event.data.unit.unitNumber.replace(/^.*-\s/i, '');
      const property = props.properties.find(findProperty(termToSearch));
      const propertyId = property.data.amli_id;
      const floorplanId = event.data.unit.floorPlan.id;
      const unitId = event.data.unit.id;
      // // Check if event is a contact us
      const eventLabel = event.data.label;
      if (eventLabel.toLowerCase() === 'contact us') {
        window.dataLayer.push({
          event: 'clickOnContactUs',
          property: propertyId,
          floorplan: floorplanId,
          unit: unitId,
        });
      } else if (eventLabel.toLowerCase() === 'schedule a tour') {
        openScheduleTour();
      }
    };
    const changedFilter = () => {
      window.dataLayer.push({
        event: 'interact.engrainMap.changedFilters',
        subregion: props.data.name,
      });
    };
    const clickOnQuote = (event) => {
      const termToSearch = event.data.unit.unitNumber.replace(/^.*-\s/i, '');
      const property = props.properties.find(findProperty(termToSearch));
      window.dataLayer.push({
        event: 'clickGetQuote',
        'clickGetQuote.property': property.data.name,
        'clickGetQuote.context': 'Engrain',
      });
    };
    const tourClicked = (event) => {
      const floorplanId = event.data.unit.floorPlan.id;
      const unitId = event.data.unit.id;

      window.dataLayer.push({
        event: 'clickOnScheduleATour',
        floorplan: floorplanId,
        unit: unitId,
      });

      openScheduleTour();
    };

    const embed = new window.SightMap.Embed('sightmap');

    embed.on('metrics.unitList.impression', loadUnitList);
    embed.on('metrics.unitMap.unit.click', clickOnUnit);
    embed.on('metrics.unitList.unit.click', clickOnUnit);
    embed.on('metrics.unitDetails.apply.click', clickOnQuote);
    embed.on('metrics.unitDetails.outbound.click', clickOnOutbound);
    embed.on('metrics.filters.change', changedFilter);
    // custom events
    embed.on('outbound.entrata.tour.click', tourClicked);
  };

  useEffect(() => {
    if (canUseDOM && engrainId && window.SightMap) {
      getMetrics();
    }
  }, [canUseDOM, window.SightMap, engrainId]);

  const handleReadyEvent = () => {
    openScheduleTour();
  };

  const openScheduleTour = () => {
    window.addEventListener('mcb-ready', handleReadyEvent, { once: true });

    if (window && window.ppcb) {
      try {
        window.ppcb.render();
        window.ppcb.open('schedule-tour');
        return;
      } catch (e) {
        console.log('Entrata widget error', e);
      }
    }
  };

  if (!canUseDOM || !engrainId) {
    return null;
  }

  const url = window.origin;

  return (
    <>
      <EntrataWidget doNotIgnore={true} property={engrainProperty} />
      <div className={styles.sightmapContainer}>
        <h2 className={styles.sightmapTitle}>Search Available Apartments</h2>
        <iframe
          title={`interactive_map_${engrainId}`}
          id="sightmap"
          src={`https://sightmap.com/embed/${engrainId}?enable_api=1&origin=${url}`}
          frameBorder="0"
          width="100%"
          height="100%"
        />
      </div>
    </>
  );
};

export default InteractiveCommunitiesMap;
