import React, { useState } from 'react';
import get from 'lodash/get';
import { graphql, compose } from 'react-apollo';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import './carousel.css';
import { propertyGalleryQuery } from '../../graphql/queries';
import styles from './listitem.module.css';
import { formatDollar } from '../../helpers/utils';
import { withRouter } from 'react-router';
import PropertyFilter from '../../helpers/map-property-filter';
import usePhone from '../../helpers/hooks/usePhone';
import { addOrReplaceUrlParam, addQualityParam } from '../../helpers/add-or-replace-url-param';
import { canUseDOM } from '../../helpers/utils';
import Info from '../../assets/icons/help.png';
import ToolTip from '../ToolTip/index';

const moment = require('moment-timezone');

const settings = {
  showThumbs: false,
  showArrows: false,
  showIndicators: true,
  infiniteLoop: true,
  showStatus: false,
  centerSlidePercentage: 60
};

const getImages = props => {
  const data = get(props, 'data') || get(props, 'property');

  const images = [
    ...get(props, 'propertyGalleryQuery.propertyGallery.interior', [])
      .filter(img => !!img && !!img.image && !!img.image.url)
      .slice(0, 3),
    ...get(props, 'propertyGalleryQuery.propertyGallery.exterior', [])
      .filter(img => !!img && !!img.image && !!img.image.url)
      .slice(0, 3)
  ];
  const mainImage = get(data, 'data.primary_property_image');
  if (!!mainImage) {
    images.unshift({
      image: mainImage
    });
  }
  return images;
};

function PropertyItem(props) {
  const [selected, setSelected] = useState(0);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [dniPhone, hrefPhone] = usePhone(get(props, 'data.data.phone'), get(props, 'data.data.entrataPropertyId'), props);
  const { data } = props;
  if (!data) {
    return null;
  }
  const title = get(data, 'data.name');
  const address = get(data, 'data.address');
  const phone = get(data, 'data.phone');
  const pets = get(data, 'data.pets');
  const smoke_free = get(data, 'data.smoke_free');
  const leed_level = get(data, 'data.leed_level');
  const leed_level_target = get(data, 'data.leed_level_target');
  const attributes = get(props, 'attributes') || {};
  const specials =
    (get(data, 'data.specials') || []).filter(special =>
      moment
        .tz(special.expiration_date, 'MM/DD/YYYY', 'America/Chicago')
        .endOf('day')
        .isAfter(moment().tz('America/Chicago'))
    ).length > 0;
  const specialsList = (get(data, 'data.specials') || []).filter(special =>
    moment
      .tz(special.expiration_date, 'MM/DD/YYYY', 'America/Chicago')
      .endOf('day')
      .isAfter(moment().tz('America/Chicago'))
  );
  const cat = pets === 'Yes';
  const dog = pets === 'Yes';
  const smoking = smoke_free === 'Yes';
  const leed = !!leed_level && !leed_level_target;
  const gallery = getImages(props);

  const resizeImages = () => {
    if (canUseDOM) {
      gallery.map(image => {
        if (image.image && image.image.url) {
          const url = addOrReplaceUrlParam(image.image.url, 'w', window.innerWidth * 2);
          const new_url = addOrReplaceUrlParam(url, 'h', 'auto');
          image.image.url = new_url;
        }
        return image;
      });
    }
  };
  resizeImages(gallery);

  const property = {
    data,
    attributes
  };

  const isPropertyZeroPriced = !!property.attributes && (+property.attributes.minprice === 0 || +property.attributes.minPrice === 0);
  if (isPropertyZeroPriced) {
    if (props.filters.dirtySlider && !PropertyFilter(property, props.filters)) {
      return null;
    }
  } else if (!PropertyFilter(property, props.filters)) {
    return null;
  }

  const showBedrooms = get(props, 'data.data.enable_pricing_availability_page', '');
  const preLeaseDate = get(props, 'data.data.pre-lease_start_date');
  const moveInDate = get(props, 'data.data.move_in_date');

  const getFullRoute = () => {
    let path;
    const page = get(props, 'page');
    const subregionUid = get(props, 'data.data.sub-regions[0].sub-region.uid');
    if (!!page && subregionUid && page.type === 'region') {
      path = `${props.location.pathname}/${subregionUid}-apartments`;
      return path;
    } else if (!!page && page.type === 'sub-region') {
      path = `${props.location.pathname.replace(page.uid, subregionUid)}`;
      return path;
    }
    return null;
  };

  const handleButtonClick = () => {
    const fullUrl = window.location.origin + getFullRoute() + '/' + data.uid;

    window.location.href = fullUrl;
  };

  return (
    <div className={styles.container}>
      <div className={styles.sliderWrapper}>
        <img
          src="/images/icon-arrow--slide-left3x.png"
          alt=""
          className={`${styles.sliderController} ${styles.leftArrow}`}
          onClick={() => {
            if (gallery && gallery.length) {
              setSelected((selected + gallery.length - 1) % gallery.length);
            }
          }}
        />
        <img
          src="/images/icon-arrow--slide-right3x.png"
          alt=""
          className={`${styles.sliderController} ${styles.rightArrow}`}
          onClick={() => {
            if (gallery && gallery.length) {
              setSelected((selected + 1) % gallery.length);
            }
          }}
        />
        <Carousel {...settings} selectedItem={selected} onClickItem={() => props.history.push(`${getFullRoute()}/${property.data.uid}`)}>
          {gallery.map(image => (
            <div className={styles.slideContainer} key={image}>
              <img key={image} src={addQualityParam(image.image.url, 5)} alt="property" className={styles.image} />
            </div>
          ))}
        </Carousel>
      </div>
      <div className={styles.infoPanel}>
        {specials && (
          <ToolTip
            toolTipElement={
              <div className={styles.specialsBanner}>
                <span className={styles.specialsText}>LEASE SPECIAL</span>
              </div>
            }
            toolTipText={specialsList.map(special => (
              <div>
                <div className={styles.specialText}>{special.special_text}</div>
                <br />
                {/*<div className={styles.specialExpDate}>*/}
                {/*  Expires {moment(special.expiration_date).format('MM/DD/YY')}*/}
                {/*</div>*/}
              </div>
            ))}
            isMobile={true}
          />
        )}
        <div className={styles.infoTitleContainer}>
          <button className={styles.infoTitle} onClick={handleButtonClick}>
            {title}
          </button>
        </div>
        <div className={styles.infoBodyRow}>
          <div className={styles.infoContactsCol}>
            <div className={styles.infoAddressContainer}>
              <h3 className={styles.infoAddress}>{address}</h3>
            </div>
            <div className={styles.infoPhoneContainer}>
              {phone ? (
                <a href={hrefPhone}>
                  <h3 className={styles.infoPhone}>{dniPhone}</h3>
                </a>
              ) : null}
            </div>
          </div>
          <div className={styles.infoDetailsCol}>
            <div className={styles.perksContainer}>
              {cat && <img src="/images/icon-Cat.svg" alt="cat" className={styles.perkIcon} />}
              {dog && <img src="/images/icon-Dog.svg" alt="dog" className={styles.perkIcon} />}
              {leed && <img src="/images/icon-LEED-p-40.png" alt="leed-level" className={styles.perkIcon} />}
              {smoking && <img src="/images/icon-Smoking.png" alt="smoking" className={styles.perkIcon} />}
            </div>
            <div className={styles.bedsContainer}>
              {showBedrooms === 'Yes' ? (
                <h3 className={styles.beds}>{`${!attributes.minBeds ? 'Studio' : attributes.minBeds} - ${attributes.maxBeds || ''} Bedrooms`}</h3>
              ) : preLeaseDate ? (
                <div className={[styles.beds, styles.preLeaseDate].join(' ')}>
                  <p>Pre-Lease Start Date:</p>
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#787878',
                      paddingLeft: '5px'
                    }}
                  >
                    {preLeaseDate}
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className={styles.pricingContainer}>
              {showBedrooms === 'Yes' ? (
                <React.Fragment>
                  <ToolTip
                    toolTipElement={<img tabIndex={0} src={Info} alt="more info" className={styles.infoIcon} />}
                    toolTipText={
                      <div className={styles.infoText}>
                        "Starting at” reflects pricing for the lowest rent offered of an available unit at the Community regardless of the number of
                        bedrooms. Unavailable units may come online with lower rent, so check back often.
                      </div>
                    }
                    isMobile={true}
                  />
                  <button className={styles.pricingButton} onClick={() => props.history.push(`${getFullRoute()}/${data.uid}/floorplans`)}>
                    <h3 className={styles.pricing}>{`Starting at $${formatDollar(attributes.minPrice, 0)}`}</h3>
                    <img src="/images/right-arrow.svg" alt="" className={`af-class-property-card-cta-arrow ${styles.arrow}`} />
                  </button>
                </React.Fragment>
              ) : moveInDate ? (
                <div className={[styles.beds, styles.preLeaseDate].join(' ')}>
                  <p>Move In Start Date:</p>
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#787878',
                      paddingLeft: '5px'
                    }}
                  >
                    {moveInDate}
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SubregionItem(props) {
  const { data } = props;
  const name = get(data, 'data.name');
  const body = get(data, 'data.description');
  const imageLarge = get(data, 'data.primary_image.url');
  const image = addOrReplaceUrlParam(imageLarge, 'w', '800');
  return (
    <div className={styles.regionContainer}>
      <div className={styles.regionImageContainer}>
        {image ? <img src={addQualityParam(image, 5)} className={styles.regionImage} alt="region" /> : <div className={styles.regionImage} />}
        <div className={styles.regionLabelContainer}>
          <span className={styles.regionLabel}>Neighborhood</span>
          <span className={styles.regionName}>{name}</span>
        </div>
      </div>
      <div className={styles.regionInfoContainer}>
        <div className={styles.regionInfoBody}>{body}</div>
        <button className={styles.regionInfoButton} onClick={() => props.history.push(`${props.location.pathname}/${props.data.uid}-apartments`)}>
          <span className={styles.regionInfoButtonLabel}>{`Explore ${name}`}</span>
          <img src="/images/icon-arrow--slide-right3x.png" alt="" className={styles.regionButtonArrow} />
        </button>
      </div>
    </div>
  );
}

const PropertyItemAttributes = withRouter(
  compose(
    graphql(propertyGalleryQuery, {
      name: 'propertyGalleryQuery',
      skip: props => !props || !props.data || !props.data.id,
      options: props => ({
        variables: {
          id: props.data.id
        }
      })
    })
  )(PropertyItem)
);

const SubregionItemRouter = withRouter(SubregionItem);

export default function ListItem(props) {
  const { data, page } = props;
  return data.type === 'property' ? (
    <PropertyItemAttributes page={page} data={props.data} filters={props.filters} attributes={props.attributes} />
  ) : (
    <SubregionItemRouter page={page} data={props.data} />
  );
}
