import React from 'react';
import { get } from 'lodash';
import { graphql, compose } from 'react-apollo';
import { propertyAttributesQuery } from '../../graphql/queries';
import { formatDollar } from './../../helpers/utils';

import PropertyComparisonTable from '../PropertyComparisonTable';

import styles from './styles.module.css';

const PropertyComparisonOverview = compose(
  graphql(propertyAttributesQuery, {
    name: 'propertyAttributesQuery',
    skip: props => {
      return !props.property || !props.property.data || !props.property.data.amli_id || !props.property.data.review_push_id;
    },
    options: props => ({
      variables: {
        id: String(props.property.data.amli_id),
        review_push_id: String(props.property.data.review_push_id),
        reputationdotcom_id: String(props.property.data.reputationdotcom_id),
        reputationdotcom_reviews_enabled: Boolean(props.property.data.reputationdotcom_reviews_enabled)
      },
      fetchPolicy: 'network-only'
    })
  })
)(props => {
  const { link } = props;
  const name = get(props, 'property.data.name');
  const uid = get(props, 'property.uid');
  const average_unit_square_ft = get(props, 'property.data.average_unit_square_ft');
  const leed_level = get(props, 'property.data.leed_level');
  const leed_level_target = get(props, 'property.data.leed_level_target');
  const smoke_free = get(props, 'property.data.smoke_free');
  const building_type = get(props, 'property.data.building_type');
  const views = get(props, 'property.data.views');
  const propertyAttributes = get(props, 'propertyAttributesQuery.propertyAttributes');
  const minPrice = get(propertyAttributes, 'minPrice');
  const minBeds = get(propertyAttributes, 'minBeds', undefined);
  const maxBeds = get(propertyAttributes, 'maxBeds', undefined);
  

  const returnBedroomString = num => {
    switch (num) {
      case 0:
        return 'Studio';
      case 1:
        return '1 Bedrooms';
      case 2:
        return '2 Bedrooms';
      case 3:
        return '3 Bedrooms';
      default:
        return 'More';
    }
  };

  const bedrooms =
    minBeds !== undefined && maxBeds !== undefined
      ? `${returnBedroomString(propertyAttributes.minBeds)} - ${returnBedroomString(propertyAttributes.maxBeds)}`
      : null;

  return (
    <PropertyComparisonTable
      title={name ? name : uid ? uid.replaceAll('-', ' ').replaceAll('amli', 'AMLI') : 'AMLI Property'}
      link={link}
    >
      <li className={[styles.listItemSmall, props.mobileRight ? styles.mobileRight : null].join(' ')}>
        <p>
          {props.mobileRight ? null : <span className={styles.listItemLabel}>Starting Price: </span>}
          <span className={styles.bold}>{minPrice ? '$' + formatDollar(minPrice, 0) : ' - '}</span>
        </p>
      </li>
      <li className={[styles.listItemSmall, props.mobileRight ? styles.mobileRight : null].join(' ')}>
        <p>
          {props.mobileRight ? null : <span className={styles.listItemLabel}>Bedrooms: </span>}
          <span className={styles.bold}>{bedrooms ? bedrooms : ' - '}</span>
        </p>
      </li>
      <li className={[styles.listItemSmall, props.mobileRight ? styles.mobileRight : null].join(' ')}>
        <p>
          {props.mobileRight ? null : <span className={styles.listItemLabel}>Building Type: </span>}
          <span className={styles.bold}>{building_type ? building_type : ' - '}</span>
        </p>
      </li>
      <li className={[styles.listItemSmall, props.mobileRight ? styles.mobileRight : null].join(' ')}>
        <p>
          {props.mobileRight ? null : <span className={styles.listItemLabel}>Avg. Square Feet: </span>}
          <span className={styles.bold}>{average_unit_square_ft ? average_unit_square_ft : ' - '}</span>
        </p>
      </li>
      <li className={[styles.listItemSmall, props.mobileRight ? styles.mobileRight : null].join(' ')}>
        <p>
          {props.mobileRight ? null : <span className={styles.listItemLabel}>Views: </span>}
          <span className={styles.bold}>{views ? views : ' - '}</span>
        </p>
      </li>
      <li className={[styles.listItemSmall, props.mobileRight ? styles.mobileRight : null].join(' ')}>
        <p>
          {props.mobileRight ? null : <span className={styles.listItemLabel}>LEED Certification: </span>}
          {leed_level && leed_level_target && <span className={styles.bold}>Targeting </span>}
          <span className={styles.bold}>{leed_level ? 'LEED ' + leed_level : 'n/a'}</span>
        </p>
      </li>
      <li className={[styles.listItemSmall, props.mobileRight ? styles.mobileRight : null].join(' ')}>
        <p>
          {props.mobileRight ? null : <span className={styles.listItemLabel}>Smoke Free: </span>}
          <span className={styles.bold}>{smoke_free ? smoke_free : ' - '}</span>
        </p>
      </li>
    </PropertyComparisonTable>
  );
});

export default PropertyComparisonOverview;
