import React, { useRef, useState, useEffect } from 'react';
import get from 'lodash/get';
import RegionHeaderMapView from '../../components/RegionHeaderMapView';
import RegionResponsiveHeader from '../RegionResponsiveHeaderController/index';
import styles from './styles.module.css';

const defaultState = {
  mapCenter: null,
  zoom: 14,
  selectedPlace: null,
  showingInfoWindow: false,
  filter: {
    tab: 'properties',
    minvalue: 0,
    maxvalue: 5000,
    minhousing: 0,
    maxhousing: 3,
    places: null,
    dirtySlider: false,
  },
  selected: {
    subregion: null,
    property: null,
  },
  subregions: null,
  properties: null,
  places: null,
  width: 0,
  height: 0,
  responsive: {
    view: 'list',
    showFilter: false,
  },
};

export default (props) => {
  try {
    let cmsZoom = get(props, 'data.subregion_map_zoom_level');
    const mobileZoom = get(props, 'data.mobile_subregion_map_zoom_level');
    if (window.innerWidth < 721 && mobileZoom) {
      cmsZoom = mobileZoom;
    }
    if (cmsZoom) {
      defaultState.zoom = cmsZoom;
    } else {
      defaultState.zoom = 14;
    }
  } catch (ex) {}
  const [state, setState] = useState(defaultState);
  const containerRef = useRef(null);

  const { selectedProperties, mobileSelectedProperty, setMobileSelectedProperty } = props;

  useEffect(() => {
    if (!state || !state.mapCenter) {
      const lat = get(props, 'data.latitude');
      const lng = get(props, 'data.longitude');
      const mobileLat = get(props, 'data.mobile_latitude');
      const mobileLng = get(props, 'data.mobile_longitude');
      const onMobile = window.innerWidth < 721;

      setState({ 
        ...state, 
        mapCenter: { 
          lat: onMobile && mobileLat && mobileLng ? mobileLat : lat, 
          lng: onMobile && mobileLat && mobileLng ? mobileLng : lng, 
        } 
      });
    }
  });

  useEffect(() => {
    const newState = {
      ...state,
      properties: selectedProperties || [],
    };

    setState({
      ...newState,
      selected: {
        property: selectedProperties[mobileSelectedProperty].id,
      },
    });
  }, [selectedProperties]);

  useEffect(() => {
    if (
      !state.properties ||
      state.properties.length < 1 ||
      state.properties.length === selectedProperties.length
    ) {
      return;
    }
    setMobileSelectedProperty(0);
  }, [state.properties]);

  useEffect(() => {
    if (state.selected.property) {
      const stateSelectedIndex = selectedProperties.findIndex(property => {
        return property.id === state.selected.property
      })
      setMobileSelectedProperty(stateSelectedIndex);
    }
  }, [state.selected])

  const updateState = (newState) =>
    setState({
      ...state,
      ...newState,
    });

  return (
    <div ref={containerRef}>
      <span className={styles.largeMap}>
        <RegionHeaderMapView {...props} mapData={state} updateState={updateState} subregion />
      </span>
      <span className={styles.mobileMap}>
        <RegionResponsiveHeader {...props} mapData={state} updateState={updateState} subregion />
      </span>
    </div>
  );
};
