import React from 'react';
import get from "lodash/get";
import { formatDollar } from "../../helpers/utils";
import styles from './styles.module.css';

function renderOption(data, text){
  const { description, amount } = data;
  return (
    <div className={styles.optionWrapper}>
      <div className={styles.name}>{text || description}</div>
      <div className={styles.fee}>${formatDollar(amount, 2)}/mo.</div>
      <div className={styles.background}></div>
    </div>
  )
}
function getTotal(data) {
  let total = 0;
  data.map(item => total +=item.amount);
  return total;
}
function LeaseAddonsPrint(props) {
  const dueImmediately = get(props, "quote.paymentSummary.dueImmediately", []);
  const dueOnApproval = get(props, "quote.paymentSummary.dueOnApproval", []);
  const dueAtMoveIn = get(props, "quote.paymentSummary.dueAtMoveIn", []);

  return (
    <div className={styles.addonsContainer}>
      {dueImmediately && dueImmediately.length > 0 && dueImmediately.map(cost => renderOption(cost))}
      <div className={styles.sectionTitle}>
        <span>Due Immediately</span>
        <span>${formatDollar(getTotal(dueImmediately), 0)}</span>
      </div>
      {dueOnApproval && dueOnApproval.length > 0 && dueOnApproval.map(cost => renderOption(cost))}
      <div className={styles.sectionTitle}>
        <span>Due At Move In</span>
        <span>${formatDollar(getTotal(dueOnApproval), 0)}</span>
      </div>
      {dueAtMoveIn && dueAtMoveIn.length > 0 && dueAtMoveIn.map(cost => renderOption(cost))}
      <div className={styles.sectionTitle}>
        <span>Due At Move In</span>
        <span>${formatDollar(getTotal(dueAtMoveIn), 0)}</span>
      </div>
    </div>
  );
}

export default LeaseAddonsPrint;
