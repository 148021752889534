import React from 'react';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { propertyAttributesQuery } from '../../graphql/queries/propertyAttributes';
import { propertyFloorplansSummary } from '../../graphql/queries/propertyFloorplansSummary';
import FloorplansConfigurationFilterView from '../../components/FloorplansConfigurationFilterView';
import FloorplansCardContainerView from '../../components/FloorplansCardContainerView';
import './index.css';
const moment = require('moment');

function FloorplansFiltersAndCardContainer(props) {
  const {
    furnishedAvailable,
    property,
    filters,
    setFilters,
    showEngrain,
    engrainId,
    pageWidth,
    attributesQuery = {},
    propertyFloorplansSummary = {}
  } = props;

  return (
    <span className="custom-filter-bar-wrapper">
      <FloorplansConfigurationFilterView.Controller
        furnishedAvailable={furnishedAvailable}
        property={property}
        filters={filters}
        setFilters={setFilters}
        showEngrain={showEngrain}
        engrainId={engrainId}
        attributesQuery={attributesQuery}
        propertyFloorplansSummary={propertyFloorplansSummary}
      />
      <FloorplansCardContainerView.Controller
        property={property}
        filters={filters}
        setFilters={setFilters}
        pageWidth={pageWidth}
        attributesQuery={attributesQuery}
        propertyFloorplansSummary={propertyFloorplansSummary}
      />
    </span>
  );
}

export default withRouter(
  compose(
    graphql(propertyFloorplansSummary, {
      name: 'propertyFloorplansSummary',
      skip: props => !props.property || !props.property.data || !props.property.data.amli_id,
      options: props => ({
        variables: {
          amliPropertyId: props.property.data.amli_id,
          propertyId: props.property.id,
          moveInDate: moment(props.filters.date).format('YYYY-MM-DD')
        }
      })
    }),
    graphql(propertyAttributesQuery, {
      name: 'attributesQuery',
      options: props => ({
        variables: {
          id: String(props.property.data.amli_id),
          review_push_id: String(props.property.data.review_push_id),
          reputationdotcom_id: String(props.property.data.reputationdotcom_id),
          reputationdotcom_reviews_enabled: Boolean(props.property.data.reputationdotcom_reviews_enabled)
        }
      })
    })
  )(FloorplansFiltersAndCardContainer)
);
