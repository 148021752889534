import React from "react";
import ReactModal from "react-modal";
import styles from './styles.module.css';


function SelfTourModal(props) {

  return (
    <ReactModal
      isOpen={props.open}
      contentLabel="Self-Guided Tours"
      onRequestClose={props.onRequestClose}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalTitle}>{props.title}</div>
      <div className={styles.modalBody}>
        <button className={styles.modalClose} onClick={props.onRequestClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="white"
          >
            <path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z" />
          </svg>
        </button>
        <div className={styles.modalGallery}>
          <iframe
            title="Self-Guided Tour"
            id="iframe-self-guided-tour"
            style={{
              width: "100%",
              height: "100%",
              margin: 0,
              minHeight: 500
            }}
            src={
              props.tourProvider === 'smartrent' ? `https://selftournow.com/property/${props.smartRentId}` : `https://app.tour24now.com/tour/${props.propertyUid.replace(/\-/g, '')}`}
            frameBorder="0"
            allowFullScreen="true"
          />
        </div>
      </div>
    </ReactModal>
  );
}

export default SelfTourModal;
