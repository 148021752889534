import React from "react";
import get from "lodash/get";
import { withRouter } from "react-router-dom";
import styles from "./top-alert-banner.module.css";
import { isMobile } from "react-device-detect";
import { canUseDOM } from "./utils";

class TopAlertBanner extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hideAlert: false,
    };
    this.setHideAlert = this.setHideAlert.bind(this);
  }
  componentDidMount() {
    if (canUseDOM) {
      document.addEventListener("openedEntrataWidget", () => {
        if (isMobile) {
          this.setHideAlert(true);
        }
      });
      document.addEventListener("closedEntrataWidget", () => {
        if (isMobile) {
          this.setHideAlert(false);
        }
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("openedEntrataWidget", () => {
      if (isMobile) {
        this.setHideAlert(true);
      }
    });
    document.removeEventListener("closedEntrataWidget", () => {
      if (isMobile) {
        this.setHideAlert(false);
      }
    });
  }

  setHideAlert = (hideAlert) => {
    this.setState({ hideAlert });
  };
  render() {
    const { data } = this.props;
    if (!data) {
      return null;
    }
    const bannerText = get(data, "data.banner_text", "");
    const buttonText = get(data, "data.button_text", "");
    const bannerUrl = get(data, "data.banner_document.url", "");
    return (
      <div className={styles.bannerWrapper}>
        <p className={styles.textWrapper}>{bannerText}&nbsp;</p>
        <p className={styles.buttonWrapper}>
          <a
            href={bannerUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.buttonText}
            aria-label={buttonText}
          >
            {buttonText}
            <svg
              className={styles.buttonArrow}
              height="13"
              viewBox="0 0 8 13"
              width="8"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m190 5.5 1.25-1.25 6.25 6.25-6.25 6.25-1.25-1.25 4.960938-5z"
                fill="white"
                fill-rule="evenodd"
                transform="translate(-190 -4)"
              />
            </svg>
          </a>
        </p>
      </div>
    );
  }
}
export default withRouter(TopAlertBanner);
