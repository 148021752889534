import React, { useEffect } from "react";
import { withRouter } from "react-router";
import get from "lodash/get";
import PropertyPhaseOneTabView from "../../components/PropertyPhaseOneTabView";
import GetPropertyFullRoute from "../../helpers/get-full-property-route";

import styles from './styles.module.css';

import "./index.css";

function PropertyPhaseOneTab(props) {

  let disableButton = props.disabled;
  if (props.value === "/amenities" && props.data.amenities === null) {
    disableButton = true;
  }
  if (props.value === "/floorplans" && props.property.data.enable_pricing_availability_page === "Yes"){
    disableButton = false;
  }

  // Hook into the component loading
  useEffect(() => {
    // Get our current path
    let path = props.location.pathname;
    // Get our current route
    let route = path.substring(path.lastIndexOf('/') + 1);
    
    // Check if this property has an amenities or location page yet
    if (props.appdata) {
      if (props.appdata.amenities) {
        if (props.appdata.amenities.length === 0 && route === 'amenities') {
          // If it doesn't have an amenities page, redirect to the overview page
          props.history.push(path.substring(0, path.lastIndexOf('/')));
        }
      }
      if (props.appdata.location) {
        if (props.appdata.location.length === 0 && route === 'location') {
          // If it doesn't have a location page, redirect to the overview page
          props.history.push(path.substring(0, path.lastIndexOf('/')));
        }
      }
    }



  });

  const fullRoute = GetPropertyFullRoute(props.property, props.appdata);
  const propertyRoute = get(props, "property.uid");

  return (
    <PropertyPhaseOneTabView>
      <wrapper
        className={[
          props.selected ? "" : styles.notselected,
          disableButton ? styles.disabled : ""
        ].join(" ")}
        onClick={() => {
          if (disableButton || !props.value) {
            return;
          }
          if (props.value === "/"){
            props.history.push(`${fullRoute}/${propertyRoute}`);
          } else {
            props.history.push(`${fullRoute}/${propertyRoute}${props.value}`);
          }
        }}
      >
        <title className={styles.tab}>
          {props.title}
          { disableButton ? <div className={styles.comingSoon}>Page Coming Soon</div> : null }
        </title>
      </wrapper>
    </PropertyPhaseOneTabView>
  );
}

export default withRouter(PropertyPhaseOneTab);
