import React from 'react';
import styles from './styles.module.css';

export default props => {
  if (!props.data) {
    return null;
  }

  const {
    data: { primary }
  } = props;

  const { onetrust_text_body: body, onetrust_text_link: link } = primary;
  const span = `<span id="ot-sdk-btn" class="${`ot-sdk-show-settings ${styles.otsdkshowsettings}`}">${link}</span>`;
  const __html = body.replace(link, span);

  return (
    <div className={styles.paddingWrapper}>
      <p className={styles.wrapper}>
        <span onClick={() => window.OneTrust.ToggleInfoDisplay()} dangerouslySetInnerHTML={{ __html }} />
      </p>
    </div>
  );
};
