import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import { graphql, compose } from 'react-apollo';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { propertyAttributesQuery, propertyGalleryQuery } from '../../graphql/queries';
import styles from './styles.module.css';
import { withRouter } from 'react-router';
import { addQualityParam } from '../../helpers/add-or-replace-url-param';

const moment = require('moment-timezone');

const settings = {
  showThumbs: false,
  showArrows: false,
  showIndicators: true,
  infiniteLoop: true,
  showStatus: false,
  centerSlidePercentage: 60
};

const getImages = props => {
  const data = get(props, 'data.data') || get(props, 'data.property') || get(props, 'data') || get(props, 'property');
  const gallery = get(props, 'propertyGalleryQuery.propertyGallery');
  const images = [
    ...get(gallery, 'interior', [])
      .filter(img => !!img && !!img.image && !!img.image.url)
      .slice(0, 3),
    ...get(gallery, 'exterior', [])
      .filter(img => !!img && !!img.image && !!img.image.url)
      .slice(0, 3)
  ];
  const mainImage = get(data, 'primary_property_image');
  if (!!mainImage) {
    images.unshift({
      image: mainImage
    });
  }
  return images;
};

function PropertyItem(props) {
  const [selected, setSelected] = useState(0);
  const { data } = props;
  if (!data) {
    return null;
  }
  const title = get(data, 'data.name');
  const primaryColor = get(data, 'data.primary_color');

  const address = get(data, 'data.address');
  const phone = get(data, 'data.phone');
  const pets = get(data, 'data.pets');
  const smoke_free = get(data, 'data.smoke_free');
  const leed_level = get(data, 'data.leed_level');
  const leed_level_target = get(data, 'data.leed_level_target');
  const specials = (get(data, 'data.specials') || []).filter(special =>
    moment
      .tz(special.expiration_date, 'MM/DD/YYYY', 'America/Chicago')
      .endOf('day')
      .isAfter(moment().tz('America/Chicago'))
  );
  const cat = pets === 'Yes';
  const dog = pets === 'Yes';
  const smoking = smoke_free === 'Yes';
  const leed = !!leed_level && !leed_level_target;
  const gallery = getImages(props);

  const getFullRoute = () => {
    let path = `/${data.uid}`;

    return path;
  };

  const formatAddress = address => {
    const splitAddress = address.split('\n');
    return (
      <>
        {splitAddress[0]}
        <br style={{ display: 'block' }} />
        {splitAddress[1]}
      </>
    );
  };

  const [modalDisplay, setModalDisplay] = useState('none');
  const wrapperRef = useRef();

  const handleClickOutside = e => {
    if (modalDisplay === 'block' && wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setModalDisplay('none');
    }
  };

  useEffect(() => {
    document.addEventListener('pointerdown', handleClickOutside);
    return () => document.removeEventListener('pointerdown', handleClickOutside);
  }, [modalDisplay]);

  return (
    <React.Fragment>
      <div className={styles.wrapper}>
        <div className={styles.modalContainer}>
          <div className={styles.sliderWrapper}>
            <img
              src="/images/icon-arrow--slide-left3x.png"
              alt="arrow-previous"
              className={`${styles.sliderController} ${styles.leftArrow}`}
              onClick={() => {
                if (gallery && gallery.length) {
                  setSelected((selected + gallery.length - 1) % gallery.length);
                }
              }}
            />
            <img
              src="/images/icon-arrow--slide-right3x.png"
              alt="arrow-next"
              className={`${styles.sliderController} ${styles.rightArrow}`}
              onClick={() => {
                if (gallery && gallery.length) {
                  setSelected((selected + 1) % gallery.length);
                }
              }}
            />
            <Carousel {...settings} selectedItem={selected}>
              {gallery.map(image => (
                <div className={styles.slideContainer} key={image}>
                  <img key={image} src={addQualityParam(image.image.url, 5)} alt="property" className={styles.image} />
                </div>
              ))}
            </Carousel>
          </div>

          <div className={styles.infoPanel}>
            {specials.length > 0 && (
              <div className={styles.specialsBanner} ref={wrapperRef} onClick={() => setModalDisplay('block')}>
                <div className={styles.specialModal} style={{ display: modalDisplay }}>
                  <div className={styles.specialModalClose} onMouseDown={() => setModalDisplay('none')}>
                    <img alt="close" src="/static/media/close_with_Background.61d01cf8.svg" tabindex="0" />
                  </div>
                  <p className={styles.specialText}>{specials[0].special_text}</p>
                  {/*<p className={styles.specialExpiration}>{`Expires: ${specials[0].expiration_date}`}</p>*/}
                </div>
                <span className={styles.specialsText}>LEASE SPECIAL</span>
              </div>
            )}
            <div className={styles.infoTitleContainer}>
              <div className={styles.infoTitle}>{title}</div>
            </div>
            <div className={styles.infoBodyRow}>
              <div className={styles.infoContactsCol}>
                <div className={styles.infoAddressContainer}>
                  <h3 className={styles.infoAddress}>{formatAddress(address)}</h3>
                </div>
                <div className={styles.infoPhoneContainer}>
                  <a href={phone ? `tel:+${phone.replace(/[)(-]/g, '')}` : null}>
                    <h3 className={styles.infoPhone}>{phone}</h3>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.perksContainer}>
          {cat && <img src="/images/icon-Cat.svg" alt="cat" className={styles.perkIcon} />}
          {dog && <img src="/images/icon-Dog.svg" alt="dog" className={styles.perkIcon} />}
          {leed && <img src="/images/icon-LEED-p-40.png" alt="leed-level" className={styles.perkIcon} />}
          {smoking && <img src="/images/icon-Smoking.png" alt="smoking" className={styles.perkIcon} />}
        </div>

        <div className={styles.linkWrapper} style={{ backgroundColor: primaryColor ? primaryColor : 'rgba(68, 119, 193, 1)' }}>
          <a
            href={`${getFullRoute()}`}
            tabIndex={0}
            className={styles.viewProperty}
            onClick={e => {
              if (e && e.preventDefault) {
                e.preventDefault();
              }
              props.history.push(`${getFullRoute()}`);
            }}
            onKeyDown={e => {
              if (e && e.preventDefault) {
                e.preventDefault();
              }
              props.history.push(`${getFullRoute()}`);
            }}
          >
            View Property
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

const PropertyItemAttributes = withRouter(
  compose(
    graphql(propertyAttributesQuery, {
      name: 'attributesQuery',
      options: props => ({
        variables: {
          id: String(props.data.data.amli_id),
          review_push_id: String(props.data.data.review_push_id),
          reputationdotcom_id: String(props.data.data.reputationdotcom_id),
          reputationdotcom_reviews_enabled: Boolean(props.data.data.reputationdotcom_reviews_enabled)
        }
      })
    }),
    graphql(propertyGalleryQuery, {
      name: 'propertyGalleryQuery',
      skip: props => !props || !props.data || !props.data.id,
      options: props => ({
        variables: {
          id: props.data.id
        }
      })
    })
  )(PropertyItem)
);

export default function PropertyComparisonMobilePropertyCard(props) {
  const { data } = props;
  return <PropertyItemAttributes {...{ data }} />;
}
