import { addQualityParam } from '../add-or-replace-url-param';
const BackgroundImageHandler = (backgroundImage, includeParam = false) =>
  backgroundImage && backgroundImage.url
    ? {
        style: {
          backgroundImage: `url(${backgroundImage.url}${
            includeParam ? `${addQualityParam(backgroundImage.url, 5)}` : ''
          })`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        },
      }
    : {};

export default BackgroundImageHandler;
