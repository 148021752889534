import gql from "graphql-tag";

export const idleTimeoutQuery = gql`
  query idleTimeoutQuery {
    idleTimeout
  }
`



