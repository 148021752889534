import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { graphql, compose } from 'react-apollo';
import RegionHeaderSliderView from '../../components/RegionHeaderSliderView';
import PropertyCardView from '../../components/PropertyCardView';
import NeighborhoodCardView from '../../components/NeighborhoodCardView';
import PropertyFilter from '../../helpers/map-property-filter';
import { regionPropertyAttributesQuery } from '../../graphql/queries/regionPropertyAttributes';
import Spinner from '../Spinner';

const RegionHeaderSlider = (props) => {
  const {
    filter: { tab, dirtySlider },
    subregions,
    properties,
    selected: { property, subregion },
  } = props.mapData;

  const [isStateSet, setIsStateSet] = useState(false);
  const attributes = get(
    props,
    'propertiesAttributesQuery.regionPropertyAttributes'
  );
  useEffect(() => {
    const atrb = get(
      props,
      'propertiesAttributesQuery.regionPropertyAttributes'
    );
    if (!!atrb && properties && properties.length && !isStateSet) {
      setIsStateSet(true);
      props.updateState({
        filter: {
          ...props.mapData.filter,
          minvalue: Math.min(...atrb.map((attr) => attr.minPrice)),
          maxvalue: Math.max(...atrb.map((attr) => attr.minPrice)),
          dirtySlider: false,
        },
        properties: (props.mapData.properties || []).map((prt) => ({
          ...prt,
          attributes: atrb.find(
            (attr) => `${attr.propertyId}` === `${prt.data.amli_id}`
          ),
        })),
      });
    }
    return () => setIsStateSet(false);
  }, [props.propertiesAttributesQuery.regionPropertyAttributes, properties]);

  const propertySort = (a, b) => {
    if (a.id === property) {
      return -1;
    } else if (b.id === property) {
      return 1;
    }

    if (
      a.attributes &&
      b.attributes &&
      a.attributes.displayOrder !== null &&
      a.attributes.displayOrder !== undefined &&
      b.attributes.displayOrder !== null &&
      b.attributes.displayOrder !== undefined
    ) {
      return a.attributes.displayOrder - b.attributes.displayOrder;
    }

    const currentlyLeasingA = get(a, 'data.currently_leasing', 'No') === 'Yes';
    const currentlyLeasingB = get(b, 'data.currently_leasing', 'No') === 'Yes';

    if (currentlyLeasingA !== currentlyLeasingB) {
      return currentlyLeasingA && !currentlyLeasingB ? -1 : 1;
    }

    const availableUnitsPercentA = get(a, 'attributes.availablePercent', 0);
    const availableUnitsPercentB = get(a, 'attributes.availablePercent', 0);

    if (availableUnitsPercentA !== availableUnitsPercentB) {
      return availableUnitsPercentA - availableUnitsPercentB > 0 ? -1 : 1;
    }

    const aTag = get(a, 'attributes.propertyTag', '');
    const bTag = get(a, 'attributes.propertyTag', '');

    return aTag < bTag ? -1 : 1;
  };

  const subregionSort = (a, b) => {
    if (a.id === subregion) {
      return -1;
    } else if (b.id === subregion) {
      return 1;
    }
    return a.data.name < b.data.name ? -1 : 1;
  };

  const filterOutZeroPriced = (item) =>
    !item.attributes || +item.attributes.minPrice > 0;

  return (
    <RegionHeaderSliderView {...props}>
      <propertyCardViewList1>
        {!attributes || !attributes.length ? (
          <div>
            <Spinner />
          </div>
        ) : null}
        {tab === 'properties'
          ? (properties || [])
              .filter(filterOutZeroPriced)
              .sort(propertySort)
              .map((item) => {
                if (!PropertyFilter(item, props.mapData.filter)) {
                  return null;
                } else if (
                  // Custom filter to check if the property has studio units
                  props.mapData.filter.minhousing === 0 &&
                  props.mapData.filter.maxhousing === 0 &&
                  item.attributes.minBeds !== 0
                ) {
                  return null;
                } else {
                  return (
                    <PropertyCardView.Controller
                      key={`property-full-${item.uid}`}
                      data={item}
                      page={props.page}
                      mapData={props.mapData}
                      updateState={props.updateState}
                    />
                  );
                }
              })
          : null}
        {tab === 'properties'
          ? (properties || [])
              .filter((it) => !filterOutZeroPriced(it))
              .sort(propertySort)
              .map((item) => {
                if (
                  dirtySlider &&
                  !PropertyFilter(item, props.mapData.filter)
                ) {
                  return null;
                } else {
                  return (
                    <PropertyCardView.Controller
                      key={`property-dev-${item.uid}`}
                      data={item}
                      page={props.page}
                      mapData={props.mapData}
                      updateState={props.updateState}
                    />
                  );
                }
              })
          : null}
        {tab === 'neighborhoods'
          ? (subregions || [])
              .filter((sr) => sr.data.ghost_region === 'No')
              .sort(subregionSort)
              .map((item) => (
                <NeighborhoodCardView.Controller
                  key={`subregion-full-${item.uid}`}
                  data={item}
                  mapData={props.mapData}
                  updateState={props.updateState}
                />
              ))
          : null}
      </propertyCardViewList1>
    </RegionHeaderSliderView>
  );
};

const RegionHeaderSliderAttritubes = compose(
  graphql(regionPropertyAttributesQuery, {
    name: 'propertiesAttributesQuery',
    skip: (props) => {
      let id = props.data.amli_id;
      if (props.subregion) {
        const region = get(props, 'appdata.regions', []).find(
          (region) => region.id === get(props, 'data.region.id')
        );
        if (region && region.data && region.data.amli_id) {
          id = region.data.amli_id;
        }
      }
      if (!id || id === null || id === 'null' || id === undefined) {
        return true;
      }
    },
    options: (props) => {
      let id = props.data.amli_id;
      if (props.subregion) {
        const region = get(props, 'appdata.regions', []).find(
          (region) => region.id === get(props, 'data.region.id')
        );
        if (region && region.data && region.data.amli_id) {
          id = region.data.amli_id;
        }
      }

      return {
        variables: {
          ...(id ? { id: String(id) } : {}),
        },
      };
    },
  })
)(RegionHeaderSlider);

export default RegionHeaderSliderAttritubes;
