import React from 'react';
import get from 'lodash/get';
import throttle from 'lodash';
import { withRouter } from 'react-router';
import { graphql } from 'react-apollo';
import moment from 'moment-timezone';
import { propertyAttributesQuery } from '../../graphql/queries/propertyAttributes';
import PropertySubPageHeaderView from '../../components/PropertySubPageHeaderView';
import MultiLineString from '../../helpers/mutli-line-string';
import { addOrReplaceUrlParam } from '../../helpers/add-or-replace-url-param';
import defaultImage from '../../assets/images/default-property-subpage-header.jpg';
import Helmet from 'react-helmet-async';
import queryString from 'query-string';

/* eslint-disable import/first */
import stylesMod from './styles.module.css';
const styles = stylesMod.locals || stylesMod;
import './index.css';
import RemoveBetterbot from '../../helpers/remove-betterbot-scripts';
import { formatDollar } from '../../helpers/utils';
import StarRatings from 'react-star-ratings';
import getDniPhone from '../../helpers/get-dni-phone';
import CapitalizeFirsts from '../../helpers/capitalize-first-letter';
import BackgroundImageHandler from '../../helpers/components-data-handlers/background-image-handler';
import { composeApplyLink } from '../../helpers/property/apply';
import { handlePrismicLink } from '../../helpers/link-helper';
import { setItem, getItem, getItemString } from '../../helpers/cookies';
import { personaBackgroundColorStyle } from '../../helpers/property/persona';
import { getRelatedAmenitiesPage, getRelatedLocationPage, getRelatedFurnishedPage } from '../../helpers/get-related-property-pages';

import Info from '../../assets/icons/help.png';
import ToolTip from '../ToolTip/index';
import { canUseDOM } from '../../helpers/utils';
import CloseWithBackground from '../../assets/icons/close_with_Background.svg';
import SelfTourModal from '../SelfTourModalController';
import { LazyLoaderElement, LazyLoader } from '../../helpers/lazy-loader';

const _renderConfiguration = attributes => {
  if (!attributes.minBeds && !attributes.maxBeds) {
    return null;
  }
  if (attributes.minBeds === attributes.maxBeds) {
    return attributes.minBeds === 0 ? 'Studio' : `${attributes.minBeds} Bedrooms`;
  } else {
    return `${attributes.minBeds === 0 ? 'Studios' : `${attributes.minBeds}`} - ${attributes.maxBeds} Bedrooms`;
  }
};

const defaultHeader = {
  url: defaultImage
};

const getHeaderImage = props => {
  let image = null;
  switch (props.route) {
    case 'floorplans':
      image = get(props, 'property.data.floorplan_header');
      break;
    case 'amenities':
      const amenities = getRelatedAmenitiesPage(props.property.id, props.appdata);
      image = get(props, 'data.amenities.data.header_image') || get(amenities, 'data.header_image');

      break;

    case 'location':
      const location = getRelatedLocationPage(props.property.id, props.appdata);
      image = get(props, 'data.location.data.header_image') || get(location, 'data.header_image');

      break;
    case 'furnished':
      const furnished = getRelatedFurnishedPage(props.property.id, props.appdata);
      image = get(props, 'data.furnished.data.header_image') || get(furnished, 'data.header_image');

      break;
    case 'contact':
      image = get(props, 'property.data.contact_header');
      break;
    default:
      break;
  }
  if (!image || !image.url) {
    return defaultHeader;
  } else {
    return image;
  }
};

let rootElem = null;
if (canUseDOM) {
  rootElem = document.getElementById('root');
}

const titles = {
  floorplans: 'Floor Plans & Pricing',
  amenities: 'Amenities & Features',
  location: 'Location',
  furnished: 'Corporate Furnished',
  contact: 'Contact Us'
};

class PropertySubPageHeader extends React.Component {
  _isMounted = false;
  intervalChecked = null;

  lazyLoaderElementList = [];
  lazyLoaded = false;

  constructor(props) {
    super(props);
    if (canUseDOM) {
      RemoveBetterbot();
    }
    this.state = {
      phone: get(props, 'property.data.phone'),
      showDisclaimer: false,
      isMobileSize: false,
      showMobileSpecialsDisclaimer: false,
      showMobilePriceDisclaimer: false,
      showCustomCloseButton: false,
      showBetterbotPixel: get(props, 'property.data.show_betterbot_pixel'),
      showSelfTourModal: false
    };
  }

  componentDidMount() {
    this.lazyLoaderElementList = [];

    this._isMounted = true;
    this.script = document.createElement('script');
    this.script.type = 'text/javascript';
    this.script.async = true;
    this.loadedScripts = false;
    //this.mountScript();
    let switchCode;
    let referralSite;
    const { switch_code } = queryString.parse(this.props.location.search);
    if (switch_code) {
      setItem('switch_code', switch_code);
    }
    referralSite = getItemString('referralSite');
    switchCode = getItem('switch_code');
    if (!switchCode && !referralSite && typeof document !== 'undefined') {
      referralSite = document.referrer;
    }
    if (!!switchCode || !!referralSite) {
      getDniPhone(get(this.props, 'property.data.entrataPropertyId'), switchCode, referralSite).then(phone => {
        if (this._isMounted) {
          if (phone) {
            this.setState({ phone });
          }
        }
      });
    }
    this.throttledMeasure = throttle(this.measure, 16);
    if (!this.sizeListener && !!window) {
      this.sizeListener = window.addEventListener('resize', this.throttledMeasure);
    }
    this.measure(true);
    if (canUseDOM) {
      window.dataLayer.push({
        event: 'propertyHeaderDidMount'
      });
    }
    this.intervalChecked = setInterval(() => {
      this.checkForEntrataContainer();
    }, 1000);

    //#region SETUP lazy loading

    if (this.lazyLoaderElementList.length > 0) {
      const lazyLoader = new LazyLoader();
      ///lazyLoader.LazyLoad(this.lazyLoaderElementList);

      this.lazyLoaded = true;
    }

    //#endregion
  }
  componentDidUpdate() {
    //#region SETUP lazy loading

    if (this.lazyLoaderElementList.length > 0 && !this.lazyLoaded) {
      const lazyLoader = new LazyLoader();
      //lazyLoader.LazyLoad(this.lazyLoaderElementList);
    }

    //#endregion
  }
  measure = () => {
    const { width } = document.body.getBoundingClientRect();
    this.setState({ isMobileSize: width < 480 });
  };

  componentWillUnmount() {
    this.lazyLoaded = false;
    this.lazyLoaderElementList = [];

    if (this.intervalChecked) {
      clearInterval(this.intervalChecked);
    }
    //this._isMounted = false;
    //this.unMountScript();
    if (canUseDOM) {
      RemoveBetterbot();
    }
    window.removeEventListener('resize');
    this.sizeListener = undefined;
  }
  loadScript = src => {
    const tag = document.createElement('script');
    tag.async = false;
    tag.src = src;
    document.getElementsByTagName('head')[0].appendChild(tag);
  };

  checkForEntrataContainer() {
    if (canUseDOM) {
      const container = document.getElementById('ppcb_container');
      const height = get(container, 'style.height', '');
      if (container && height !== '0px') {
        this.setState({ showCustomCloseButton: true });
        clearInterval(this.intervalChecked);
      }
    }
  }

  //mountScript = () => {
  //    const entrataDomain = get(this.props, "property.data.entrata_domain");
  //    if (
  //        !!this.instance &&
  //        !!this.script &&
  //        !this.script.innerHTML.length &&
  //        !this.loadedScripts &&
  //        !!entrataDomain
  //    ) {
  //        this.script.innerHTML = composeEntrataScript(entrataDomain);
  //        this.instance.appendChild(this.script);
  //        this.loadedScripts = true;
  //    }
  //};

  //unMountScript = () => {
  //    if (!this.script || !this.script.innerHTML) {
  //        return;
  //    }
  //    if (
  //        this.script &&
  //        !!this.script.innerHTML &&
  //        !!this.instance &&
  //        !!this.instance.children.length
  //    ) {
  //        this.instance.removeChild(this.script);
  //        const elem = document.getElementById("ppcb_btn");
  //        const elem2 = document.getElementById("ppcb_container");
  //        const elem3 = document.getElementById("ppcb_notification");
  //        if (!!elem) {
  //            elem.remove();
  //        }
  //        if (!!elem2) {
  //            elem2.remove();
  //        }
  //        if (!!elem3) {
  //            elem3.remove();
  //        }
  //    }
  //}
  openWidget = () => {
    if (!!window && window.ppcb) {
      window.ppcb.open('schedule-tour');
    }
  };

  render() {
    const customPhoneNumber = get(this.props, 'property.data.custom_phone_number');
    const customPhoneNumberText = get(this.props, 'property.data.custom_phone_number_text');

    const route = get(this.props, 'route');
    const headerTitle = titles[route] || CapitalizeFirsts(route);
    const name = get(this.props, 'page.name');
    const address = MultiLineString(get(this.props, 'page.address'));
    const specials =
      (get(this.props, 'page.specials') || []).filter(special =>
        moment
          .tz(special.expiration_date, 'MM/DD/YYYY', 'America/Chicago')
          .endOf('day')
          .isAfter(moment().tz('America/Chicago'))
      ).length > 0;
    const specialsList = (get(this.props, 'page.specials') || []).filter(special =>
      moment
        .tz(special.expiration_date, 'MM/DD/YYYY', 'America/Chicago')
        .endOf('day')
        .isAfter(moment().tz('America/Chicago'))
    );
    const attributes = get(this.props, 'attributesQuery.propertyAttributes') || {};

    const rating = get(this.props, 'attributesQuery.propertyAttributes.reviews.rating');
    const reviews = get(this.props, 'attributesQuery.propertyAttributes.reviews.reviews');
    const hideEntrataWidget = get(this.props, 'property.data.hide_entrata_widget');

    const showSpecialToolTip = () => {
      this.setState({ showMobileSpecialsDisclaimer: true });
      if (canUseDOM) {
        if (this.state.isMobileSize) rootElem.style.position = 'fixed';
      }
    };
    const hideSpecialToolTip = () => {
      this.setState({ showMobileSpecialsDisclaimer: false });
      if (canUseDOM) {
        rootElem.style.position = '';
      }
    };
    const showPriceToolTip = () => {
      this.setState({ showMobilePriceDisclaimer: true });
      if (canUseDOM) {
        if (this.state.isMobileSize) rootElem.style.position = 'fixed';
      }
    };
    const hidePriceToolTip = () => {
      this.setState({ showMobilePriceDisclaimer: false });
      if (canUseDOM) {
        rootElem.style.position = '';
      }
    };

    const headerProps = BackgroundImageHandler(getHeaderImage(this.props));

    const mobileWidth = this.props.pageWidth < 500 ? '1000' : '1650';
    const width = this.props.pageWidth > 1600 ? '2000' : mobileWidth;
    const bgImage = get(headerProps, 'style.backgroundImage', '');
    const image = bgImage.substring(4, bgImage.length - 1);
    const url = addOrReplaceUrlParam(image, 'w', width);
    const new_url = addOrReplaceUrlParam(url, 'h', 'auto');
    if (bgImage) {
      headerProps.style.backgroundImage = `url(${new_url})`;
    }

    const dailyRate = get(this.props, 'data.furnished.data.daily_rate');
    const movein = get(this.props, 'property.data.move_in_date');

    const subregions = get(this.props, 'property.data.sub-regions', []);

    const subregion = subregions.length
      ? this.props.appdata.subregions.find(sr => !!subregions[0]['sub-region'] && sr.uid === subregions[0]['sub-region'].uid)
      : null;

    const region = subregion
      ? this.props.appdata.regions.find(reg => !!subregion && !!subregion.data && !!subregion.data.region && reg.uid === subregion.data.region.uid)
      : null;

    const uid = get(this.props, 'property.uid');
    const secondaryColor = get(this.props, 'page.secondary_color');
    const property = get(this.props, 'property');
    const primaryColor = get(this.props, 'property.data.primary_color');

    let subRegionBreadCrumb;
    const referralPage = get(this.props, 'referralPage');
    if (!!referralPage && referralPage.type === 'sub-region' && referralPage.data.region.uid === region.uid) {
      subRegionBreadCrumb = referralPage;
    } else if (!!referralPage && referralPage.type !== 'sub-region') {
      subRegionBreadCrumb = null;
    } else {
      subRegionBreadCrumb = subregion;
    }

    const breadcrumbs = [
      { label: 'AMLI Home', value: '/' },
      region ? { label: region.data.name, value: `/apartments/${region.uid}` } : null,
      subRegionBreadCrumb
        ? {
            label: subRegionBreadCrumb.data.name,
            value: `/apartments/${get(subRegionBreadCrumb, 'data.region.uid')}/${subRegionBreadCrumb.uid}-apartments`
          }
        : null,
      {
        label: `${name} Apartments`,
        value: this.props.location.pathname.replace(`/${route}`, '')
      }
    ].filter(item => !!item && !!item.label);

    const metaPhone = get(this.props, 'property.data.phone');
    const metaAddress = get(this.props, 'property.data.address');
    const { phone } = this.state;
    let user = getItem('user');

    const buttonText = get(this.props, 'page.buttontext') || get(this.props, 'property.data.buttontext') || 'Schedule a Tour';

    const goDark = get(property, 'data.go_dark', '');
    const selfGuidedTourType = get(property, 'data.self_guided_tour_type') || 'None';
    const enableSelfGuidedTours = selfGuidedTourType !== 'None';
    const propertyUid = get(property, 'uid');
    const smartRentId = get(property, 'data.smart_rent_id');
    const { showAlertBanner } = this.props;
    let betterbotData = 'AMLI';
    if (this.state.showBetterbotPixel) {
      try {
        betterbotData = get(this.props, 'property.data.betterbotlink.url');
      } catch (e) {}
    }

    //#region SETUP lazy loading

    //STORE images TO lazy load
    if (headerProps) {
      let lazyLoaderElementItem = new LazyLoaderElement();

      lazyLoaderElementItem.Id = 'property_sub_page_header_image';
      lazyLoaderElementItem.BackgroundImage = headerProps.style.backgroundImage;
      lazyLoaderElementItem.BackgroundPosition = headerProps.style.backgroundPosition;
      lazyLoaderElementItem.BackgroundSize = headerProps.style.backgroundSize;

      this.lazyLoaderElementList.push(lazyLoaderElementItem);
    }

    //#endregion

    return (
      <React.Fragment>
        {this.state.showBetterbotPixel ? (
          <Helmet>
            <script type="text/javascript" className="betterbot" src={`${betterbotData}`} defer="defer"></script>
          </Helmet>
        ) : null}
        <Helmet>
          <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org/",
                  "@type": "LocalBusiness",
                  "additionalType": "ApartmentComplex",
                  "name": "${name}",
                  "address": "${metaAddress}",
                  "telephone": "${metaPhone}",
                  "image": "${image}",
                  "priceRange": "${attributes.minPrice} - ${attributes.maxPrice}",
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "${rating}",
                    "reviewCount": "${reviews}"
                  }
                }
              `}</script>
        </Helmet>
        {specials && this.state.isMobileSize && this.state.showMobileSpecialsDisclaimer && (
          <div className={styles.mobileDisclaimerContainer}>
            <div className={styles.mobileDisclaimerOverlay} />
            <div className={styles.mobileDisclaimerTextWrapper}>
              <img alt="close" src={CloseWithBackground} tabIndex={0} className={styles.toolTipClose} onClick={() => hideSpecialToolTip()} />
              {specialsList.map(special => (
                <div>
                  <div className={styles.specialText}>{special.special_text}</div>
                  <br />
                  {/*<div className={styles.specialExpDate}>Expires {moment(special.expiration_date).format('MM/DD/YY')}</div>*/}
                </div>
              ))}
            </div>
          </div>
        )}
        {this.state.isMobileSize && this.state.showMobilePriceDisclaimer && (
          <div className={styles.mobileDisclaimerContainer}>
            <div className={styles.mobileDisclaimerOverlay} />
            <div className={styles.mobileDisclaimerTextWrapper}>
              <img alt="close" src={CloseWithBackground} tabIndex={0} className={styles.toolTipClose} onClick={() => hidePriceToolTip()} />
              <div className={styles.mobilePriceDisclaimerText}>
                "Available Units Start at” reflects pricing for the lowest rent offered of an available unit at the Community regardless of the number
                of bedrooms. Unavailable units may come online with lower rent, so check back often.
              </div>
            </div>
          </div>
        )}
        <PropertySubPageHeaderView>
          <wrapper
            {...headerProps}
            className={styles.header}
            topelement={() => (
              <>
                <div className={[styles.breadcrumbContainer, showAlertBanner ? styles.hasAlertBanner : null].join(' ')}>
                  <span style={{ position: 'absolute', top: -10, height: 0 }} ref={el => (this.instance = el)} />
                  {breadcrumbs
                    .filter(itm => !!itm.label)
                    .map((item, index) => (
                      <span key={`link_${index}`} className={styles.crunchContainer}>
                        {index !== 0 && <div className="af-class-breadcrumb-divider" />}
                        <a
                          href={item.value}
                          onClick={e => {
                            if (!!e && !!e.preventDefault) {
                              e.preventDefault();
                            }
                            this.props.history.push(item.value);
                          }}
                          className="af-class-phase-2-breadcrumb-link"
                        >
                          {item.label}
                        </a>
                      </span>
                    ))}
                </div>
                <SelfTourModal
                  open={this.state.showSelfTourModal}
                  onRequestClose={() => this.setState({ showSelfTourModal: false })}
                  smartRentId={smartRentId}
                  tourProvider={selfGuidedTourType === 'Smart Rent' ? 'smartrent' : 'tour24'}
                  propertyUid={propertyUid}
                  title="Self-Guided Tours"
                />
              </>
            )}
          >
            <title className={[styles.title, enableSelfGuidedTours ? styles.titleWithSelfTourMobile : null].join(' ')}>{headerTitle}</title>
            <placard-container
              className={styles.placard}
              style={enableSelfGuidedTours ? { maxHeight: '275px' } : null}
              topelement={() => (
                <>
                  <div className="af-class-property-info-card-wrapper property-subpage-details-card-wrapper-mobile">
                    {!!movein && (
                      <div className={`af-class-move-in-date-wrapper ${styles.movein}`} style={personaBackgroundColorStyle(property)}>
                        <div className="af-class-text-block-33 " style={personaBackgroundColorStyle(property)}>
                          We'll be move-in ready
                        </div>
                        <div className="af-class-text-block-34 " style={personaBackgroundColorStyle(property)}>
                          {movein && movein}
                        </div>
                        <div className="af-class-move-in-date-dash-line" />
                      </div>
                    )}
                    <div className="af-class-property-info-card-details-mobile">
                      <div className="mobile-property-card-top-row">
                        <h2 className={`af-class-property-phase--property-name ${name.length > 15 ? 'long-property-name' : ''}`}>{name}</h2>
                        {!!reviews && !!rating ? (
                          <div className={`af-class-star-rating-wrapper ${styles.starsContainer} ${specials ? styles.starsWithSpecials : ''}`}>
                            <StarRatings
                              rating={rating}
                              starRatedColor="#ecd925"
                              starDimension="19px"
                              starSpacing="1px"
                              numberOfStars={5}
                              name="rating"
                            />
                            <span className={styles.reviews}>({reviews || 0}) </span>
                          </div>
                        ) : null}
                        {specials && (
                          <div
                            className={`af-class-div-block-157 mobile-subpage-specials-stamp ${styles.specials}`}
                            onClick={() => showSpecialToolTip()}
                          >
                            <img src="/images/icon-special-fire3x.svg" alt="specials icon" className="af-class-image-28" />
                            <div>Current Special&nbsp;</div>
                          </div>
                        )}
                      </div>
                      <div className="mobile-property-card-middle-row">
                        <div className="left-column">
                          <div className="af-class-text-block-39">{address}</div>
                          <div className="af-class-text-block-1000 mobile-card-phone-heading">
                            {customPhoneNumber && phone ? customPhoneNumberText : ''}
                          </div>
                          <div className="af-class-text-block-38 mobile-card-phone">{phone ? <a href={`tel:${phone}`}>{phone}</a> : phone}</div>
                        </div>
                        <div className="af-class-div-block-130">
                          <div className="af-class-text-block-35">{_renderConfiguration(attributes)}</div>
                          <div className="af-class-text-block-37">Available Units Start at</div>
                          <div className={`af-class-text-block-36 ${styles.pricingWrapper}`}>
                            {route === 'furnished' ? (
                              <span>
                                {`$${formatDollar(dailyRate, 0)}`}
                                <span className={styles.dailyText}>/ daily rate</span>
                              </span>
                            ) : (
                              `$${formatDollar(attributes.minPrice, 0)}`
                            )}
                            {route !== 'furnished' ? (
                              <div className={styles.disclaimerContainer}>
                                <img
                                  tabIndex={0}
                                  onClick={() => (this.state.showMobilePriceDisclaimer ? hidePriceToolTip() : showPriceToolTip())}
                                  onBlur={() => hidePriceToolTip()}
                                  onKeyDown={e => {
                                    if (e.keyCode === 13) {
                                      this.state.showMobilePriceDisclaimer ? hidePriceToolTip() : showPriceToolTip();
                                    }
                                    if (e.keyCode === 27) {
                                      hidePriceToolTip();
                                    }
                                  }}
                                  className={styles.infoIcon}
                                  src={Info}
                                  alt="more info"
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div
                        className={['mobile-property-card-bottom-row', styles.bottomRowMobile].join(' ')}
                        onClick={() => this.setState({ showCustomCloseButton: true })}
                      >
                        {route === 'furnished' ? (
                          <a
                            href={`/contact?topic=community&propertyUid=${property.uid}&propertyName=${property.data.name}&residentOption=nonResident&nonResidentOptionSelect=short-term-housing`}
                            className={styles.button}
                            onClick={e => {
                              if (e && e.preventDefault) {
                                e.preventDefault();
                              }
                              this.props.history.push({
                                pathname: '/contact',
                                search: `?topic=community&propertyUid=${property.uid}&propertyName=${property.data.name}&residentOption=nonResident&nonResidentOptionSelect=short-term-housing`
                              });
                            }}
                            style={{ backgroundColor: secondaryColor }}
                          >
                            <span className={styles.buttonText}>Request Information</span>
                          </a>
                        ) : (
                          <a // eslint-disable-line
                            style={{ backgroundColor: secondaryColor }}
                            className={styles.visit}
                            {...handlePrismicLink(this.props, {}, { propertyData: property }, goDark !== 'Yes' ? buttonText : 'goDark')}
                          >
                            <div className={styles.visitLabel}>{buttonText}</div>
                          </a>
                        )}
                        {goDark !== 'Yes' ? (
                          <a
                            href={property && composeApplyLink(property.data) ? composeApplyLink(property.data) : '#'}
                            onClick={e => {
                              if (e) {
                                e.preventDefault();
                              }
                              if (composeApplyLink(property.data) && composeApplyLink(property.data) === '/create-profile') {
                                this.props.history.push('/create-profile', {
                                  property
                                });
                              } else if (composeApplyLink(property.data)) {
                                window.open(composeApplyLink(property.data));
                              }
                            }}
                            className={['af-class-button-7 w-button', styles.apply].join(' ')}
                            style={{ backgroundColor: primaryColor }}
                          >
                            {user ? 'My Account/Apply' : 'Apply'}
                          </a>
                        ) : null}
                        {enableSelfGuidedTours && (
                          <div className={styles.selfTourWrapperMobile}>
                            <a
                              className={styles.selfTour}
                              href={
                                selfGuidedTourType === 'Smart Rent'
                                  ? `https://selftournow.com/property/${smartRentId}`
                                  : `https://app.tour24now.com/tour/${propertyUid.replace(/\-/g, '')}`
                              }
                              onClick={e => {
                                e.preventDefault();
                                this.setState({ showSelfTourModal: true });
                              }}
                            >
                              Schedule a Self-Guided Tour
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {enableSelfGuidedTours && (
                    <div className={styles.selfTourWrapper}>
                      <a
                        className={styles.selfTour}
                        href={
                          selfGuidedTourType === 'Smart Rent'
                            ? `https://selftournow.com/property/${smartRentId}`
                            : `https://app.tour24now.com/tour/${propertyUid.replace(/\-/g, '')}`
                        }
                        onClick={e => {
                          e.preventDefault();
                          this.setState({ showSelfTourModal: true });
                        }}
                      >
                        <img
                          src="/images/icon-arrow--right-no-shadow3x.png"
                          alt="Arrow icon."
                          className={['af-class-boss-right-arrow', styles.buttonArrow].join(' ')}
                        />
                        <span className={styles.selfTourLabel}>Schedule a Self-Guided Tour</span>
                      </a>
                    </div>
                  )}
                </>
              )}
            >
              <property-name className={styles.name}>{name}</property-name>
              {!!reviews && !!rating ? (
                <stars className={styles.starsContainer}>
                  <StarRatings rating={rating} starRatedColor="#ecd925" starDimension="19px" starSpacing="3px" numberOfStars={5} name="rating" />
                  <span className={styles.reviews}>({reviews || 0})</span>
                </stars>
              ) : null}
              <address className={styles.address}>{address}</address>
              {customPhoneNumber && phone ? <phone-heading>{customPhoneNumberText}</phone-heading> : <phone-heading></phone-heading>}
              {phone ? (
                <phone className={styles.phone}>
                  <a href={`tel:${phone}`}>{phone}</a>
                </phone>
              ) : (
                phone
              )}
              <details-wrapper
                onClick={() => this.setState({ showCustomCloseButton: true })}
                topelement={() =>
                  route === 'furnished' ? (
                    <a
                      href={`/contact?topic=community&propertyUid=${property.uid}&propertyName=${property.data.name}&residentOption=nonResident&nonResidentOptionSelect=short-term-housing`}
                      className={styles.button}
                      onClick={e => {
                        if (e && e.preventDefault) {
                          e.preventDefault();
                        }
                        this.props.history.push({
                          pathname: '/contact',
                          search: `?topic=community&propertyUid=${property.uid}&propertyName=${property.data.name}&residentOption=nonResident&nonResidentOptionSelect=short-term-housing`
                        });
                      }}
                      style={{ backgroundColor: secondaryColor }}
                    >
                      <img
                        src="/images/icon-arrow--right-no-shadow3x.png"
                        alt="right-arrow"
                        className={`af-class-boss-right-arrow ${styles.buttonArrow}`}
                      />
                      <span className={styles.buttonText}>Request Information</span>
                    </a>
                  ) : (
                    <a // eslint-disable-line
                      style={{ backgroundColor: secondaryColor }}
                      className={styles.visit}
                      {...handlePrismicLink(this.props, {}, { propertyData: property }, goDark !== 'Yes' ? buttonText : 'goDark')}
                    >
                      <img
                        src="/images/icon-arrow--right-no-shadow3x.png"
                        alt="Arrow icon."
                        className={['af-class-boss-right-arrow', styles.buttonArrow].join(' ')}
                      />
                      <span className={styles.visitLabel}>{buttonText}</span>
                    </a>
                  )
                }
              >
                {specials && (
                  <specials className={styles.specials}>
                    <ToolTip
                      toolTipElement={
                        <div className={styles.specialContainer}>
                          <div>Current Special&nbsp;</div>
                          <img src="/images/icon-special-fire3x.svg" alt="specials icon" className="af-class-image-28" />
                        </div>
                      }
                      toolTipText={specialsList.map(special => (
                        <div>
                          <div className={styles.specialText}>{special.special_text}</div>
                          <br />
                          {/*<div className={styles.specialExpDate}>Expires {moment(special.expiration_date).format('MM/DD/YY')}</div>*/}
                        </div>
                      ))}
                      isMobile={this.state.isMobileSize}
                      toolTipPosition={'top'}
                    />
                  </specials>
                )}
                <beds className={styles.beds}>{_renderConfiguration(attributes)}</beds>
                <starting-label>Available Units Start at</starting-label>
                <price className={styles.price}>
                  {route === 'furnished' ? (
                    <span>
                      {`$${formatDollar(dailyRate, 0)}`}
                      <span className={styles.dailyText}>/ daily rate</span>
                    </span>
                  ) : (
                    `$${formatDollar(attributes.minPrice, 0)}`
                  )}
                  {route !== 'furnished' ? (
                    <ToolTip
                      toolTipElement={<img tabIndex={0} src={Info} alt="more info" className={styles.infoIcon} />}
                      toolTipText={
                        <div className={styles.infoText}>
                          "Available Units Start at” reflects pricing for the lowest rent offered of an available unit at the Community regardless of
                          the number of bedrooms. Unavailable units may come online with lower rent, so check back often.
                        </div>
                      }
                      isMobile={this.state.isMobileSize}
                      toolTipPosition={'top'}
                    />
                  ) : null}
                </price>
              </details-wrapper>
            </placard-container>
          </wrapper>
        </PropertySubPageHeaderView>
        {hideEntrataWidget && this.state.showCustomCloseButton && (
          <div
            className={styles.widgetClose}
            onClick={() => {
              window.ppcb.close();
              this.setState({ showCustomCloseButton: false });
            }}
          >
            Close X
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(
  graphql(propertyAttributesQuery, {
    name: 'attributesQuery',
    options: props => ({
      variables: {
        id: String(props.property.data.amli_id),
        review_push_id: String(props.property.data.review_push_id),
        reputationdotcom_id: String(props.property.data.reputationdotcom_id),
        reputationdotcom_reviews_enabled: Boolean(props.property.data.reputationdotcom_reviews_enabled)
      }
    })
  })(PropertySubPageHeader)
);
