const GetRegionSlug = (region) => {

  switch (region) {
    case 'Atlanta':
      return 'atlanta';
    case 'Austin':
      return 'austin';
    case 'Chicago':
      return 'chicago';
    case 'Dallas':
      return 'dallas'; 
    case 'Denver':
      return 'denver';
    case 'Houston':
      return 'houston';
    case 'Seattle':
      return 'seattle';
    case 'Florida':
      return 'southeast-florida';
    case 'Southern California':
      return 'southern-california';
    default:
      return ''
  }
}

export default GetRegionSlug;
