import React from "react";
import get from "lodash/get";
import QuoteDetailsColumnPaymentSummaryPrintView from "../../components/QuoteDetailsColumnPaymentSummaryPrintView";
import QuoteDetailsColumnPaymentSectionPrintView from "../../components/QuoteDetailsColumnPaymentSectionPrintView";
import QuoteMonthlyRentLineView from "../../components/QuoteMonthlyRentLineView";
import { formatDollar } from "../../helpers/utils";
import styles from './styles.module.css';

const minValFilter = it => it && it.amount > 0;
function QuoteDetailsColumnPaymentSummaryPrintController(props) {
  const quote = get(props, "quote", {});
  const leaseOptions = get(props, "quote.leaseOptions", []);

  const deposits = leaseOptions.filter(
    lo => lo.deposit > 0 && lo.category !== "PET"
  );
  if (quote.depositRequired && quote.deposit > 0) {
    deposits.push({
      name: "Deposit",
      deposit: quote.deposit
    });
  }

  const dueAtMoveIn = get(quote, "paymentSummary.dueAtMoveIn", []).filter(
    minValFilter
  );
  const immediately = get(quote, "paymentSummary.dueImmediately", []).filter(
    minValFilter
  );
  const dueOnApproval = get(quote, "paymentSummary.dueOnApproval", []).filter(
    minValFilter
  );
  const monthly = get(quote, "paymentSummary.customRentSummary", []).filter(
    minValFilter
  );


  return (
    <QuoteDetailsColumnPaymentSummaryPrintView>
      <active-tab className={styles.breakdownContainer}>
        <quoteDetailsColumnPaymentSectionViewList0>
          {immediately.length > 0 ? (
            <QuoteDetailsColumnPaymentSectionPrintView.Controller
              sectionTitle="Due Immediately"
              items={immediately.map(it => ({
                label: it.description,
                value: `$${formatDollar(it.extendedAmount)}`
              }))}
            />
          ) : null}
          {dueOnApproval.length > 0 ? (
            <QuoteDetailsColumnPaymentSectionPrintView.Controller
              sectionTitle="Due On Pre Approval"
              items={dueOnApproval.map(it => ({
                label: it.description,
                value: `$${formatDollar(it.extendedAmount)}`
              }))}
            />
          ) : null}
          {dueAtMoveIn.length > 0 && (
            <QuoteDetailsColumnPaymentSectionPrintView.Controller
              sectionTitle="Due At Move In"
              items={dueAtMoveIn.map(item => ({
                label: item.description,
                value: `$${formatDollar(item.extendedAmount, 2)}`
              }))}
            />
          )}
        </quoteDetailsColumnPaymentSectionViewList0>

      </active-tab>
    </QuoteDetailsColumnPaymentSummaryPrintView>
  );
}

export default QuoteDetailsColumnPaymentSummaryPrintController;
