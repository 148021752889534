import React from 'react';

import styles from './styles.module.css';
import PrismicDom from 'prismic-dom';

const SubregionDescription = ({ title, description, richDescription }) => {
  const _richDescription = PrismicDom.RichText.asHtml(richDescription || []);

  return (
    <>
      <h1 className={styles.subregionTextBoxHeader}>{title + ' Apartments'}</h1>
      <p className={styles.subregionTextBoxDescription}>{description}</p>
      <div className={styles.subregionTextBoxDescription} dangerouslySetInnerHTML={{ __html: _richDescription }} />
    </>
  );
};

export default SubregionDescription;
