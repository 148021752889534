import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { setQueryParameters } from '../../helpers/add-or-replace-url-param';

import styles from './styles.module.css';
import './index.css';

const ImageSlider = ({ images, className }) => {
  const [selected, setSelected] = useState(0);
  const settings = {
    showArrows: false,
    showStatus: false,
    showThumbs: false,
  };

  return (
    <div className={styles.sliderWrapper + ' ' + className}>
      {images.length > 1 ? (
        <>
          <img
            src="/images/icon-arrow--slide-left3x.png"
            alt=""
            className={`${styles.sliderController} ${styles.leftArrow}`}
            onClick={() => {
              if (images && images.length) {
                setSelected((selected + images.length - 1) % images.length);
              }
            }}
          />
          <img
            src="/images/icon-arrow--slide-right3x.png"
            alt=""
            className={`${styles.sliderController} ${styles.rightArrow}`}
            onClick={() => {
              if (images && images.length) {
                setSelected((selected + 1) % images.length);
              }
            }}
          />
        </>
      ) : null}
      <Carousel {...settings} selectedItem={selected}>
        {images.map((image) => (
          <div className={styles.slideContainer} key={image}>
            <img
              key={image}
              src={setQueryParameters(image.src, { q: 5 })}
              alt={image.alt}
              className={styles.image}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageSlider;
