import React, { useState, useEffect } from "react";

import get from "lodash/get";
import { withRouter } from "react-router-dom";
import QuoteSummaryCardPrintView from "../../components/QuoteSummaryCardPrintView";
import placeholderImage from "../../assets/images/floorplan-placeholder@2x.jpg";
import styles from "./styles.module.css";
import { formatDollar, canUseDOM } from "../../helpers/utils";
import getPropertyDataFromAppData from "../../helpers/property/getDataFromProps";
import BackgroundImageHandler from "../../helpers/components-data-handlers/background-image-handler";
import MultiLineString from "../../helpers/mutli-line-string";
import usePhone from "../../helpers/hooks/usePhone";
import "./index.css";
import { applyWithQuote } from "../../helpers/property/apply";
import { addQualityParam } from "../../helpers/add-or-replace-url-param";
// import { unitsQuery } from "../../graphql/queries";

const moment = require("moment");

function QuoteSummaryCardPrint(props) {

  const {
    quote: {
      unit,
      floorPlan
    }
  } = props;

  const property = get(props, "quote.property.propertyId");
  const floorPlanName = get(floorPlan, "data.name").split(" - AMLI")[0];
  const floorPlanImage = get(floorPlan, "data.main_image.url");
  const unitName = get(unit, "data.unit_number");
  const imageProps = BackgroundImageHandler(
    get(property, "data.primary_property_image")
  );
  const beds = get(unit, "bedrooms");
  const baths = get(unit, "bathrooms");
  const sqft = get(unit, "squareFeet");
  const floor = get(unit, "floor");
  const amli_id = get(props, "quote.property.propertyId");

  return (
    <QuoteSummaryCardPrintView>
      <floorplan-details className={styles.floorplanCardContainer}>
        <floorplan-name>{floorPlanName}</floorplan-name>
        <unit-name className={styles.unitName}>{unitName}</unit-name>
        {floorPlanImage ? (
          <plant-image src={addQualityParam(floorPlanImage, 5)} className={styles.plant} />
        ) : (
          <plant-image
            src={placeholderImage}
            alt="Images Coming Soon"
            className={styles.plant}
          />
        )}
        {beds ? (
          <beds className={styles.beds}>
            {+beds === 0 ? "Studio" : `${+beds} Bedroom${+beds > 1 ? "s" : ""}`}
          </beds>
        ) : null}
        {baths ? (
          <baths className={styles.baths}>{`${parseFloat(baths)} Bath${
            parseFloat(baths) > 1 || parseFloat(baths) === 0 ? "s" : ""
          }`}</baths>
        ) : null}
        {sqft ? (
          <sqft className={styles.sqft}>
            {sqft ? sqft.toLocaleString() : ""} Sq Ft
          </sqft>
        ) : null}
        {floor ? <floor className={styles.floor}>Floor {floor.toLocaleString()}</floor> : null}
      </floorplan-details>
    </QuoteSummaryCardPrintView>
  );
}

export default withRouter(QuoteSummaryCardPrint);
