import React from 'react';
import get from 'lodash/get';
import styles from './styles.module.css';
import './index.css';

const moment = require('moment-timezone');

function FloorplansSpecialSpotlight(props) {
  const text = get(props, 'property.data.specials[0].special_text');
  const date = get(props, 'property.data.specials[0].expiration_date');
  const formattedDate = moment(date).format('MM/DD/YY');
  const phone = get(props, 'property.data.phone');
  const imageUrl = get(props, 'property.data.spotlight_specials_image.url');

  if (
    moment(date)
      .tz('America/Chicago')
      .endOf('day')
      .isBefore(new Date())
  ) {
    return null;
  }

  return (
    <React.Fragment>
      <span className="af-view" style={{ width: '100%', height: '100%', flex: 1 }}>
        <section className="af-class-spotlight-current-specials">
          <div className="af-class-div-block-182">
            <div className="af-class-div-block-186">
              <img src={imageUrl ? imageUrl : '/images/spotlight-tour-kitchen2x-p-500.jpeg'} alt="Controller" className="af-class-image-34"></img>
              <div className="af-class-div-block-187"></div>
            </div>
            <div className="af-class-div-block-184">
              <img src="/images/icon-special-fire3x.svg" alt="specials icon" className="af-class-image-33"></img>
              <div className="af-class-text-block-58">current special</div>
            </div>
            <div className="af-class-div-block-185">
              <div className={'af-class-text-block-56 ' + styles.text}>{text}</div>
    {/*          <div className={'af-class-text-block-57 ' + styles.date}>Exp. {formattedDate}</div>*/}
            </div>
            <a href={'tel: ' + phone} className={'af-class-button-4 w-button ' + styles.cta}>
              Call for details
            </a>
          </div>
        </section>
      </span>
    </React.Fragment>
  );
}

export default FloorplansSpecialSpotlight;
