import React from "react";
import QuoteSummaryColumnItemPrintView from "../../components/QuoteSummaryColumnItemPrintView";

import styles from './styles.module.css';

function QuoteSummaryColumnItemPrint(props) {
  return (
    <QuoteSummaryColumnItemPrintView>
      <label className={styles.label}>{props.label}</label>
      <value className={styles.value}>{props.value}</value>
    </QuoteSummaryColumnItemPrintView>
  );
}

export default QuoteSummaryColumnItemPrint;
