import React from "react";
import get from "lodash/get";
import RichTextAreaView from "../../components/RichTextAreaView";
import PrismicDom from "prismic-dom";
import "./index.css";
import styles from './styles.module.css';

function RichTextArea(props)
{
    const items = get(props, "slice.primary.text", []);

    let body = PrismicDom.RichText.asHtml(items);

    body = body.replace(/\[nested_list_start\]/g, "<ul>");
    body = body.replace(/\[nested_list_item_start\]/g, "<li>");
    body = body.replace(/\[nested_list_item_end\]/g, "</li>");
    body = body.replace(/\[nested_list_end\]/g, "</ul>");

    return (
        <RichTextAreaView>
            <wrapper>
                <div className={styles.bodyContainer} dangerouslySetInnerHTML={{ __html: body }} />
            </wrapper>
        </RichTextAreaView>
    );
}

export default RichTextArea;
