const getTypeLabel = (type) => {
    switch (type) {
        case "Z":
            return "Affordable";
        case "Y":
            return "Affordable";
        case "W":
            return "Live/Work";
        case "T":
            return "Townhome";
        // case "S":
        //   return "Sunroom";
        case "P":
            return "Penthouse";
        case "L":
            return "Loft";
        // case "G":
        //   return "Garage";
        case "D":
            return "Den/Office";
        case "ORI":
            return "Modular Furniture";
        default:
            return false;
    }
}
const GetUnitTypes = (data) => {
    if (!data) {
        return [];
    }
    let found = false;

    const types = [];

    data.map(fp => {
        if (fp.units) {
            fp.units.map(unit => {
                const typeCode = unit.unitTypeDesignation || "";
                if (typeCode.length > 1) {
                    if (!types.includes(typeCode)) {
                        types.push(typeCode);
                    }
                }
                else {
                    for (let index = 0; index < typeCode.length; index++) {
                        const typeLetter = typeCode[index];
                        if (!types.includes(typeLetter)) {
                            types.push(typeLetter);
                        }
                    }
                }
            })
        }
    })

    const typeOptions = types.sort().filter((type) => {
        const label = getTypeLabel(type);
        if (label) return true;
    })
        .map((type) => {
            const label = getTypeLabel(type);
            return { label, value: type };
        })
    const selections = [];
    typeOptions.forEach(option => {
        const selectionIndex = selections.find(selection => selection.label === option.label)
        if (selectionIndex > -1) {
            selections[selectionIndex] = {
                label: option.label,
                value: [...selections[selectionIndex], option.value]
            }
        } else {
            selections.push({
                label: option.label,
                value: [option.value]
            })
        }
    })
    return selections;

}
export default GetUnitTypes;
