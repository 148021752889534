import React, { useState, useEffect } from 'react';
import get from 'lodash/get';

import HeaderController from './headerController';
import PropertyComparison from './../PropertyComparison/index';

import './index.css';
import { graphql, compose } from 'react-apollo';
import { regionPropertyAttributesQuery } from './../../graphql/queries/regionPropertyAttributes';
import InteractiveCommunitiesMap from './../InteractiveCommunitiesMap/index';

export default compose(
  graphql(regionPropertyAttributesQuery, {
    name: 'regionPropertyAttributes',
    skip: (props) => {
      return (
        !props.page &&
        !props.page.data &&
        !props.page.data.region &&
        !props.page.data.region.id &&
        !props.appdata &&
        !props.appdata.regions &&
        props.appdata.regions.length > 0
      );
    },
    options: (props) => {
      const {
        data: { amli_id },
      } = props.appdata.regions.find((region) => {
        return region.id === props.page.data.region.id;
      });

      return {
        variables: {
          id: String(amli_id),
        },
        fetchPolicy: 'network-only',
      };
    },
  })
)((props) => {
  const regionPropertyAttributes = get(props, 'regionPropertyAttributes.regionPropertyAttributes');
  if (!Array.isArray(regionPropertyAttributes)) return null;

  const subregionUID = get(props, 'page.uid');
  const allProperties = get(props, 'appdata.properties');

  let [properties, setProperties] = useState(
    Array.isArray(allProperties)
      ? allProperties.filter((property) => {
          let found = false;
          property.data['sub-regions'].forEach((subRegion) => {
            if (subRegion['sub-region'].uid === subregionUID) {
              found = true;
            }
          });
          return found;
        })
      : null
  );

  const [selectedProperties, setSelectedProperties] = useState(properties);
  const [mobileSelectedProperty, setMobileSelectedProperty] = useState(0);
  const [propertiesReadyToDisplay, setPropertiesReadyToDisplay] = useState(false);

  useEffect(() => {
    try {
      const sortPropertiesByDisplayOrder = (a, b) => {
        return (
          regionPropertyAttributes.find(({ propertyId }) => propertyId === a.data.amli_id)
            .displayOrder -
          regionPropertyAttributes.find(({ propertyId }) => propertyId === b.data.amli_id)
            .displayOrder
        );
      };
      const sortedProperties = properties.sort(sortPropertiesByDisplayOrder);
  
      setProperties(sortedProperties);
      setSelectedProperties(sortedProperties);
      setPropertiesReadyToDisplay(true);
    } catch (e) {
      console.error('Error sorting properties by display order: ', e);
      setProperties(properties);
      setSelectedProperties(properties);
      setPropertiesReadyToDisplay(true);
    }

  }, []);

  return propertiesReadyToDisplay ? (
    <>
      <HeaderController
        {...props}
        {...{
          subregionUID,
          properties,
          setProperties,
          selectedProperties,
          setSelectedProperties,
          mobileSelectedProperty,
          setMobileSelectedProperty,
        }}
      />
      <PropertyComparison
        {...props}
        {...{
          subregionUID,
          properties,
          setProperties,
          selectedProperties,
          setSelectedProperties,
          mobileSelectedProperty,
          setMobileSelectedProperty,
        }}
      />
      {props && props.appdata && props.data && props.data.engrain_id ? (
        <InteractiveCommunitiesMap
          key="interactive-communities-app"
          data={props.data}
          page={props}
          engrainId={props.data.engrain_id}
          appdata={props.appdata}
          properties={properties}
        />
      ) : null}
    </>
  ) : null;
});
