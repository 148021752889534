import React from 'react';

const ExtractMeta = (props, canonicalLink) => {
  if (!props || !props.data) {
    return [];
  }
  const isSubregion = props.type === 'sub-region';
  const isBlogPost = props.type === 'blog_post';
  const page = props.data;
  const keys = [
    'page_title',
    'og_description',
    'og_see_also',
    'og_site_name',
    'og_title',
    'meta_description',
    'meta_canonical',
    'social_share_image',
    'twitter_description',
    'twitter_title'
  ];
  const output = [];
  output.push(<meta property="og:type" content={isBlogPost ? 'article' : 'website'} />);
  keys.forEach(key => {
    if (!!page[key]) {
      if (key === 'page_title') {
        output.push(<title key={key}>{page[key]}</title>);
      } else if (key === 'meta_canonical') {
        if (canonicalLink) {
          output.push(<link key={`${key}_canonical`} rel="canonical" href={canonicalLink} />);
          output.push(<link key={`${key}_canonical_og`} property="og:url" content={canonicalLink} />);
        } else if (page[key].url) {
          const urlPiecesArray = page[key].url.split('/');
          const urlEnding = urlPiecesArray[urlPiecesArray.length - 1];
          const trueUrl = `https://www.amli.com/blog/${urlEnding}`;
          output.push(<link key={`${key}_canonical`} rel="canonical" href={isBlogPost ? trueUrl : page[key].url} />);
          output.push(<link key={`${key}_canonical_og`} property="og:url" content={isBlogPost ? trueUrl : page[key].url} />);
        }
      } else if (key === 'meta_description') {
        output.push(<meta key={key} name="description" content={page[key]} />);
      } else if (key === 'social_share_image') {
        if (page && page[key] && page[key].url) {
          output.push(<meta key={`${key}_og`} property="og:image" content={page[key].url} />);
          output.push(<meta property="og:image:width" content="1000" />);
          output.push(<meta property="og:image:height" content="500" />);
          output.push(<meta key={`${key}_twitter`} property="twitter:image" content={page[key].url} />);
        }
      } else {
        output.push(<meta key={key} property={key.replace(new RegExp('_'), ':')} content={page[key]} />);
      }
    }
  });

  if (isSubregion) {
    output.push(<script src="https://sightmap.com/embed/api.js" type="text/javascript" />);
  }

  return output;
};

export const ExtractMetaProperty = (page, subroute, canonicalLink) => {
  if (!page) {
    return [];
  }
  const keys = [
    `${subroute}_page_title`,
    `${subroute}_meta_description`,
    `${subroute}_social_share_image`,
    `${subroute}_open_graph_description`,
    `${subroute}_social_share_image`,
    `${subroute}_open_graph_title`,
    `${subroute}_meta_canonical`
  ];
  const output = [];
  output.push(<meta property="og:type" content="website" />);
  keys.forEach(fullKey => {
    const key = fullKey.replace(`${subroute}_`, '');
    if (key === 'meta_canonical') {
      if (page[key].url) {
        output.push(<link key={`${key}_canonical`} rel="canonical" href={page[fullKey].url} />);
        output.push(<link key={`${key}_canonical_og`} property="og:url" content={page[fullKey].url} />);
      } else if (canonicalLink) {
        output.push(<link key={`${key}_canonical`} rel="canonical" href={canonicalLink} />);
        output.push(<link key={`${key}_canonical_og`} property="og:url" content={canonicalLink} />);
      }
    }
    if (!!page[fullKey]) {
      if (key === 'page_title') {
        output.push(<title key={key}>{page[fullKey]}</title>);
      } else if (key === 'meta_description') {
        output.push(<meta key={key} name="description" content={page[fullKey]} />);
      } else if (key === 'social_share_image') {
        if (page && page[fullKey] && page[fullKey].url) {
          output.push(<meta key={`${key}_og`} property="og:image" content={page[fullKey].url} />);
          output.push(<meta property="og:image:width" content="1000" />);
          output.push(<meta property="og:image:height" content="500" />);
          output.push(<meta key={`${key}_twitter`} property="twitter:image" content={page[fullKey].url} />);
        }
      } else {
        output.push(<meta key={key} property={key.replace(new RegExp('_'), ':')} content={page[fullKey]} />);
      }
    }
  });
  return output;
};

export default ExtractMeta;
