import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import AmliPerksHeader from '../../assets/images/AmliPerksHeader.jpg';
import { Font } from '@react-pdf/renderer';
export default function ListPerksPdfDocument(props) {
  Font.register({
    family: 'Outfit',
    src: window.location.protocol + '//' + window.location.host + '/fonts/Outfit-Regular.ttf'
  });

  const headerImageStyle = {
    width: '100%',
    marginBottom: '-5px'
  };

  const footerStyle = {
    height: '40px',
    bottom: '20px',
    width: '100%',
    marginLeft: '14px',
    marginRight: '20px',
    position: 'absolute',
    textAlign: 'center'
  };

  const textFooterStyle = {
    paddingHorizontal: '20px',
    backgroundColor: 'white',
    fontSize: 17,
    fontWeight: 'bold',
    fontFamily: 'Outfit',
    top: '140%',
    left: '190px',
    width: '280px',
    position: 'fixed',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)'
  };
  const footerLineStyle = {
    backgroundColor: '#d7df23',
    top: '60%',
    height: '2.5px',
    left: '0px',
    width: '100%',
    position: 'absolute'
  };

  const textStyle = {
    margin: 1,
    fontSize: 10,
    textAlign: 'center',
    fontFamily: 'Outfit'
  };

  const businessNameStyle = {
    margin: 1,
    fontSize: 10,
    textAlign: 'center',
    fontFamily: 'Outfit',
    textTransform: 'uppercase'
  };

  const imageStyle1 = {
    display: 'block',
    margin: 'auto',
    width: '50px',
    align: 'center'
  };

  const innerFlexStyle = {
    display: 'flex',
    backgroundColor: '#fff',
    margin: '7px',
    textAlign: 'center',
    fontSize: '10px',
    width: '126px'
  };

  const bodyStyle = {
    paddingTop: 38,
    paddingBottom: 38,
    paddingHorizontal: 14
  };

  const flexStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#fff',
    flexDirection: 'row'
  };

  const propertyname = props.propertyname;

  let perksList;
  try {
    perksList = props.data.map((perk, index) =>
      perk && perk.perk_image && perk.perk_image.url ? (
        <View key={index} style={innerFlexStyle} wrap={false}>
          <View>
            <Image style={imageStyle1} source={{ uri: perk.perk_image.url, method: 'GET', body: '' }}></Image>
            <Text style={businessNameStyle}>{perk.name}</Text>
            <Text style={textStyle}>{perk.perk_text}</Text>
          </View>
        </View>
      ) : null
    );
  } catch (ex) {
    perksList = [];
  }

  const perksPage = (
    <Page style={bodyStyle}>
      <Image style={headerImageStyle} source={AmliPerksHeader} fixed></Image>
      <View style={flexStyle}>{perksList}</View>
      <View style={footerStyle} fixed>
        <View style={footerLineStyle}></View>
        <Text style={textFooterStyle} fixed>
          {propertyname}
        </Text>
      </View>
    </Page>
  );

  let result;
  try {
    result = <Document>{perksPage}</Document>;
  } catch (ex) {
    result = <Document>There was a problem in generating pdf.</Document>;
  }
  return result;
}
