import React, { useEffect } from "react";
import { withRouter } from "react-router";
import get from "lodash/get";
import SpotlightSpecialFeatureView from "../../components/SpotlightSpecialFeatureView";
import { handlePrismicLink } from "../../helpers/link-helper";
import MultiLineString from "../../helpers/mutli-line-string";
import { personaBackgroundColorStyle } from "../../helpers/property/persona";
import styles from "./styles.module.css";
import "./index.css";
import { addQualityParam } from "../../helpers/add-or-replace-url-param";
import { LazyLoaderElement, LazyLoader } from "../../helpers/lazy-loader";

export default withRouter(props => {
    const { slice } = props;
    const image1 = get(slice, "primary.image_1.url");
    const title = get(slice, "primary.title");
    const textBlurb = get(slice, "primary.text_blurb[0].text");
    const linkText = get(slice, "primary.link_text");
    const property = get(props, "property") || get(props, "page.data");
    const linkData = handlePrismicLink(
        props,
        get(slice, "primary.link_url"),
        { propertyData: get(props, "page") },
        linkText
    );

    let sliceIDReg1 = new RegExp("-", 'g');
    let sliceID = slice.id.replace("$", "_");
    sliceID = sliceID.replace(sliceIDReg1, "_");

    const image2 = get(slice, "primary.image_2.url");

    let lazyLoaderElementList = [];

    useEffect(() => {
        //#region SETUP lazy loading

        let lazyLoaderElementItem = new LazyLoaderElement();

        //STORE images TO lazy load
        if (image1) {
            lazyLoaderElementItem.Id = "spotlight_special_feature_image_1_" + sliceID;

            lazyLoaderElementItem.BackgroundImage = "url('" + addQualityParam(image1, 5) + "')";
            lazyLoaderElementList.push(lazyLoaderElementItem);
        }

        if (image2) {
            lazyLoaderElementItem = new LazyLoaderElement();
            lazyLoaderElementItem.Id = "spotlight_special_feature_image_2_" + sliceID;
            lazyLoaderElementItem.BackgroundImage = "url('" + addQualityParam(image2, 5) + "')";

            lazyLoaderElementList.push(lazyLoaderElementItem);
        }

        if (lazyLoaderElementList.length > 0) {
            const lazyLoader = new LazyLoader();
            lazyLoader.LazyLoad(lazyLoaderElementList);
        }

        //#endregion
    }, [])

    return (
        <SpotlightSpecialFeatureView>
            <image-1 id={"spotlight_special_feature_image_1_" + sliceID } />
            <center-wrapper
                className={styles.textWrapper}
                style={personaBackgroundColorStyle(property)}
            >
                <title>
                    <h2 className="af-class-property-sub-section-tagline af-class-white">
                        {MultiLineString(title)} 
                    </h2>
                </title>
                <text-blurb>{textBlurb}</text-blurb>
                {linkText ? (
                    <link-url {...linkData}>
                        <link-text style={{ color: 'white' }}>
                            {linkText}
                        </link-text>
                    </link-url>
                ) : null}
            </center-wrapper>
            <image-2 id={"spotlight_special_feature_image_2_" + sliceID} />
        </SpotlightSpecialFeatureView>
    );
});
