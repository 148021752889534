import gql from "graphql-tag";

export const googleMapsQuery = gql`
   query googleMaps(
  $latitude: Float!,
  $longitude: Float!,
  $radius: Int!,
  $type: [String!]!,
  $minprice: Int,
  $maxprice: Int
) {
  googleMaps(
    latitude: $latitude,
    longitude: $longitude,
    radius: $radius,
    type: $type,
    minprice: $minprice,
    maxprice: $maxprice
  ) {
    name
    location {
      latitude
      longitude
    }
    viewport {
      northeast {
        lat
        lng
      }
      southwest {
        lat
        lng
      }
    }
    icon
    opening_hours {
      open_now
    }
    photos {
      height
      width
      photo_reference
      html_attributions
    }
    place_id
    rating
    vicinity
    types
    user_ratings_total
    website
    price_level
  }
}
`;
