import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { get, xor } from "lodash";
import icon from "../../assets/icons/arrow-chevron.svg";
import Checkbox from "../FloorplanHeaderFiltersController/checkbox";
import { getPersonaColor } from "../../helpers/property/persona";
import GetUnitTypes from "../../helpers/components-data-handlers/get-unit-types";

const toggle = (array, item) => xor(array, [item]);
let listCurrentState = [];

function AdditionalFilters(props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [localFilters, setLocalFilters] = useState(props.filters.homeTypes);
  const [size, setSize] = useState(props.filters.size);

  const hasSize = () => {
    return props.location.search.includes('size');
  }

  const { property, propertyFloorplansSummary, filters, setFilters } = props;

  let skipCode = false;
  let types = null;
  let filteredValues = {};

  useEffect(() => {
    if (props.filters.closeHomeType) {
      setDropdownOpen(false);
    }

    setFilters({
      ...filters,
      closeHomeType: false,
      closeBedType: false,
      closeBathType: false,
    });

    skipCode = true;
  }, [props.filters.closeHomeType]);

  useEffect(() => {
    filteredValues = {
      dropDownID: "additionalFilters",
      dropDownValues: localFilters,
    };

    setFilters({
      ...filters,
      homeTypes: localFilters,
      filteredValues: filteredValues,
    });
  }, [localFilters]);

  useEffect(() => {
    if (!skipCode) {
      setFilters({ ...filters, closeBedType: true, closeBathType: true });
    }
  }, [dropdownOpen]);

  let floorplanData = get(
    propertyFloorplansSummary,
    "propertyFloorplansSummary"
  );
  types = GetUnitTypes(floorplanData) || [];

  const filterDropDownList = (featureListUnFiltered) => {
    let filteredFeaturesList = [];

    // If we are using a ?size=n filter, then filter out the floorplans that don't match the size
    if (floorplanData) {
      if (hasSize()) {
        floorplanData = floorplanData.filter((floorplan) => {
          return floorplan.bathroomMin === size;
        });
      }
    }

    filteredFeaturesList = GetUnitTypes(floorplanData) || [];

    return filteredFeaturesList;
  };

  if (props.filters.filteredValues.dropDownID == "additionalFilters") {
    types = listCurrentState;
  } else {
    types = filterDropDownList(types);

    listCurrentState = types;
  }

  if (!types || !types.length) {
    return null;
  }

  return (
    <div
      id="additionalFilters"
      style={{ position: "relative" }}
      tabIndex={-1}
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setDropdownOpen(false);
        }
      }}
    >
      <div
        className={styles.filtersDropdown}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <span style={{ fontSize: 16 }}>Features</span>
        <img
          src={icon}
          alt="dropdown arrow"
          className={dropdownOpen ? styles.dropdownOpen : styles.dropdownClosed}
        />
      </div>
      {dropdownOpen && (
        <div className={styles.dropdownContainer}>
          <p style={{ fontSize: 12 }}>HOME TYPE</p>
          {types.map((type, index) => {
            return (
              <Checkbox
                key={`${type.value.join("-")}-${index}`}
                label={type.label}
                onChange={async (e) => {
                  const newTypes = toggle(localFilters, type.value.join(","));
                  setLocalFilters(newTypes);
                }}
                color={getPersonaColor(property, "primary")}
                isChecked={(localFilters || []).includes(type.value.join(","))}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default AdditionalFilters;
