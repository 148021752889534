import React from "react";
import get from "lodash/get";
import PortfolioEnvironmentalTargetsView from "../../components/PortfolioEnvironmentalTargetsView";
import BackgroundImageHandler from "../../helpers/components-data-handlers/background-image-handler";
import TextWritter from "../../helpers/text-writter/index";
import styles from "./styles.module.css";
import "./index.css";

export default props => {

    const title = get(props, "slice.primary.title", [])[0];
    const sub_text = get(props, "slice.primary.sub_text", [])[0];
    const items = get(props, "slice.items", []);
    const infographic_desktop_left = get(props, "slice.primary.infographic_desktop_icon_left", []);
    const infographic_desktop_right = get(props, "slice.primary.infographic_desktop_icon_right", []);

    return (
        <PortfolioEnvironmentalTargetsView>
            <title>{(title) ? title.text : ""}</title>
            <sub-text>{(sub_text) ? sub_text.text : ""}</sub-text> 
            <infographic-left-image />
            <infographic-grid-data>
                <div className="af-portfolio-environmental-infographic-data-wrapper-left">
                    <img src={infographic_desktop_left.url} />     
                </div>
                <div className="af-portfolio-environmental-infographic-data-wrapper-center">
                    <div className="af-portfolio-environmental-infographic-data-wrapper-center-item-column-1">
                        {items !== "" && items.map((item, index) => (
                            (index <= 2) ?
                                <div className="af-portfolio-environmental-infographic-data-wrapper-center-item-row">
                                    <img src={item.infographic_icon.url} /> 
                                    <h3
                                        key={`infographic-grid-item-header${index}`}
                                        className="af-portfolio-environmental-infographic-data-item-header"
                                    >
                                        {item.infographic_title[0].text}
                                    </h3>
                                    <div key={`infographic-grid-item-wrapper-progress${index}`} className="af-portfolio-environmental-infographic-grid-item-wrapper-progress">
                                        {(item.infographic_progress_date.length > 0) ? <span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-progress-label">Progress:</span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-progress-value">{(item.infographic_progress_value.length > 0) ? item.infographic_progress_value[0].text : ""}</span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-progress-date-label">since:</span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-progress-date-value">{item.infographic_progress_date[0].text}</span></span> : ""}
                                    </div>
                                    <div key={`infographic-grid-item-wrapper-goal${index}`} className="af-portfolio-environmental-infographic-grid-item-wrapper-goal">
                                        {(item.infographic_goal_date.length > 0) ? <span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-goal-label">Goal:</span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-goal-value">{(item.infographic_goal_value.length > 0) ? item.infographic_goal_value[0].text : ""}</span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-goal-date-label">by:</span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-goal-date-value">{item.infographic_goal_date[0].text}</span></span> : ""}
                                    </div>
                                </div> :
                                ""
                        ))}
                    </div>
                    <div className="af-portfolio-environmental-infographic-data-wrapper-center-item-column-2">
                        {items !== "" && items.map((item, index) => (
           
                            (index > 2) ?
                                <div className="af-portfolio-environmental-infographic-data-wrapper-center-item-row">
                                    <img src={item.infographic_icon.url} /> 
                                    <h3
                                        key={`infographic-grid-item-header${index}`}
                                        className="af-portfolio-environmental-infographic-data-item-header"
                                    >
                                        {item.infographic_title[0].text}
                                    </h3>
                                    <div key={`infographic-grid-item-wrapper-progress${index}`} className="af-portfolio-environmental-infographic-grid-item-wrapper-progress">
                                        {(item.infographic_progress_date.length > 0) ? <span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-progress-label">Progress:</span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-progress-value">{(item.infographic_progress_value.length > 0) ? item.infographic_progress_value[0].text : ""}</span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-progress-date-label">since:</span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-progress-date-value">{item.infographic_progress_date[0].text}</span></span> : ""}
                                    </div>
                                    <div key={`infographic-grid-item-wrapper-goal${index}`} className="af-portfolio-environmental-infographic-grid-item-wrapper-goal">
                                        {(item.infographic_goal_date.length > 0) ? <span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-goal-label">Goal:</span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-goal-value">{(item.infographic_goal_value.length > 0) ? item.infographic_goal_value[0].text : ""}</span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-goal-date-label">by:</span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-goal-date-value">{item.infographic_goal_date[0].text}</span></span> : ""}
                                    </div>                                    
                                </div> :
                                ""
                        ))}
                        {items !== "" && items.map((item, index) => (
                            (items.length - 1 == index) ? <i className="af-portfolio-environmental-infographic-data-item-attention">*for community common areas</i> : ""
                        ))}
                    </div>
                </div>
                <div className="af-portfolio-environmental-infographic-data-wrapper-center-mobile">
                    <div className="af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-column">
                        {items !== "" && items.map((item, index) => (
                            <div className="af-portfolio-environmental-infographic-data-wrapper-center-mobile-item-row">
                                <img src={item.infographic_icon.url} />
                                <h3
                                    key={`infographic-grid-item-mobile-header${index}`}
                                    className="af-portfolio-environmental-infographic-data-mobile-item-header"
                                >
                                    {item.infographic_title[0].text}
                                </h3>
                                <div key={`infographic-grid-item-mobile-wrapper-progress${index}`} className="af-portfolio-environmental-infographic-grid-item-mobile-wrapper-progress">                                 
                                    {(item.infographic_progress_date.length > 0) ? <span><span className="af-portfolio-environmental-infographic-data-wrapper-mobile-center-item-progress-label">Progress:</span><span className="af-portfolio-environmental-infographic-data-wrapper-mobile-center-item-progress-value">{(item.infographic_progress_value.length > 0) ? item.infographic_progress_value[0].text : ""}</span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-progress-date-label">since:</span><span className="af-portfolio-environmental-infographic-data-wrapper-center-item-progress-date-value">{item.infographic_progress_date[0].text}</span></span> : ""}
                                </div>
                                <div key={`infographic-grid-item-mobile-wrapper-goal${index}`} className="af-portfolio-environmental-infographic-grid-item-mobile-wrapper-goal">
                                    {(item.infographic_goal_date.length > 0) ? <span><span className="af-portfolio-environmental-infographic-data-wrapper-mobile-center-item-goal-label">Goal:</span><span className="af-portfolio-environmental-infographic-data-wrapper-mobile-center-item-goal-value">{(item.infographic_goal_value.length > 0) ? item.infographic_goal_value[0].text : ""}</span><span className="af-portfolio-environmental-infographic-data-wrapper-mobile-center-item-goal-date-label">by:</span><span className="af-portfolio-environmental-infographic-data-wrapper-mobile-center-item-goal-date-value">{item.infographic_goal_date[0].text}</span></span> : ""}
                                </div>
                            </div> 
                        ))}
                        {items !== "" && items.map((item, index) => (
                            (items.length - 1 == index) ? <i className="af-portfolio-environmental-infographic-data-item-attention">*for community common areas</i> : ""
                        ))}
                    </div>
                </div>
                <div className="af-portfolio-environmental-infographic-data-wrapper-right">
                    <img src={infographic_desktop_right.url} />  
                </div>
            </infographic-grid-data>
            <infographic-right-image />
        </PortfolioEnvironmentalTargetsView>
    );
};
