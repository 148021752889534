import React from "react";
import get from "lodash/get";
import { graphql } from "react-apollo";
import { subregionRelatedBlogPostsQuery } from "../../graphql/queries/subregionRelatedBlogPostsQuery";
import CardsNewsView from "../../components/CardsNewsView";
import BackgroundImageHandler from "../../helpers/components-data-handlers/background-image-handler";
import NewsCardView from "../../components/NewsCardView";
import styles from './styles.module.css'
import './index.css'
function CardsNewsSubregion(props) {
  const image = get(props, "slice.primary.background_image");
  const title = get(props, "slice.primary.subregiontitlecardnews");
  const subtitle = get(props, "slice.primary.subtitlecardnews");
  const imageProps = BackgroundImageHandler(image);
  const posts = get(props, "data.subregionRelatedBlogPosts", []);
  if (!posts || !posts.length) {
    return null;
  }
  let subregionPosts = posts.filter(function (postItem) {
    try {
      if (postItem.blogPost.subregiontag !== null) {
        let substr = postItem.blogPost.subregiontag.toString();
        return substr.includes(props.page.uid);
      }
    } catch(ex) {
      //console.log("subregiontag in blogpost");
    }    
  });
  if (!subregionPosts || !subregionPosts.length) {
    subregionPosts = posts;
  }
  return (
    <CardsNewsView>
      <wrapper
        className={styles.wrapper}
        {...imageProps}
        topelement={() => <div className={styles.overlay} />}
      >
        <label>{title}</label>
        <sub-title>{subtitle}</sub-title>
        <newsCardViewList0>
          {subregionPosts.slice(0, 2).map((post, key) => (
            <NewsCardView.Controller key={`news-card-${key}`} data={post.blogPost} />
          ))}
        </newsCardViewList0>
      </wrapper>
    </CardsNewsView>
  );
}
export default graphql(subregionRelatedBlogPostsQuery, {
  skip: props =>
    !props.page,
  options: props => ({
    variables: {
        subregion: props.page.id
    }
  })
})(CardsNewsSubregion);