import React, { useState, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { graphql, compose } from 'react-apollo';
import get from 'lodash/get';
import { withRouter } from 'react-router';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { propertyGalleryQuery } from '../../graphql/queries';
import PropertyCardView from '../../components/PropertyCardView';
import styles from './styles.module.css';
import './index.css';
import MultiLineString from '../../helpers/mutli-line-string';
import { formatDollar } from '../../helpers/utils';
import usePhone from '../../helpers/hooks/usePhone';
import { addOrReplaceUrlParam, addQualityParam } from '../../helpers/add-or-replace-url-param';
import Info from '../../assets/icons/help.png';
import ToolTip from '../ToolTip/index';

const moment = require('moment-timezone');

const CarouselSettings = {
  showThumbs: false,
  showArrows: false,
  showIndicators: false,
  infiniteLoop: true,
  showStatus: false
};

function getClass(index, selected) {
  let dist = Math.abs(index - selected);
  if (dist > 2) {
    dist = 2;
  }
  return styles[`fade${dist}`];
}

const _renderConfiguration = (minBeds, maxBeds) => {
  if (minBeds === maxBeds) {
    return minBeds === 0 ? 'Studio' : `${minBeds} Bedrooms`;
  } else {
    return `${minBeds === 0 ? 'Studio' : `${minBeds} Bedrooms`} - ${maxBeds} Bedrooms`;
  }
};

const _renderPricing = minPrice => `Starting at $${formatDollar(minPrice, 0)}`;

const getImages = props => {
  const images = [
    ...get(props, 'gallery.exterior', [])
      .filter(img => !!img && !!img && !!img.image && !!img.image.url)
      .slice(0, 3),
    ...get(props, 'gallery.interior', [])
      .filter(img => !!img && !!img && !!img.image && !!img.image.url)
      .slice(0, 3)
  ];
  const mainImage = get(props, 'property.data.primary_property_image');
  if (!!mainImage) {
    images.unshift({
      image: mainImage
    });
  }
  return images;
};

const PropertyCard = props => {
  const [selected, setSelected] = useState(0);
  const data = get(props, 'data.data');
  const property = get(props, 'data');
  const gallery = get(props, 'propertyGalleryQuery.propertyGallery');
  const attributes = get(property, 'attributes');
  const [dniPhone, hrefPhone] = usePhone(get(data, 'phone'), get(data, 'entrataPropertyId'), props);
  const sliderRef = useRef(null);
  const showBedrooms = get(props, 'data.data.enable_pricing_availability_page', '');
  const preLeaseDate = get(props, 'data.data.pre-lease_start_date');
  const moveInDate = get(props, 'data.data.move_in_date');

  if (!data || !attributes) {
    return null;
  }

  const {
    name,
    address,
    phone,
    pets,
    smoke_free,
    leed_level,
    leed_level_target
    // logo,
  } = data;

  const images = getImages({ property: { data }, gallery });
  const resizeImages = () => {
    images.map(image => {
      if (sliderRef && sliderRef.current && sliderRef.current.getBoundingClientRect) {
        const { height, width } = sliderRef.current.getBoundingClientRect();
        const url = addOrReplaceUrlParam(image.image.url, 'w', width * 2.5);
        const new_url = addOrReplaceUrlParam(url, 'h', height * 2.5);
        image.image.url = new_url;
      }
      return image;
    });
  };
  resizeImages(images);

  const cat = pets === 'Yes';
  const dog = pets === 'Yes';
  const smoking = smoke_free === 'Yes';
  const leed = !!leed_level && !leed_level_target;

  const specials =
    (data.specials || []).filter(special =>
      moment
        .tz(special.expiration_date, 'MM/DD/YYYY', 'America/Chicago')
        .endOf('day')
        .isAfter(moment().tz('America/Chicago'))
    ).length > 0;

  const specialsList = (data.specials || []).filter(special =>
    moment
      .tz(special.expiration_date, 'MM/DD/YYYY', 'America/Chicago')
      .endOf('day')
      .isAfter(moment().tz('America/Chicago'))
  );

  const getRoute = property => {
    const page = get(props, 'page');
    const subregion = get(property, 'data.sub-regions[0].sub-region');

    if (!!page && subregion && page.type === 'region') {
      return `${props.location.pathname}/${subregion.uid}-apartments/${property.uid}`;
    } else if (!!page && subregion && page.type === 'sub-region') {
      return `/apartments/${get(page, 'data.region.uid')}/${subregion.uid}-apartments/${property.uid}`;
    }

    return `/${property.uid}`;
  };

  return (
    <PropertyCardView>
      <name className={styles.title} onClick={() => props.history.push(dedupeSlash(getRoute(property)))}>
        {name}
      </name>
      <address href={null}>{MultiLineString(address)}</address>
      {phone ? <phone href={hrefPhone}>{dniPhone}</phone> : null}
      <configuration>
        {showBedrooms === 'Yes' ? (
          _renderConfiguration(attributes.minBeds, attributes.maxBeds)
        ) : preLeaseDate ? (
          <p style={{ display: 'flex', flexDirection: 'column' }}>
            <span>Pre-Lease Start Date:</span>
            <span style={{ fontSize: '14px', color: '#787878' }}>{preLeaseDate}</span>
          </p>
        ) : null}
      </configuration>
      <pricing-container
        className={styles.pricingContainer}
        style={showBedrooms === 'No' ? { marginTop: 0 } : null}
        topelement={() => {
          if (showBedrooms === 'No') {
            return moveInDate ? (
              <div className="af-class-property-card-bedroom">
                <p style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>Move In Start Date:</span>
                  <span style={{ fontSize: '14px', color: '#787878' }}>{moveInDate}</span>
                </p>
              </div>
            ) : null;
          }
        }}
      >
        {showBedrooms === 'Yes' ? (
          <pricing-link
            className={styles.pricingLink}
            href={`${dedupeSlash(getRoute(property))}/floorplans`}
            tabIndex={0}
            onClick={e => {
              if (e && e.preventDefault) {
                e.preventDefault();
              }
              props.history.push(`${dedupeSlash(getRoute(property))}/floorplans`);
            }}
          >
            <pricing>
              <span style={{ paddingRight: '5px' }}>{_renderPricing(attributes.minPrice)}</span>
              <ToolTip
                toolTipElement={<img id="property_card_info" tabIndex={0} src={Info} alt="more info" className={styles.infoIcon} />}
                toolTipText={
                  <div className={styles.infoText}>
                    "Starting at” reflects pricing for the lowest rent offered of an available unit at the Community regardless of the number of
                    bedrooms. Unavailable units may come online with lower rent, so check back often.
                  </div>
                }
                isMobile={false}
                toolTipPosition={'top'}
              />
            </pricing>
          </pricing-link>
        ) : null}
      </pricing-container>
      {cat && <cat alt="cat" title="Cats Allowed" />}
      {dog && <dog alt="dog" title="Dogs Allowed" />}
      {leed && <leed alt="leed level" title="LEED Certified Community" />}

      {smoking && <smoking alt="smoking" title="Smoke-Free" />}
      <slider
        ref={sliderRef}
        className={styles.sliderContainer}
        topelement={() => (
          <>
            <Carousel {...CarouselSettings} selectedItem={selected} onClickItem={() => props.history.push(dedupeSlash(getRoute(property)))}>
              {(images || []).map((image, index) =>
                Math.abs(selected - index) < 3 ||
                (selected === 0 && index === images.length - 1) ||
                (images.length - 1 - selected < 3 && index === 0) ? (
                  <img
                    id={`property_images_${index}`}
                    src={image.image ? addQualityParam(image.image.url, 5) : addQualityParam(image.url, 5)}
                    alt={image.caption || (image.image ? image.image.alt || '' : '')}
                    className={styles.image}
                    style={preLeaseDate ? { maxHeight: '260px' } : {}}
                    key={`images_${index}`}
                  />
                ) : (
                  <span key={`image_span_${index}`} />
                )
              )}
            </Carousel>
            <div className={styles.dotContainer}>
              {images.map((image, index) => (
                <div
                  tabIndex={0}
                  key={`dot_${index}`}
                  onKeyDown={e => e.keyCode === 13 && setSelected(index)}
                  className={[styles.dot, getClass(index, selected)].join(' ')}
                />
              ))}
            </div>
          </>
        )}
      >
        <slider-left
          className={styles.arrows}
          alt="previous image"
          tabIndex={0}
          onKeyDown={e => e.keyCode === 13 && images && images.length && setSelected((selected + images.length - 1) % images.length)}
          onClick={() => {
            if (images && images.length) {
              setSelected((selected + images.length - 1) % images.length);
            }
          }}
        />
        <slider-right
          className={styles.arrows}
          alt="next image"
          tabIndex={0}
          onKeyDown={e => e.keyCode === 13 && images && images.length && setSelected((selected + 1) % images.length)}
          onClick={() => {
            if (images && images.length) {
              setSelected((selected + 1) % images.length);
            }
          }}
        />
        {specials && (
          <specials className={styles.specials} onClick={() => props.history.push(dedupeSlash(getRoute(property)))}>
            <ToolTip
              toolTipElement={
                <>
                  <div className="af-class-text-block">Current Special&nbsp;</div>
                  <img src="/images/icon-special-fire3x.svg" alt="specials icon" className={`af-class-icon-fire ${styles.fireIcon}`} />
                </>
              }
              toolTipText={specialsList.map(special => (
                <div>
                  <div className={styles.specialText}>{special.special_text}</div>
                  <br />
                  {/*<div className={styles.specialExpDate}>Expires {moment(special.expiration_date).format("MM/DD/YY")}</div>*/}
                </div>
              ))}
              isMobile={false}
            />
          </specials>
        )}
      </slider>
    </PropertyCardView>
  );
};

export default withRouter(
  compose(
    graphql(propertyGalleryQuery, {
      name: 'propertyGalleryQuery',
      skip: props => !props || !props.data || !props.data.id,
      options: props => ({
        variables: {
          id: props.data.id
        }
      })
    })
  )(PropertyCard)
);

function dedupeSlash(path) {
  return path.replace(/([^:]\/)\/+/g, '$1');
}
