import get from 'lodash/get';
import { default as moment } from 'moment-timezone';
import React, { useState, useEffect, useRef } from 'react';

import PropertyComparisonFilter from '../PropertyComparisonFilter';
import CollapsibleSection from '../CollapsibleSection';
import ImageSlider from '../ImageSlider';
import PropertyComparisonAmenitiesSection from '../PropertyComparisonAmenitiesSection';
import PropertyComparisonOverview from '../PropertyComparisonOverview';
import PropertyComparisonMobilePropertyCard from '../PropertyComparisonMobilePropertyCard';
import { mapImagesFromProperty } from './../../helpers/utils';

import styles from './styles.module.css';

const PropertyComparison = props => {
  const { subregionUID, properties, selectedProperties, setSelectedProperties, mobileSelectedProperty, setMobileSelectedProperty } = props;

  const subregionTitle = typeof subregionUID === 'string' ? subregionUID.replaceAll('-', ' ') : null;
  const pageType = get(props, 'page.type');
  const pageUID = get(props, 'page.uid');

  if ((properties && properties.length < 2) || pageType !== 'sub-region') return <></>;

  const communityAmenitiesToDisplay = get(props, 'page.data.community_amenities_to_display', []).map(amenity => {
    return amenity && typeof amenity.feature_type ? amenity.amenity_type : null;
  });
  const apartmentFeaturesToDisplay = get(props, 'page.data.property_features_to_display', []).map(feature => {
    return feature && typeof feature.feature_type ? feature.feature_type : null;
  });

  // handlers for retrieving window size
  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // slides state and handlers
  const slidesTotal = Array.isArray(selectedProperties)
    ? windowSize.innerWidth <= 720
      ? selectedProperties.length - 1
      : selectedProperties.length - (windowSize.innerWidth <= 1084 ? 2 : 3)
    : null;

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideIncrement = () => {
    if (currentSlide < slidesTotal) return setCurrentSlide(currentSlide + 1);
    setCurrentSlide(0);
  };

  const handleSlideDecrement = () => {
    if (currentSlide > 0) return setCurrentSlide(currentSlide - 1);
    setCurrentSlide(slidesTotal);
  };

  const slideState = {
    transition: 'transform 200ms',
    transform:
      currentSlide === 0 || windowSize.innerWidth <= 720
        ? 'unset'
        : windowSize.innerWidth <= 1084
        ? `translateX(calc(((100% + 2em) / -2) * ${currentSlide}))`
        : `translateX(calc(-${33 * currentSlide}vw + ${10.63 * currentSlide}em + ${-100 * currentSlide}px ))`
  };

  useEffect(() => {
    if (slidesTotal === selectedProperties.length - 1) {
      setMobileSelectedProperty(currentSlide);
    }
  }, [currentSlide]);

  useEffect(() => {
    if (slidesTotal === selectedProperties.length - 1) {
      setCurrentSlide(mobileSelectedProperty);
    }
  }, [mobileSelectedProperty]);

  useEffect(() => {
    if (windowSize.innerWidth <= 720 && currentSlide !== mobileSelectedProperty) {
      setCurrentSlide(mobileSelectedProperty);
    }
    setCurrentSlide(0);
  }, [selectedProperties]);

  // handlers for adjusting table row height
  const communityRef = useRef(null);
  const aparmentRef = useRef(null);

  const [communityMounted, setCommunityMounted] = useState(0);
  const [apartmentMounted, setapartmentMounted] = useState(0);

  const handleMaxHeightOfTableRow = (ref, toDisplay) => {
    const propertyStatWrappers = Array.from(ref.current.querySelectorAll(`div`));

    toDisplay.forEach((amenityType, amenityTypeIndex) => {
      const maxHeight = propertyStatWrappers
        .map(propertyStatWrapper => {
          return propertyStatWrapper.querySelectorAll('li')[amenityTypeIndex].offsetHeight + 1;
        })
        .reduce((currentMax, height) => {
          return currentMax < height ? height : currentMax;
        });

      propertyStatWrappers.forEach(propertyStatWrapper => {
        propertyStatWrapper.querySelectorAll('li')[amenityTypeIndex].style.minHeight = `${maxHeight}px`;
      });
    });
  };

  const [recalculateRowHeight, setRecalculateRowHeight] = useState(0);

  useEffect(() => {
    handleMaxHeightOfTableRow(communityRef, communityAmenitiesToDisplay);
  }, [communityMounted, recalculateRowHeight, selectedProperties]);

  useEffect(() => {
    handleMaxHeightOfTableRow(aparmentRef, apartmentFeaturesToDisplay);
  }, [apartmentMounted, recalculateRowHeight, selectedProperties]);

  useEffect(() => {
    Array.from(communityRef.current.querySelectorAll('li')).forEach(item => (item.style.minHeight = ''));
    Array.from(aparmentRef.current.querySelectorAll('li')).forEach(item => (item.style.minHeight = ''));
    setRecalculateRowHeight(recalculateRowHeight + 1);
  }, [windowSize, currentSlide]);

  // look ahead and look behind for selected property
  const mobilePrevIndex = mobileSelectedProperty !== 0 ? mobileSelectedProperty - 1 : selectedProperties.length - 1;
  const mobileNextIndex = mobileSelectedProperty !== selectedProperties.length - 1 ? mobileSelectedProperty + 1 : 0;

  const formatAddress = address => {
    const splitAddress = address.split('\n');
    return (
      <>
        {splitAddress[0]}
        <br style={{ display: 'block' }} />
        {splitAddress[1]}
      </>
    );
  };

  const getFullRoute = property => {
    // Calculate the full route to the property page
    // We need to replace the page UID with the subregion UID incase the property is in multiple subregions...
    // ...and the current subregion is not the subregion used for the propertie's full link
    const subregionUID = get(property, 'data.sub-regions[0].sub-region.uid');
    const path = `${window.location.pathname.replace(pageUID, subregionUID)}/${property.uid}`;
    return path;
  };

  return (
    <div id="property-comparison" className={styles.comparisonWrapper}>
      {windowSize.innerWidth <= 720 || selectedProperties.length <= (windowSize.innerWidth <= 1084 ? 2 : 3) ? null : (
        <div className={styles.decrementArrow + ' ' + styles.slideArrow} onClick={handleSlideDecrement} />
      )}

      <section className={'af-class-wrapper ' + styles.comparisonSection}>
        <h2 className={styles.comparisonTitle}>
          <span className={styles.capitalize}>{subregionTitle}</span> Apartments Comparison
        </h2>

        {properties.length <= 2 ? null : (
          <PropertyComparisonFilter
            {...{
              properties,
              selectedProperties,
              setSelectedProperties,
              setMobileSelectedProperty
            }}
          />
        )}

        <div style={{ display: 'flex' }}>
          <PropertyComparisonMobilePropertyCard data={selectedProperties[mobileSelectedProperty]} />
          <PropertyComparisonMobilePropertyCard data={selectedProperties[mobileNextIndex]} />
        </div>

        {selectedProperties.length > 2 ? (
          <div className={styles.mobileControls}>
            <div className={styles.mobileControl} onClick={handleSlideDecrement}>
              <span className={styles.mobilePrevArrow}></span>
              <span className={styles.mobileControlName}>{selectedProperties[mobilePrevIndex].uid.replaceAll('-', ' ').replace('amli', 'AMLI')}</span>
            </div>
            <div className={styles.mobileControl} onClick={handleSlideIncrement}>
              <span className={styles.mobileControlName}>
                {selectedProperties[mobileNextIndex + 1 === selectedProperties.length ? 0 : mobileNextIndex + 1].uid
                  .replaceAll('-', ' ')
                  .replace('amli', 'AMLI')}
              </span>
              <span className={styles.mobileNextArrow}></span>
            </div>
          </div>
        ) : null}

        <div className={styles.mobileNavBar}>
          <a className={styles.mobileNavLink} href={`#${styles['overview']}`}>
            Overview
          </a>
          {communityAmenitiesToDisplay.length > 0 ? (
            <a className={styles.mobileNavLink} href="#communityAmenities">
              Community Amenities
            </a>
          ) : null}
          {apartmentFeaturesToDisplay.length > 0 ? (
            <a className={styles.mobileNavLink} href="#apartmentFeatures">
              Apartment Features
            </a>
          ) : null}
        </div>

        {windowSize.innerWidth <= 720 ? (
          <div className={styles.followingTitle}>
            <p
              className={styles.propertyMobileTitle}
              style={{
                backgroundColor: selectedProperties[mobileSelectedProperty].data.primary_color
              }}
            >
              {selectedProperties[mobileSelectedProperty].data.name
                ? selectedProperties[mobileSelectedProperty].data.name
                : selectedProperties[mobileSelectedProperty].uid
                ? selectedProperties[mobileSelectedProperty].uid.replaceAll('-', ' ').replaceAll('amli', 'AMLI')
                : 'AMLI Property'}
            </p>
            <p className={styles.propertyMobileTitle} style={{ backgroundColor: selectedProperties[mobileNextIndex].data.primary_color }}>
              {selectedProperties[mobileNextIndex].data.name
                ? selectedProperties[mobileNextIndex].data.name
                : selectedProperties[mobileNextIndex].uid
                ? selectedProperties[mobileNextIndex].uid.replaceAll('-', ' ').replaceAll('amli', 'AMLI')
                : 'AMLI Property'}
            </p>
          </div>
        ) : null}

        <div style={{ overflow: 'hidden', width: '100%', padding: 0 }}>
          <div className={styles.galleriesWrapper} style={slideState}>
            {selectedProperties.map(property => {
              const address = get(property, 'data.address');
              const phone = get(property, 'data.phone');
              const specials = get(property, 'data.specials', []).filter(special => {
                return moment
                  .tz(special.expiration_date, 'MM/DD/YYYY', 'America/Chicago')
                  .endOf('day')
                  .isAfter(moment().tz('America/Chicago'));
              });

              return (
                <div className={styles.propertyGallery} key={property.id}>
                  <a href={getFullRoute(property)}>
                    <h3 className={styles.propertyTitle + ' ' + styles.capitalize}>
                      {property.data && property.data.name
                        ? property.data.name
                        : property.uid
                        ? property.uid.replaceAll('-', ' ').replaceAll('amli', 'AMLI')
                        : 'AMLI Property'}
                      {property.data.currently_leasing === 'No' ? (
                        <span className={styles.propertyDisclaimer}>{'Coming ' + property.data.move_in_date}</span>
                      ) : null}
                    </h3>
                  </a>
                  <p className={styles.propertyGalleryAddress}>{formatAddress(address)}</p>
                  {phone ? (
                    <a href={phone ? `tel:+${phone.replace(/[)(-]/g, '')}` : null}>
                      <p className={styles.propertyGalleryPhone}>{phone}</p>
                    </a>
                  ) : null}
                  <div className={styles.sliderContainer}>
                    {specials.length > 0 ? (
                      <a href={getFullRoute(property)}>
                        <div className={styles.specials}>
                          <img src="/images/icon-special-fire3x.svg" alt="icon for specials" />
                          <span>Current Special</span>
                          <div className={styles.specialModal}>
                            <p className={styles.specialText}>{specials[0].special_text}</p>
                            {/*<p className={styles.specialExpiration}>{`Expires: ${specials[0].expiration_date}`}</p>*/}
                          </div>
                        </div>
                      </a>
                    ) : null}

                    <ImageSlider className={styles.imageGallery} images={mapImagesFromProperty(property)} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <CollapsibleSection startOpen={true} sectionTitle="Overview" id={styles['overview']}>
          <div className={styles.comparisonTable} style={slideState}>
            {windowSize.innerWidth > 720 ? (
              selectedProperties.map(property => {
                return <PropertyComparisonOverview windowSize={windowSize} property={property} key={property.id} link={getFullRoute(property)} />;
              })
            ) : (
              // render mobile
              <>
                <PropertyComparisonOverview
                  link={getFullRoute(selectedProperties[mobileSelectedProperty])}
                  property={selectedProperties[mobileSelectedProperty]}
                />
                <PropertyComparisonOverview
                  link={getFullRoute(selectedProperties[mobileSelectedProperty])}
                  property={selectedProperties[mobileSelectedProperty === selectedProperties.length - 1 ? 0 : mobileSelectedProperty + 1]}
                  mobileRight={true}
                />
                =
              </>
            )}
          </div>
        </CollapsibleSection>

        {communityAmenitiesToDisplay.length > 0 ? (
          <CollapsibleSection sectionTitle="Community Amenities" id="communityAmenities">
            <div ref={communityRef} className={styles.comparisonTable} style={slideState}>
              {windowSize.innerWidth > 720 ? (
                selectedProperties.map(property => {
                  return (
                    <PropertyComparisonAmenitiesSection
                      property={property}
                      targetChild="COMMUNITY AMENITIES"
                      amenitiesToDisplay={communityAmenitiesToDisplay}
                      link={getFullRoute(property)}
                      key={property.id}
                      setMountedCallback={() => setCommunityMounted(communityMounted + 1)}
                    />
                  );
                })
              ) : (
                // render mobile
                <>
                  <PropertyComparisonAmenitiesSection
                    property={selectedProperties[mobileSelectedProperty]}
                    targetChild="COMMUNITY AMENITIES"
                    amenitiesToDisplay={communityAmenitiesToDisplay}
                    setMountedCallback={() => setCommunityMounted(communityMounted + 1)}
                    link={getFullRoute(selectedProperties[mobileSelectedProperty])}
                  />
                  <PropertyComparisonAmenitiesSection
                    property={selectedProperties[mobileSelectedProperty === selectedProperties.length - 1 ? 0 : mobileSelectedProperty + 1]}
                    targetChild="COMMUNITY AMENITIES"
                    amenitiesToDisplay={communityAmenitiesToDisplay}
                    setMountedCallback={() => setCommunityMounted(communityMounted + 1)}
                    link={getFullRoute(selectedProperties[mobileSelectedProperty === selectedProperties.length - 1 ? 0 : mobileSelectedProperty + 1])}
                  />
                </>
              )}
            </div>
          </CollapsibleSection>
        ) : null}

        {apartmentFeaturesToDisplay.length > 0 ? (
          <CollapsibleSection sectionTitle="Apartment Features" id="apartmentFeatures">
            <div ref={aparmentRef} className={styles.comparisonTable} style={slideState}>
              {windowSize.innerWidth > 720 ? (
                selectedProperties.map(property => {
                  return (
                    <PropertyComparisonAmenitiesSection
                      property={property}
                      targetChild="APARTMENT FEATURES"
                      amenitiesToDisplay={apartmentFeaturesToDisplay}
                      key={property.id}
                      setMountedCallback={() => setapartmentMounted(apartmentMounted + 1)}
                      link={getFullRoute(property)}
                    />
                  );
                })
              ) : (
                // render mobile
                <>
                  <PropertyComparisonAmenitiesSection
                    property={selectedProperties[mobileSelectedProperty]}
                    targetChild="APARTMENT FEATURES"
                    amenitiesToDisplay={apartmentFeaturesToDisplay}
                    setMountedCallback={() => setapartmentMounted(apartmentMounted + 1)}
                    link={getFullRoute(selectedProperties[mobileSelectedProperty])}
                  />
                  <PropertyComparisonAmenitiesSection
                    property={selectedProperties[mobileSelectedProperty === selectedProperties.length - 1 ? 0 : mobileSelectedProperty + 1]}
                    targetChild="APARTMENT FEATURES"
                    amenitiesToDisplay={apartmentFeaturesToDisplay}
                    setMountedCallback={() => setapartmentMounted(apartmentMounted + 1)}
                    link={getFullRoute(selectedProperties[mobileSelectedProperty === selectedProperties.length - 1 ? 0 : mobileSelectedProperty + 1])}
                  />
                </>
              )}
            </div>
            <div className={styles.selectApartmentsDisclaimer}>* in select apartments</div>
          </CollapsibleSection>
        ) : null}
      </section>
      {windowSize.innerWidth <= 720 || selectedProperties.length <= (windowSize.innerWidth <= 1084 ? 2 : 3) ? null : (
        <div className={styles.incrementArrow + ' ' + styles.slideArrow} onClick={handleSlideIncrement} />
      )}
    </div>
  );
};

export default PropertyComparison;
