import gql from "graphql-tag";

export const propertyGalleryQuery = gql`
  query propertyGallery($id: ID) {
    propertyGallery(id: $id) {
      interior
      exterior
      videos
    }
  }
`;
