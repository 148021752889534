import React from "react";
import Helmet from "react-helmet-async";
import get from "lodash/get";
import ExtractMeta from "../helpers/extract-meta";
import BuildCanonicalLink from "../helpers/build-canonical-link";

export default props => {
  const conLink = BuildCanonicalLink(props, 'default:default');
  const meta = ExtractMeta(get(props, "page"), conLink);
  return (
    <Helmet>
      {meta}
    </Helmet>
  );
};
