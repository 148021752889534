import gql from "graphql-tag";

export const locationScoreQuery = gql`
  query locationScore(
    $format: String,
    $address: String,
    $lat: Float,
    $lon: Float,
    $transit: Int,
    $bike: Int
  ) {
    locationScore(
      format: $format,
      address: $address,
      lat: $lat,
      lon: $lon,
      transit: $transit,
      bike: $bike
    ) {
      status
      walkscore
      description
      updated
      logo_url
      more_info_icon
      more_info_link
      ws_link
      help_link
      snapped_lat
      snapped_lon
      transit {
        description
        summary
        score
      }
      bike {
        description
        score
      }
    }
  }
`;
