import React from "react";
import get from "lodash/get";
import styles from "./styles.module.css";

const InteractiveToggle = props => {
  const { property, showInteractive, setShowInteractive, showEngrain, engrainId } = props;

  const primaryColor = get(property, "data.primary_color", "");
  if (!showEngrain || !engrainId) {
    return null;
  }
  return (
      <div className={styles.interactiveWrapper}>
        <div
          className={styles.toggleWrapper}
          onClick={() => setShowInteractive(!showInteractive)}
        >
          <span
            className={[styles.toggleText, !showInteractive && 'activeText'].join(" ")}
          >
            View Floor Plans
          </span>
          <span
            className={[styles.toggleText, showInteractive && 'activeText'].join(" ")}
          >
            View Interactive Map
          </span>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html: `\n.activeText {\n  background: ${primaryColor};\n color: white`
          }}
        />
      </div>
  )
}

export default InteractiveToggle;
