import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.css';
import { get, xor } from 'lodash';
import icon from '../../assets/icons/arrow-chevron.svg';
import Checkbox from '../FloorplanHeaderFiltersController/checkbox';
import { getPersonaColor } from '../../helpers/property/persona';
import GetUnitTypes from '../../helpers/components-data-handlers/get-unit-types';

const toggle = (array, item) => xor(array, [item]);
let listCurrentState = [];

function BedroomFilters(props) {
  let bedList = [];

  const hasSize = () => {
    return props.location.search.includes('size');
  };

  const getChecked = (size, _localFilters) => {
    let urlFilter = hasSize();

    if (urlFilter && [`${size}`] == _localFilters) {
      return [`${size}`];
    }
    if (_localFilters) {
      return _localFilters;
    }
    return [];
  };

  const [size, setSize] = useState(props.filters.size);

  if (hasSize()) {
    if (size.toString()) {
      props.filters.bedTypes = [`${size}`];
    }
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [localFilters, setLocalFilters] = useState(props.filters.bedTypes);

  const { property, propertyFloorplansSummary, filters, setFilters } = props;

  let path = props.location.pathname;

  let skipCode = false;

  useEffect(() => {
    setSize(props.filters.size);
  });

  useEffect(() => {
    if (props.filters.closeBedType) {
      setDropdownOpen(false);
    }

    setFilters({
      ...filters,
      closeHomeType: false,
      closeBedType: false,
      closeBathType: false,
    });

    skipCode = true;
  }, [props.filters.closeBedType]);

  useEffect(() => {
    const filteredValues = {
      dropDownID: 'bedroomFilters',
      dropDownValues: localFilters,
    };

    setFilters({
      ...filters,
      bedTypes: localFilters,
      filteredValues: filteredValues,
    });
  }, [localFilters]);

  useEffect(() => {
    if (!skipCode) {
      setFilters({ ...filters, closeHomeType: true, closeBathType: true });
    }
  }, [dropdownOpen]);

  const floorplanData = get(propertyFloorplansSummary, 'propertyFloorplansSummary', []);

  const convertBedsToCommonObject = (bedList) => {
    const bedListEntries = Object.entries(bedList);

    let availableBeds = [];
    let newList = [];

    if (floorplanData.length > 0) {
      floorplanData.map((fp) => {
        if (!availableBeds.includes(fp.bedroomMax)) {
          availableBeds.push(fp.bedroomMax);
        }
      });
    }

    if (availableBeds.length > 0) {
      bedListEntries.map((item) => {
        if (availableBeds.includes(parseInt(item[0]))) {
          newList.push({
            label: item[1],
            value: [item[0]],
          });
        }
      });
    }

    return newList;
  };

  bedList = props.beds;
  bedList = convertBedsToCommonObject(bedList);

  const filterDropDownList = (bedListUnFiltered) => {
    let filteredBedList = [];

    if (props.filters.filteredValues && props.filters.filteredValues.dropDownID && props.filters.filteredValues.dropDownValues.length > 0) {
      floorplanData.map((floorplan) => {
        switch (props.filters.filteredValues.dropDownID) {
          case 'additionalFilters':
            if (floorplan.units) {
              floorplan.units.map((floorplanUnit) => {
                if (floorplanUnit.unitTypeDesignation == props.filters.filteredValues.dropDownValues[0]) {
                  bedListUnFiltered.map((bed) => {
                    if (floorplan.bedroomMax == bed.value[0]) {
                      if (!filteredBedList.includes(bed)) {
                        filteredBedList.push(bed);
                      }
                    }
                  });
                }
              });
            }

            break;

          case 'bathroomFilters':
            props.filters.filteredValues.dropDownValues.map((bathroomValue) => {
              if (floorplan.bathroomMin == bathroomValue) {
                bedListUnFiltered.map((bed) => {
                  if (floorplan.bedroomMax == bed.value[0]) {
                    if (!filteredBedList.includes(bed)) {
                      filteredBedList.push(bed);
                    }
                  }
                });
              }
            });

            break;
        }
      });

      if (filteredBedList.length == 0) {
        bedList = props.beds;
        filteredBedList = convertBedsToCommonObject(bedList);
      }
    } else {
      bedList = props.beds;
      filteredBedList = convertBedsToCommonObject(bedList);
    }

    return filteredBedList;
  };

  if (props.filters.filteredValues.dropDownID == 'bedroomFilters') {
    bedList = listCurrentState;
  } else {
    bedList = filterDropDownList(bedList);

    listCurrentState = bedList;
  }

  if (!bedList || !bedList.length) {
    return null;
  }

  return (
    <div
      id="bedroomFilters"
      style={{ position: 'relative' }}
      tabIndex={-1}
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setDropdownOpen(false);
        }
      }}
    >
      <div className={styles.filtersDropdown} onClick={() => setDropdownOpen(!dropdownOpen)}>
        <span style={{ fontSize: 16 }}>Bedrooms</span>
        <img src={icon} alt="dropdown arrow" className={dropdownOpen ? styles.dropdownOpen : styles.dropdownClosed} />
      </div>
      {dropdownOpen && (
        <div className={styles.dropdownContainer}>
          {/*<p style={{ fontSize: 12 }}>HOME TYPE</p>*/}
          {bedList.map((type, index) => {
            return (
              <Checkbox
                key={`${type.value.join('-')}-${index}`}
                label={type.label}
                isDisabled={hasSize() ? !getChecked(size, localFilters).includes(type.value.join(',')) : false}
                onChange={async (e) => {
                  const newBeds = toggle(localFilters, type.value.join(','));
                  if (hasSize()) {
                    // Remove the size from the path
                    props.history.push(path);
                    // Set the new filters with newBeds
                    setLocalFilters(newBeds);
                  } else {
                    setLocalFilters(newBeds);
                  }
                }}
                isChecked={getChecked(size, localFilters).includes(type.value.join(','))}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default BedroomFilters;
