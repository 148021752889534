import queryString from "query-string";

export const addOrReplaceUrlParam = (url, paramName, paramValue) => {
  let wParam = queryString.parse(url);
  if (wParam.w) {
    return url
    .replace(new RegExp("([?&]"+paramName+"(?=[=&#]|$)[^#&]*|(?=#|$))"), "&"+paramName+"="+encodeURIComponent(paramValue))
    .replace(/^([^?&]+)&/, "$1?")
  } else {

      if (url == undefined)
          return "";

    return url.toString().concat(`&${paramName}=${paramValue}`)
  }
}
export const addQualityParam = (url, value) => {
  if (url.includes('?')) {
    return url.concat(`&q=${value}`);
  }
  return url.concat(`?q=${value}`);
}

export const setQueryParameters = (url, queryParameters = { auto: 'compress,format' }) => {
    const strippedUrl = url.replace(/\?.*/i, '');
    let queryParamsArray = [];
    for (const query in queryParameters) {
        queryParamsArray.push(String(query) + '=' + String(queryParameters[query]));
    }
    return strippedUrl + '?' + queryParamsArray.join('&');
}
