import gql from "graphql-tag";

export const regionPropertyAttributesQuery = gql`
  query regionPropertyAttributes($id: String!) {
    regionPropertyAttributes(id: $id) {
      minPrice
      maxPrice
      minBeds
      maxBeds
      reviews
      availabeUnitCount
      availablePercent
      propertyTag
      displayOrder
      propertyId
    }
  }
`;
