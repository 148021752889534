import { useEffect } from 'react';
import get from 'lodash/get';
import { canUseDOM } from './utils';

const EliseChat = props => {
  const shouldDisplayWidget = get(props, 'data.show_elisechat_pixel');
  const buildingCode = get(props, 'data.elisechat_building_code');

  useEffect(() => {
    if (canUseDOM && shouldDisplayWidget && buildingCode) {
      const script = window.document.createElement('script');
      script.setAttribute('type', 'module');
      script.innerHTML = `
      import MEChat from 'https://cdn.skypack.dev/@meetelise/chat';
      MEChat.start({
      organization: "amli",
      building: "${buildingCode}",
      });`;
      document.body.appendChild(script);
      return () => {
        document.querySelector('.meetelise-chat').remove();
        document.body.removeChild(script);
      };
    }
  }, [canUseDOM, shouldDisplayWidget, buildingCode]);

  return null;
};

export default EliseChat;
