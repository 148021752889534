import PrismicDom from "prismic-dom";

export const htmlSerializerFunc = (linkColor) => {

    const Elements = PrismicDom.RichText.Elements;
    
    const htmlSerializer = function (type, element, content, children) {
        switch(type) {
            // use secondary color and underline for blurb hyperlinks
            case Elements.hyperlink: 
                if (element.data.link_type !== 'Web') return null;
                const newLink = `<a style="color: ${linkColor}; text-decoration: underline;" target='${element.data.target}' rel='noopener' href='${element.data.url}'>${children.join('')}</a>`;
                return newLink;
        
            // Return null to stick with the default behavior
            default: 
                return null;
        }
    };

    return htmlSerializer;
};
