import gql from "graphql-tag";

export const entrataPhoneQuery = gql`
  query entrataPhone($entrataId: String, $switchCode: String, $referralSite: String) {
    entrataPhone(entrataId: $entrataId, switchCode: $switchCode, referralSite: $referralSite) {
      phone
    }
  }
`



