import React, { useState, useEffect, useRef } from "react";
import get from "lodash/get";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ListAmenitiesWithReversedGalleryView from "../../components/ListAmenitiesWithReversedGalleryView";
import Helmet from "react-helmet-async";
import { canUseDOM } from "../../helpers/utils";
import { addOrReplaceUrlParam, addQualityParam } from "../../helpers/add-or-replace-url-param";

import styles from "./style.module.css";
import { personaBackgroundColorStyle } from "../../helpers/property/persona";
import { LazyLoaderElement, LazyLoader } from "../../helpers/lazy-loader";

import TourModal from '../TourModalController';
import { validateUrl } from "../../helpers/link-helper";

const settings = {
    showThumbs: false,
    showArrows: false,
    showIndicators: false,
    infiniteLoop: true,
    showStatus: false,
    centerMode: true,
    centerSlidePercentage: 90,
    swipeScrollTolerance: 20
};

function getClass(index, selected) {
    let dist = Math.abs(index - selected);
    if (dist > 2) {
        dist = 2;
    }
    return styles[`fade${dist}`];
}

let selectedIndex = 0;

function ListAmenitiesWithReversedGallery(props) {
    const [selected, setSelected] = useState(0);
    const sliderRef = useRef(null);
    const title = get(props, "slice.primary.title", "");
    const subtitle = get(props, "slice.primary.subtitle", "");

    const [showTour, setShowTour] = useState(false);
    const [playTour, setPlayTour] = useState(false);
    const [selectedTour, setSelectedTourItem] = useState(0);

    const tours = get(props, 'property.data.3d_tours', []).filter((tour) => !!tour && !!tour.enabled && tour.enabled === 'True');

    const primaryColor = get(props, "property.data.primary_color");

    const amenities = get(props, "slice.items", []).filter(
        amn => !!amn && !!amn.image && !!amn.image.url
    );

    const features = get(props, "slice.items", []).filter(
        am => !!am && !!am.amenity
    );
    const images = amenities.filter(am => !!am && !!am.image && !!am.image.url);
    if (!images || !images.length) {
        return null;
    }
    const property = get(props, "property") || get(props, "page.data");
    const showDisclaimer = get(props, "slice.primary.show_disclaimer");

    useEffect(() => {
        resizeImages();
    }, [amenities])

    useEffect(() => {
        let lazyLoaderElementList = [];

        images.map((amenity, index) => {
            //#region SETUP lazy loading

            let lazyLoaderElementItem = new LazyLoaderElement();

            //STORE images TO lazy load
            lazyLoaderElementItem.Id = `amenity-reverse-carousel-image-${index}`;
            lazyLoaderElementItem.Src = addQualityParam(amenity.image.url, 5);

            lazyLoaderElementList.push(lazyLoaderElementItem);

            //#endregion
        });

        const lazyLoader = new LazyLoader();
        lazyLoader.LazyLoad(lazyLoaderElementList);
    }, [])

    const resizeImages = () => {
        images.map(image => {
            if (
                sliderRef &&
                sliderRef.current &&
                sliderRef.current.getBoundingClientRect
            ) {
                const { width } = sliderRef.current.getBoundingClientRect();
                const imageWidth = width > 500 ? width * 1.5 : width * 2;
                const url = addOrReplaceUrlParam(image.image.url, 'w', imageWidth)
                const new_url = addOrReplaceUrlParam(url, 'h', 'auto')
                image.image.url = new_url
            }
            return image
        })
    }

    const showHideToolTip = (elementId, elementToolTipId, elementToolTipShow) => {
        const element = document.getElementById(elementId);
        const elementToolTip = document.getElementById(elementToolTipId);

        if (element && elementToolTip) {

            if (elementToolTipShow) {
                element.style.cursor = 'pointer';
                //elementToolTip.className = styles.amenitityToolTipShow;
            }
            else {
                element.style.cursor = 'default';
                //elementToolTip.className = styles.amenitityToolTipHide;
            }

        }
    }

    const setSelectedTour = (index) => {
        selectedIndex = index;

        setShowTour(true);
    }

    const closeTour = () => {
        setShowTour(false);
        setPlayTour(false);
    }

    return (
        <React.Fragment>
            <Helmet>
                <script type="application/ld+json">
                    {`
            {
              "@context": "http://schema.org/",
              "@type": "ApartmentComplex",
              "name": "${property.data.name}",
              "amenityFeature": [
              ${features.map(feature => (
                        `{
                  "@type": "LocationFeatureSpecification",
                  "description": "${feature.amenity}"
                  }
                `
                    ))}
            ]}
          `}
                </script>
            </Helmet>
            <ListAmenitiesWithReversedGalleryView>
                <header-container style={personaBackgroundColorStyle(property)}>
                    <title>{title}</title>
                    <subtitle>{subtitle}</subtitle>
                </header-container>
                <gallery className={`${showDisclaimer === "Yes" ? styles.gallery : ''}`}>
                    <slider id="amenities-reverse-carousel" className={styles.sliderWrapper} ref={sliderRef}>
                        <Carousel
                            {...settings}
                            selectedItem={selected}
                            onChange={e => setSelected(e)}
                        >
                            {images.map((amenity, index) =>
                                !!amenity && !!amenity.image && !!amenity.image.url ? (
                                    <div key={`amenity_${index}`}>
                                        <img
                                            id={`amenity-reverse-carousel-image-${index}`}
                                            src={addQualityParam(amenity.image.url, 5)}
                                            alt={amenity.image.image_alt_text || ""}
                                            className={styles.image}
                                        />
                                    </div>
                                ) : null
                            )}
                        </Carousel>
                        <div
                            className={`af-class-amenities-slider-toolbar ${styles.toolbarReversed
                                }`}
                        >
                            <div
                                className={["af-class-html-embed-8", styles.left].join(" ")}
                                tabIndex={0}
                                onKeyDown={e =>
                                    e.keyCode === 13 &&
                                    setSelected(
                                        (selected + amenities.length - 1) % amenities.length
                                    )
                                }
                                onClick={() =>
                                    setSelected(
                                        (selected + amenities.length - 1) % amenities.length
                                    )
                                }
                            >
                                <style
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            "\n .af-class-arrow-l-chevron {\n  fill:#4c698f;\n  fill-rule: evenodd;\n}\n"
                                    }}
                                />
                                <svg
                                    data-name="Layer 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 13.13 22.02"
                                >
                                    <title>Arrow-Chevron</title>
                                    <g>
                                        <polygon
                                            points="13.13 2.2 10.94 0 0 11.01 10.94 22.02 13.13 19.82 4.44 11.01 13.13 2.2"
                                            className="af-class-arrow--chevron"
                                        />
                                    </g>
                                </svg>
                            </div>
                            {amenities
                                .filter(amn => !!amn && !!amn.image && !!amn.image.url)
                                .map((item, index) => (
                                    <button
                                        aria-label={`slide ${index + 1}`}
                                        key={`dot-${index}`}
                                        tabIndex={0}
                                        onKeyDown={e => e.keyCode === 13 && setSelected(index)}
                                        className={[styles.dot, getClass(index, selected)].join(" ")}
                                        style={personaBackgroundColorStyle(property)}
                                    />
                                ))}
                            <div
                                className={["af-class-html-embed-9", styles.right].join(" ")}
                                tabIndex={0}
                                onKeyDown={e =>
                                    e.keyCode === 13 &&
                                    setSelected((selected + 1) % amenities.length)
                                }
                                onClick={() => setSelected((selected + 1) % amenities.length)}
                            >
                                <style
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            "\n .af-class-arrow--chevron {\n  fill: #4c698f;\n  fill-rule: evenodd;\n}\n"
                                    }}
                                />
                                <svg
                                    data-name="Layer 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 13.13 22.02"
                                >
                                    <g>
                                        <polygon
                                            points="0 19.82 2.19 22.02 13.13 11.01 2.19 0 0 2.2 8.68 11.01 0 19.82"
                                            className="af-class-arrow--chevron"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </slider>
                </gallery>
                <amenities-list>
                    <TourModal
                        tours={tours}
                        amenities={features}
                        selected={0}
                        open={showTour}
                        onRequestClose={() => { closeTour() }}
                        play={playTour}
                        setSelected={() => null}
                        getSelectedIndex={selectedIndex}
                        startPlay={() => setPlayTour(true)}
                        title={props.property.data.name}
                    />
                    {features.map((amenity, index) =>
                        !!amenity && !!amenity.amenity ? (
                            <li
                                className="af-class-amenities-slider-list-item"
                                key={`amenity-item-${index}`}
                            >
                                <span>{(amenity.weblink && validateUrl(amenity.weblink.url)) ?
                                    <React.Fragment>
                                        <span id={`amenities_tour_${index}`} className="floorplan-3d-tour" tabIndex={0} onKeyDown={(e) => e.keyCode === 13 && setSelectedTour(index)} onMouseOver={(e) => e.target.style.cursor = "pointer"} onClick={() => setSelectedTour(index)}>
                                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 219.000000 110.000000"
                                                preserveAspectRatio="xMidYMid meet" width="30" height="31">

                                                <g transform="translate(0.000000,230.000000) scale(0.100000,-0.100000)"
                                                    fill="#000000" stroke="none">
                                                    <path d="M1702 1644 c-32 -22 -43 -57 -31 -94 30 -84 149 -58 149 32 0 63 -67
98 -118 62z m72 -43 c23 -25 6 -61 -28 -61 -35 0 -52 25 -38 55 14 30 42 32
66 6z"/>
                                                    <path d="M585 1525 c-42 -15 -85 -56 -85 -80 0 -8 17 -23 39 -34 l38 -20 11
24 c21 46 106 46 118 -1 10 -38 -11 -73 -46 -80 -25 -5 -30 -11 -30 -35 0 -22
5 -29 19 -29 34 0 71 -38 71 -75 0 -75 -106 -90 -142 -20 l-13 24 -47 -21
c-27 -11 -48 -24 -48 -29 0 -20 74 -89 107 -100 50 -16 147 -7 180 17 70 52
92 135 53 198 -12 20 -26 36 -30 36 -5 0 0 16 12 35 15 27 19 47 15 81 -6 53
-27 80 -82 105 -48 22 -86 23 -140 4z"/>
                                                    <path d="M1393 1520 c-42 -21 -83 -71 -103 -124 -15 -40 -15 -162 -1 -214 13
-47 60 -106 102 -128 41 -21 122 -18 169 6 143 73 145 370 3 452 -50 30 -121
33 -170 8z m145 -131 c21 -39 23 -54 19 -124 -5 -74 -8 -82 -36 -107 -36 -32
-57 -35 -89 -12 -32 22 -43 56 -43 139 -1 84 21 140 60 153 39 14 64 0 89 -49z"/>
                                                    <path d="M990 1452 c-88 -104 -120 -169 -120 -242 0 -110 78 -174 202 -168 57
3 73 8 104 34 68 54 80 148 29 225 -25 39 -77 69 -119 69 -33 0 -27 12 43 90
28 30 51 58 51 63 0 4 -28 7 -62 7 l-63 -1 -65 -77z m123 -205 c23 -26 17 -85
-10 -104 -46 -34 -123 -1 -123 53 0 76 85 109 133 51z"/>
                                                    <path d="M301 1203 c-116 -48 -173 -131 -150 -218 14 -51 103 -139 184 -181
200 -105 525 -163 840 -151 517 21 908 208 855 408 -15 55 -53 95 -123 128
-91 43 -121 49 -72 16 83 -56 135 -119 135 -164 0 -48 -84 -109 -208 -152
-171 -59 -531 -88 -867 -70 -288 15 -446 46 -576 111 -61 31 -109 80 -109 111
0 45 52 108 135 163 50 33 38 32 -44 -1z"/>
                                                </g>
                                            </svg>
                                            &nbsp;&nbsp;
                                            {/*<style*/}
                                            {/*    dangerouslySetInnerHTML={{*/}
                                            {/*        __html: `\n .floorplan-3d-tour:hover {\n background-color: ${primaryColor};\n color: white;\n}"`,*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                        </span>
                                    </React.Fragment> :
                                    ""}
                                </span>
                                <span>{amenity.amenity}</span>
                                {/*<div className={styles.amenitityToolTipHide} id={`amenities_reversed_tour_tooltip${index}`}> Click to view 3D Tour</div>*/}
                            </li>
                        ) : null
                    )}
                    {
                        showDisclaimer === "Yes"
                            ? <li className={["af-class-amenities-slider-list-item", styles.disclaimer].join(" ")}>*in select apartment homes</li>
                            : null
                    }
                </amenities-list>
            </ListAmenitiesWithReversedGalleryView>
        </React.Fragment>
    );
}

export default ListAmenitiesWithReversedGallery;
