import React from 'react'
import styles from './styles.module.css'
export default (props) => {
    if (!props.data) {
        return null
    }

    const { data: { items } } = props;

    return (
        <div className={styles.table}>
            {items.map((item, index) => (
                <div key={`row-${index}`} className={styles.row}>
                    <div className={styles.column}>
                        <p className={styles.cell}>{item.col1[0].text}</p>
                    </div>
                    <div className={styles.column}>
                        <p className={styles.cell}>{item.col2[0].text}</p>
                    </div>
                    <div className={styles.column}>
                        <p className={styles.cell}>{item.col3[0].text}</p>
                    </div>
                    {
                        item.col4 && item.col4.length > 0 &&
                        <div className={styles.column}>
                            <p className={styles.cell}>{item.col4[0].text}</p>
                        </div>
                    }
                </div>
            ))}
        </div>
    )
}
