/* eslint-disable */

import React from 'react';
import { createScope, map, transformProxies } from '../helpers';

import PropertyContactMapScheduleItemView from './PropertyContactMapScheduleItemView';
import PropertyContactMapItemView from './PropertyContactMapItemView';

let Controller;

class PropertyContactMapView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require('../controllers/PropertyContactMapController');
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = PropertyContactMapView;

        return Controller;
      }

      throw e;
    }
  }

  render() {
    const proxies =
      Controller !== PropertyContactMapView
        ? transformProxies(this.props.children)
        : {
            container: [],
            map: [],
            info: [],
            'info-header': [],
            subtitle: [],
            address: [],
            details: [],
            schedule: [],
            'phone-container': [],
            'phone-title': [],
            'phone-number': [],
            'contact-title': [],
            'contact-value': [],
            'follow-title': [],
            social: [],
            propertyContactMapScheduleItemViewList0: [],
            propertyContactMapItemViewList2: [],
            'cta-link': [],
            'cta-text': [],
            'cta-arrow': [],
            tour: [],
            note: [],
            infoHeaderContainer: [],
            'leasing-message': []
          };

    return (
      <React.Fragment>
        <span
          className="af-view"
          style={{ width: '100%', height: '100%', flex: 1 }}
        >
          {map(proxies['container'], (props) => (
            <div
              {...{
                ...props,
                className: `af-class-div-block-416 ${props.className || ''}`,
              }}
            >
              {createScope(props.children, (proxies) => (
                <React.Fragment>
                  {props.topelement ? props.topelement() : null}

                  {map(proxies['map'], (props) => (
                    <div
                      {...{
                        ...props,
                        className: `af-class-div-block-417 ${props.className ||
                          ''}`,
                      }}
                    >
                      {props.children}
                    </div>
                  ))}
                  {map(proxies['info'], (props) => (
                    <div
                      {...{
                        ...props,
                        className: `af-class-div-block-418 ${props.className ||
                          ''}`,
                      }}
                    >
                      {createScope(props.children, (proxies) => (
                        <React.Fragment>
                          {props.topelement ? props.topelement() : null}

                          {map(proxies['info-header'], (props) => (
                            <div
                              {...{
                                ...props,
                                className: `af-class-div-block-419 ${props.className ||
                                  ''}`,
                              }}
                            >
                              {createScope(props.children, (proxies) => (
                                <React.Fragment>
                                  <div className={`af-class-div-block-419-padding`}>
                                    {map(proxies['subtitle'], (props) => (
                                      <h2
                                        {...{
                                          ...props,
                                          className: `af-class-section-title af-class-white ${props.className ||
                                            ''}`,
                                        }}
                                      >
                                        {props.children ? (
                                          props.children
                                        ) : (
                                          <React.Fragment>
                                            amli lofts chicago
                                          </React.Fragment>
                                        )}
                                      </h2>
                                    ))}
                                    {map(
                                      proxies['infoHeaderContainer'],
                                      (props) => (
                                        <div
                                          {...{
                                            ...props,
                                            className: `splitColumn ${props.className ||
                                              ''}`,
                                          }}
                                        >
                                          {createScope(
                                            props.children,
                                            (proxies) => (
                                              <React.Fragment>
                                                {map(
                                                  proxies['address'],
                                                  (props) => (
                                                    <div
                                                      {...{
                                                        ...props,
                                                        className: `af-class-section-tagline af-class-white infoColumn ${props.className ||
                                                          ''}`,
                                                      }}
                                                    >
                                                      {props.children ? (
                                                        <div className="infoItem">
                                                          {props.children}
                                                        </div>
                                                      ) : (
                                                        <React.Fragment>
                                                          850 S Clark St.
                                                          <br />
                                                          Chicago, IL 60605
                                                        </React.Fragment>
                                                      )}
                                                    </div>
                                                  )
                                                )}
                                                {map(
                                                  proxies['social'],
                                                  (props) => (
                                                    <div
                                                      {...{
                                                        ...props,
                                                        className: `af-class-section-tagline af-class-white infoColumn-social ${props.className ||
                                                          ''}`,
                                                      }}
                                                    >
                                                      {props.children ? (
                                                        <div className="infoItem">
                                                          {props.children}
                                                        </div>
                                                      ) : (
                                                        <React.Fragment>
                                                          /AMLILofts
                                                          <br />
                                                          @amlilofts
                                                        </React.Fragment>
                                                      )}
                                                    </div>
                                                  )
                                                )}
                                              </React.Fragment>
                                            )
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                  <div className={`leasing-message`}>
                                    {map(proxies['leasing-message'], (props) => (              
                                        <span>
                                            {props.children ? (props.children) : (<React.Fragment></React.Fragment>
                                            )}
                                        </span>
                                    ))}                                    
                                  </div>
                                </React.Fragment>
                              ))}
                            </div>
                          ))}
                          {map(proxies['details'], (props) => (
                            <div
                              {...{
                                ...props,
                                className: `af-class-property-contact-details-wrap ${props.className ||
                                  ''}`,
                              }}
                            >
                              {createScope(props.children, (proxies) => (
                                <React.Fragment>
                                  {props.topelement ? props.topelement() : null}
                                  <div className={`af-class-property-contact-details-content splitColumn`}>
                                    {map(proxies['schedule'], (props) => ((props.children.props.children[0].props.item.days || props.children.props.children[0].props.item.hours || props.children.props.children[0].props.item.note) &&
                                      
                                      <div
                                        {...{
                                          ...props,
                                          className: `af-class-div-block-421 contact-group smallContact ${props.className ||
                                            ''}`,
                                        }}
                                      >
                                        {props.children ? (
                                          props.children
                                        ) : (
                                          <React.Fragment>
                                            {map(
                                              proxies[
                                                'propertyContactMapScheduleItemViewList0'
                                              ],
                                              (props) => (
                                                <React.Fragment {...props}>
                                                  {props.children
                                                    ? props.children
                                                    : null}
                                                </React.Fragment>
                                              )
                                            )}
                                          </React.Fragment>
                                        )}
                                      </div>
                                    ))}
                                    {map(proxies['schedule'], (props) => ((props.children.props.children[0].props.item.days || props.children.props.children[0].props.item.hours || props.children.props.children[0].props.item.note) && (<div className="divider"></div>) ))}
                                    <div className={`af-class-div-block-421 smallContact ${map(proxies['schedule'], (props) => ((props.children.props.children[0].props.item.days || props.children.props.children[0].props.item.hours || props.children.props.children[0].props.item.note) ? '' : 'removePadding'))}`}>
                                      {map(
                                        proxies['phone-container'],
                                        (props) => (
                                          <div
                                            {...{
                                              ...props,
                                              className: `af-class-property-contact-detail-col contact-group ${props.className ||
                                                ''}`,
                                            }}
                                          >
                                            {createScope(
                                              props.children,
                                              (proxies) => (
                                                <React.Fragment>
                                                  {props.topelement
                                                    ? props.topelement()
                                                    : null}

                                                  {map(
                                                    proxies['phone-title'],
                                                    (props) => (
                                                      <div
                                                        {...{
                                                          ...props,
                                                          className: `af-class-property-contact-detail-col-label ${props.className ||
                                                            ''}`,
                                                        }}
                                                      >
                                                        {props.children ? (
                                                          props.children
                                                        ) : (
                                                          <React.Fragment>
                                                            call us 24/7
                                                          </React.Fragment>
                                                        )}
                                                      </div>
                                                    )
                                                  )}
                                                  {map(
                                                    proxies['phone-number'],
                                                    (props) => (
                                                      <div
                                                        {...{
                                                          ...props,
                                                          className: `af-class-property-contact-detail-col-detail ${props.className ||
                                                            ''}`,
                                                        }}
                                                      >
                                                        {props.children ? (
                                                          props.children
                                                        ) : (
                                                          <React.Fragment>
                                                            888.826.3223
                                                          </React.Fragment>
                                                        )}
                                                      </div>
                                                    )
                                                  )}
                                                </React.Fragment>
                                              )
                                            )}
                                          </div>
                                        )
                                      )}
                                      <div className="af-class-property-contact-detail-col contact-group">
                                        {map(
                                          proxies['contact-title'],
                                          (props) => (
                                            <div
                                              {...{
                                                ...props,
                                                className: `af-class-property-contact-detail-col-label ${props.className ||
                                                  ''}`,
                                              }}
                                            >
                                              {props.children ? (
                                                props.children
                                              ) : (
                                                <React.Fragment>
                                                  want us to contact you?
                                                </React.Fragment>
                                              )}
                                            </div>
                                          )
                                        )}
                                        {map(
                                          proxies['contact-value'],
                                          (props) => (
                                            <div
                                              {...{
                                                ...props,
                                                className: `af-class-property-contact-detail-col-detail ${props.className ||
                                                  ''}`,
                                              }}
                                            >
                                              {props.children ? (
                                                props.children
                                              ) : (
                                                <React.Fragment>
                                                  Contact Us
                                                </React.Fragment>
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {map(proxies['note'], (props) => (
                                    <p className="note">{props.text}</p>
                                  ))}
                                  {map(proxies['tour'], (props) => (
                                    <div className="tour">{props.children}</div>
                                  ))}
                                  {map(proxies['cta-link'], (props) => (
                                    <a
                                      {...{
                                        ...props,
                                        className: `af-class-button af-class-boss af-class-yellow af-class-no-margin w-inline-block ${props.className ||
                                          ''}`,
                                      }}
                                    >
                                      {createScope(
                                        props.children,
                                        (proxies) => (
                                          <React.Fragment>
                                            {props.topelement
                                              ? props.topelement()
                                              : null}

                                            {map(
                                              proxies['cta-text'],
                                              (props) => (
                                                <div
                                                  {...{
                                                    ...props,
                                                    className: `af-class-boos-button-label ${props.className ||
                                                      ''}`,
                                                  }}
                                                >
                                                  {props.children ? (
                                                    props.children
                                                  ) : (
                                                    <React.Fragment>
                                                      Visit Property
                                                    </React.Fragment>
                                                  )}
                                                </div>
                                              )
                                            )}
                                            {map(
                                              proxies['cta-arrow'],
                                              (props) => (
                                                <img
                                                  src="/images/right-arrow.svg"
                                                  alt="Arrow icon."
                                                  {...{
                                                    ...props,
                                                    className: `af-class-boss-right-arrow ${props.className ||
                                                      ''}`,
                                                  }}
                                                >
                                                  {props.children}
                                                </img>
                                              )
                                            )}
                                          </React.Fragment>
                                        )
                                      )}
                                    </a>
                                  ))}
                                </React.Fragment>
                              ))}
                            </div>
                          ))}
                        </React.Fragment>
                      ))}
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </div>
          ))}
        </span>
      </React.Fragment>
    );
  }
}

export default PropertyContactMapView;

/* eslint-enable */
