import React, { useEffect } from "react";
import { get, findIndex } from "lodash";
import { withRouter } from "react-router-dom";

import "./index.css"
import { handlePrismicLink } from "../../helpers/link-helper";
import { addQualityParam } from "../../helpers/add-or-replace-url-param";
import { LazyLoaderElement, LazyLoader } from "../../helpers/lazy-loader";

export default withRouter(props => {

    const { property } = props;

    let propertyContactCta;
    if (property && property.data && property.data.body) {
        const sliceIndex = findIndex(property.data.body, ['slice_type', 'property_contact_cta']);
        if (sliceIndex >= 0) {
            propertyContactCta = property.data.body[sliceIndex];
        }
    }

    if (!property || !propertyContactCta) {
        return null;
    }
    const title = get(propertyContactCta, "primary.title");
    const textBlub = get(propertyContactCta, "primary.text_blurb");
    const image = get(propertyContactCta, "primary.image.url");
    let link = get(propertyContactCta, "primary.link");
    const buttonText = get(propertyContactCta, "primary.button_text", "Schedule a Tour");
    const secondaryColor = get(property, "data.secondary_color");
    const goDark = get(property, "data.go_dark", "");
    link = handlePrismicLink(
        props,
        link,
        {
            property,
            propertyData: property
        },
        goDark !== "Yes" ? buttonText : "goDark"
    );

    useEffect(() => {
        //#region SETUP lazy loading

        let lazyLoaderElementList = [];

        let lazyLoaderElementItem = new LazyLoaderElement();

        //STORE images TO lazy load
        lazyLoaderElementItem.Id = "floorplans_footer_cta_background_image";
        lazyLoaderElementItem.BackgroundImage = `linear-gradient(0deg, #000000, transparent, rgba(0, 0, 0, .8)), url(${addQualityParam(image, 5)})`;

        lazyLoaderElementList.push(lazyLoaderElementItem);

        const lazyLoader = new LazyLoader();
        lazyLoader.LazyLoad(lazyLoaderElementList);

        //#endregion
    }, [])

    return (
        <div className="floorplans-footer-cta-wrapper">
            <div id="floorplans_footer_cta_background_image"
                className="floorplans-footer-cta-background-image"
            >
                <div className="floorplans-footer-cta-title">
                    {title}
                </div>
                <div className="floorplans-footer-cta-subtitle">
                    {textBlub}
                </div>
                <span
                    className="floorplans-footer-cta-button-wrapper"
                >
                    <a
                        style={{ backgroundColor: secondaryColor }}
                        className="floorplans-footer-cta-button"
                        {...link}
                    >
                        <img
                            alt="Arrow icon."
                            className="floorplans-footer-cta-button-arrow"
                            src="/images/icon-arrow--right-no-shadow3x.png"
                        />
                        <span className="floorplans-footer-cta-button-text">{buttonText}</span>
                    </a>
                </span>
            </div>
        </div>
    );
});
