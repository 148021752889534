import React, { useCallback, useEffect, useState } from 'react';

import get from 'lodash/get';
import Helmet from 'react-helmet-async';
import ImageZoom from 'react-medium-image-zoom';
import { withRouter } from 'react-router';
import placeholderImage from '../../assets/images/floorplan-placeholder@2x.jpg';
import { getItemString } from '../../helpers/cookies';
import usePhone from '../../helpers/hooks/usePhone';
import pageOpener from '../../helpers/page-opener';
import { composeCustomApplyLink } from '../../helpers/property/apply';
import { getQuote } from '../../helpers/property/get-quote';
import { canUseDOM } from '../../helpers/utils';
import TourModal from '../TourModalController';
import './index.css';
import styles from './styles.module.css';

import { isMobile } from 'react-device-detect';

function FloorplansFloorplanCard(props) {
  const [showTour, setShowTour] = useState(false);
  const [playTour, setPlayTour] = useState(false);

  const sessionIdFromCookies = getItemString('session_uuid');
  const { floorplan, property, moveInDate, size, sessionId, location } = props;
  const name = get(floorplan, 'cms.data.name');
  const image = get(floorplan, 'cms.data.main_image.url');
  const customQuoteButton = get(property, 'data.custom_quote_button');
  const customQuoteButtonText = get(property, 'data.custom_quote_button_text');
  const customQuoteLink = get(property, 'data.custom_quote_link');
  const customQuoteLinkText = get(property, 'data.custom_quote_link_text.url');
  const primaryColor = get(props, 'property.data.primary_color');
  const entrataLink = getQuote(property.data, floorplan, moveInDate, sessionId || sessionIdFromCookies);
  const isEntrata = get(property, 'data.lease_service', '') === 'Entrata';
  const regularUnitCount = get(floorplan, 'returnedRegularUnitCount');
  const affordableUnitCount = get(floorplan, 'returnedAffordableUnitCount');

  let contactLink = get(location, 'pathname');
  contactLink = contactLink.replace('floorplans', 'contact');

  const units = get(floorplan, 'units');

  let unitsAvailableDates = null;
  let unitsEarilestDate = null;

  const entrataOnlyAffordableAvialable = isEntrata && affordableUnitCount > 0 && regularUnitCount === 0;

  const totalAvailableUnits = regularUnitCount + affordableUnitCount;

  let disableAvailableUnitsCount = get(props, 'property.data.disable_available_units_count_text_display');

  const getEarliestMoveInDate = () => {
    if (!units) return null;

    unitsAvailableDates = [];

    units.forEach(unit => {
      if (unit.rpAvailableDate) {
        const dateArray = unit.rpAvailableDate.split('-'); //0 = Year, 1 = Month, 2 = Day
        const dateFormatted = dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0];

        unitsAvailableDates.push(new Date(dateFormatted + ' 00:00'));
      }
    });

    if (unitsAvailableDates.length > 0) {
      let date = unitsAvailableDates.sort((a, b) => {
        return a.getTime() - b.getTime();
      })[0];

      if (new Date(date.toLocaleDateString()) <= new Date()) {
        unitsEarilestDate = 'Now';
      } else {
        unitsEarilestDate = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        });
      }

      return unitsEarilestDate;
    }

    return null;
  };

  const goDark = get(property, 'data.go_dark', '');
  const [, /*dniPhone*/ hrefPhone] = usePhone(get(property, 'data.phone'), get(property, 'data.entrataPropertyId'), props);

  const tours = get(props, 'property.data.3d_tours', []).filter(tour => !!tour && !!tour.enabled && tour.enabled === 'True');

  const floorplanIdInUse = floorplan.cms.id;

  const selectedTourUrl = () => {
    let tmpFloorplan = tours.find(t => t.floorplan.id === floorplanIdInUse);

    return typeof tmpFloorplan !== 'undefined' ? true : false;
  };

  if (isMobile) {
    contactLink = hrefPhone;
  }

  // State for if an image is zoomed  / modal is open
  const [isZoomed, setIsZoomed] = useState(false);

  // Create a new element that we'll use as our close button
  const el = typeof document !== 'undefined' ? document.createElement('div') : null;
  if (el) {
    el.className = 'floorplan-close-button';
    el.innerHTML = 'Close ✖';
    el.id = 'floorplan-close-button';
  }

  // Add an event listener to set our state & close the modal whenever it is clicked/tapped
  // Also try to remove the element from the DOM\
  if (el) {
    el.addEventListener('click', () => {
      setIsZoomed(false);
      if (typeof document !== 'undefined') document.getElementById('floorplan-close-button').remove();
    });
  }

  // Create a function that will be called when the image is clicked/tapped
  // Note: This functionality is added to replicate the default behavior of a non-controlled ImageZoom component
  const handleZoomChange = useCallback(() => {
    // If we're not already zoomed, add the close button to the DOM
    if (!isZoomed) {
      if (typeof document !== 'undefined' && el) document.body.appendChild(el);
    } else {
      // Otherwise, try to remove it from the DOM
      if (typeof document !== 'undefined' && el) document.getElementById('floorplan-close-button').remove();
    }

    // Set our state to the opposite of what it currently is
    setIsZoomed(!isZoomed);
  }, [isZoomed, el]);

  useEffect(() => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      // Add a function to handle proper closing of the modal when the user presses the escape key or scrolls
      const handleScroll = event => {
        if (event.key === 'Escape' || event.type === 'scroll') {
          // Always set isZoomed to false
          setIsZoomed(false);
          if (isZoomed) {
            // but only try to remove the close button from the DOM if we're certain we are zoomed
            document.getElementById('floorplan-close-button').remove();
          }
        }
      };

      // Add our event listeners
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('keydown', handleScroll);

      // Resolve our event listeners when effect is cleaned up
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('keydown', handleScroll);
      };
    }
  }, [el]);

  return (
    <React.Fragment>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org/",
              "@type": "Apartment",
              "name": "${floorplan.floorplanName}",
              "image": "${image ? image : placeholderImage}",
              "numberOfRooms": "${floorplan.bedroomMax}",
              "floorsize": {
                "@type": "QuantitativeValue",
                "minValue": "${floorplan.sqftMin}",
                "maxValue": "${floorplan.sqFtMax}",
                "unitCode": "sqft"
              }
            }
          `}
        </script>
      </Helmet>

      <div className="floorplan-list-item" onScroll={handleZoomChange}>
        <div className="floorplan-thumbnail">
          <div className="floorplan-img-container" onClick={handleZoomChange}>
            {image ? <ImageZoom isZoomed={isZoomed} image={{ src: image, alt: name }} /> : <img src={placeholderImage} alt="Images Coming Soon" />}
          </div>
          {!!tours && selectedTourUrl() ? (
            <React.Fragment>
              <div
                className="floorplan-3d-tour"
                tabIndex={0}
                onKeyDown={e => e.keyCode === 13 && setShowTour(true)}
                onClick={() => setShowTour(true)}
              >
                Virtual Tours
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M2.39746 5.544L8.99995 1.66018L15.6024 5.544L15.6024 13.3442L8.99995 17.228L2.39746 13.3442L2.39746 5.544Z"
                    stroke="#383838"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <mask id="mask0_1691_8600" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="0" width="16" height="19">
                    <path
                      d="M2.39746 5.544L8.99995 1.66018L15.6024 5.544L15.6024 13.3442L8.99995 17.228L2.39746 13.3442L2.39746 5.544Z"
                      fill="white"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </mask>
                  <g mask="url(#mask0_1691_8600)">
                    <path
                      d="M1.83231 4.23328L0.963135 5.71088L8.14296 9.9343V18.5986H9.85725V9.93443L17.0373 5.71088L16.1681 4.23328L9.00021 8.44969L1.83231 4.23328Z"
                      fill="#383838"
                    />
                  </g>
                </svg>
                <style
                  dangerouslySetInnerHTML={{
                    __html: `\n .floorplan-3d-tour:hover {\n background-color: ${primaryColor};\n color: white;\n}"`
                  }}
                />
              </div>
              <TourModal
                tours={tours}
                selected={0}
                open={showTour}
                onRequestClose={() => setShowTour(false)}
                play={playTour}
                setSelected={() => null}
                startPlay={() => setPlayTour(true)}
                title={props.property.data.name}
                floorplan={floorplan.cms.id}
              />
            </React.Fragment>
          ) : null}
        </div>

        <div className="floorplan-title-container">
          <h3 className="floorplan-title">{floorplan.floorplanName}</h3>
          <div className="floorplan-perk-container">
            {floorplan.cms.data.description
              ? floorplan.cms.data.description
                  .split(', ')
                  .slice(0, 4)
                  .map(perk => (
                    <div className="floorplan-perk" style={{ backgroundColor: primaryColor }}>
                      {perk}
                    </div>
                  ))
              : null}
          </div>
          {goDark !== 'Yes' && !disableAvailableUnitsCount ? (
            <React.Fragment>
              {affordableUnitCount || floorplan.allFloorPlanUnitsAreAffordable ? (
                <h4 className="floorplan-available-units-affordable">
                  {affordableUnitCount ? 'Affordable Unit(s) Available*' : '0 Affordable Units Available*'}
                </h4>
              ) : (
                <h4 className="floorplan-available-units">
                  {`${totalAvailableUnits} Available Unit`}
                  {totalAvailableUnits === 1 ? '' : 's'}
                </h4>
              )}
            </React.Fragment>
          ) : null}
        </div>

        <div className="floorplan-information-container">
          <div className="floorplan-information-stats">
            <div className="floorplan-info floorplan-beds">
              <p>Bed</p>
              <p>{size === '0' ? 'Studio' : `${size}`}</p>
            </div>
            <div className="floorplan-info floorplan-baths">
              <p>Bath</p>
              <p>{floorplan.bathroomMin ? floorplan.bathroomMin : 1}</p>
            </div>
            <div className="floorplan-info floorplan-size">
              <p>SQFT</p>
              <p>
                {floorplan.sqftMin !== floorplan.sqFtMax && floorplan.sqftMin ? (floorplan.sqftMin ? floorplan.sqftMin.toLocaleString() : '') : null}
                {floorplan.sqftMin !== floorplan.sqFtMax && floorplan.sqftMin && floorplan.sqFtMax ? ' → ' : null}
                {floorplan.sqFtMax ? floorplan.sqFtMax.toLocaleString() : null}
              </p>
            </div>
          </div>

          <div className="floorplan-availability-pricing-container">
            {getEarliestMoveInDate() ? (
              <div className={`floorplan-info floorplan-availability ${affordableUnitCount ? 'affordable' : ''}`}>
                <p>Available</p>
                <p>{getEarliestMoveInDate()}</p>
              </div>
            ) : null}
            {floorplan.priceMin !== null &&
            typeof floorplan.priceMin === 'number' &&
            !floorplan.isCallForPricing &&
            totalAvailableUnits !== 0 &&
            !affordableUnitCount ? (
              <div className="floorplan-info floorplan-pricing">
                <p>Starting At</p>
                <p>{`$${floorplan.priceMin.toLocaleString()}/mo.`}</p>
              </div>
            ) : null}
            <div className="floorplan-quote-container">
              {goDark !== 'Yes' && totalAvailableUnits > 0 && !entrataOnlyAffordableAvialable && !floorplan.isCallForPricing ? (
                <a
                  href={entrataLink ? entrataLink : `floorplan/${floorplan.floorplanId}`}
                  onClick={e => {
                    if (canUseDOM) {
                      window.dataLayer.push({
                        event: 'clickGetQuote',
                        'clickGetQuote.property': property.data.name,
                        'clickGetQuote.context': entrataLink ? 'Entrata' : 'RealPage'
                      });
                    }
                    if (e) {
                      e.preventDefault();
                    }
                    if (canUseDOM && customQuoteLink && customQuoteLinkText) {
                      window.open(composeCustomApplyLink(customQuoteLinkText));
                    } else if (entrataLink) {
                      pageOpener(entrataLink);
                    } else {
                      props.history.push(`floorplan/${floorplan.floorplanId}`, {
                        moveInDate: props.moveInDate,
                        floorplan: floorplan.floorplanName
                      });
                    }
                  }}
                >
                  <div className="floorplan-quote" style={{ backgroundColor: primaryColor }}>
                    {customQuoteButton ? customQuoteButtonText : 'Get Quote'}
                  </div>
                </a>
              ) : goDark === 'Yes' || totalAvailableUnits === 0 || entrataOnlyAffordableAvialable || floorplan.isCallForPricing ? (
                <a href={contactLink}>
                  <div className="floorplan-quote" style={{ backgroundColor: primaryColor }}>
                    {customQuoteButton && totalAvailableUnits !== 0 && !floorplan.isCallForPricing && !affordableUnitCount
                      ? customQuoteButtonText
                      : 'Contact Office'}
                  </div>
                </a>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.disclaimerApartmentSize}>Square footage & measurements are approximate, and floor plan details may vary.</div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(FloorplansFloorplanCard);
