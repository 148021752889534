/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from '../helpers'

let Controller = null;

class PortfolioEnvironmentalTargetsView extends React.Component {
    static get Controller() {
        if (Controller) return Controller

        try {
            Controller = require('../controllers/PortfolioEnvironmentalTargetsController')
            Controller = Controller.default || Controller

            return Controller
        }
        catch (e) {
            if (e.code == 'MODULE_NOT_FOUND') {
                Controller = PortfolioEnvironmentalTargetsView

                return Controller
            }

            throw e
        }
    }

    render() {
        const proxies = Controller !== PortfolioEnvironmentalTargetsView ? transformProxies(this.props.children) : {
            'title': [],
            'sub-text': [],
            'infographic-left-image': [],
            'infographic-grid-data': [],
            'infographic-right-image': [],
        }

        return (
            <React.Fragment>
                <span className="af-view" style={{ width: '100%', height: '100%', flex: 1 }}>
                    <div className="af-class-environmental-goals">
                        <div class="af-portfolio-environmental-wrapper">
                            <div class="af-class-div-block-118 af-portfolio-environmental">
                                {map(proxies['title'], props => <h2 {...{ ...props, className: `af-class-section-tagline af-class-small ${props.className || ''}` }}>{props.children ? props.children : <React.Fragment>Portfolio Environmental Targets</React.Fragment>}</h2>)}
                                {map(proxies['sub-text'], props => <p {...{ ...props, className: `af-class-paragraph ${props.className || ''}` }}>{props.children ? props.children : <React.Fragment>AMLI's ambitious targets drive efficient resource use at our communities and reduce our carbon footprint.</React.Fragment>}</p>)}
                            </div>
                            {map(proxies['infographic-grid-data'], props => <div {...{ ...props, className: `af-portfolio-environmental-infographic-data-wrapper ${props.className || ''}` }}>{props.children ? props.children : <React.Fragment></React.Fragment>}</div>)}
                        </div>
                    </div>
                </span>
            </React.Fragment>
        )
    }
}

export default PortfolioEnvironmentalTargetsView

/* eslint-enable */


