import React, { useEffect } from "react";
import get from "lodash/get";
import { withRouter } from "react-router-dom";
import ContentCollageLifestyleView from "../../components/ContentCollageLifestyleView";
import { handlePrismicLink } from "../../helpers/link-helper";
import { addOrReplaceUrlParam, addQualityParam } from "../../helpers/add-or-replace-url-param";
import styles from './styles.module.css';
import "./index.css";
import { canUseDOM } from "../../helpers/utils";
import { LazyLoaderElement, LazyLoader } from "../../helpers/lazy-loader";

export default withRouter(props => {
    const { data } = props;
    const subtitle = get(data, "primary.subtitle");
    const titleLine1 = get(data, "primary.title_line_1");
    const titleLine2 = get(data, "primary.title_line_2");
    const textBlurb = get(data, "primary.text_blurb");
    const linkData = handlePrismicLink(props, get(data, "primary.link_url"));
    const linkText = get(data, "primary.link_text");
    const image1 = get(data, "primary.image_1.url");
    const image2 = get(data, "primary.image_2.url");
    const image3 = get(data, "primary.image_3.url");
    const imageText2 = get(data, "primary.image_alt_text_2");
    const imageText3 = get(data, "primary.image_alt_text_3");
    let width;
    if (canUseDOM) {
        width = window.innerWidth > 1100 ? '600' : '300';
    }
    const resizedImage2 = image2 && addOrReplaceUrlParam(image2, 'w', width);
    const resizedImage3 = image3 && addOrReplaceUrlParam(image3, 'w', width);

    let lazyLoaderElementList = [];

    useEffect(() =>
    {
        //#region SETUP lazy loading

        //STORE background image TO lazy load
        let lazyLoaderElementItem = new LazyLoaderElement();

        if (image1)
        {
            lazyLoaderElementItem.Id = "lazy_content_collage_lifestyle_home_intro";
            lazyLoaderElementItem.BackgroundImage = `url(${addQualityParam(image1, 5)})`;

            lazyLoaderElementList.push(lazyLoaderElementItem);
        }

        //STORE images TO lazy load
        lazyLoaderElementItem = new LazyLoaderElement();
        lazyLoaderElementItem.Id = "lazy_content_collage_lifestyle_couple_dining";
        lazyLoaderElementItem.Src = addQualityParam(resizedImage2, 5);
        lazyLoaderElementItem.SrcSet = addQualityParam(resizedImage2, 5);

        lazyLoaderElementList.push(lazyLoaderElementItem);

        lazyLoaderElementItem = new LazyLoaderElement();
        lazyLoaderElementItem.Id = "lazy_content_collage_lifestyle_bike_ride";
        lazyLoaderElementItem.Src = addQualityParam(resizedImage3, 5);
        lazyLoaderElementItem.SrcSet = addQualityParam(resizedImage3, 5);

        lazyLoaderElementList.push(lazyLoaderElementItem);

        const lazyLoader = new LazyLoader();
        lazyLoader.LazyLoad(lazyLoaderElementList);

        //#endregion
    }, []);

    return (
        <ContentCollageLifestyleView>
            <subtitle>{subtitle}</subtitle>
            <title-line-1 className={styles.line1}>{titleLine1}</title-line-1>
            <title-line-2 className={styles.line2}>{titleLine2}</title-line-2>
            <text-blurb className={styles.textBlurb}>{textBlurb}</text-blurb>
            <link-url {...linkData} className={styles.button}>
                <link-text className={styles.buttonText}>{linkText}</link-text>
                <link-arrow className={styles.buttonArrow} alt="Right arrow." />
            </link-url>
            <image-1
                className={styles.mainImage}
            >
                {image2 ? (
                    <image-2 alt={imageText2} role="img" />
                ) : (
                        <image-2 />
                    )}
                {image3 ? (
                    <image-3 alt={imageText3} role="img" />
                ) : (
                        <image-3 />
                    )}
            </image-1>
        </ContentCollageLifestyleView>
    );
});
