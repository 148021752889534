import React from "react";
import get from "lodash/get";
import { graphql } from "react-apollo";
import Helmet from "react-helmet-async";
import ExtractMeta from '../../extract-meta';
import componentMapper from "../../component-mapper";
import BuildCanonicalLink from '../../build-canonical-link';
import { amenitiesQuery } from "../../../graphql/queries/amenitiesQuery";
import { getRelatedAmenitiesPage } from "../../get-related-property-pages";

import EntrataWidget from "../../entrata-widget";

const amenitiesPage = props => {
    const { property } = props;
    const amenities = getRelatedAmenitiesPage(props.property.id, props.appdata);
    const canonicalLink = BuildCanonicalLink(props, 'property:amenities');
    const meta = ExtractMeta(get(props, "data.amenities"), canonicalLink) || ExtractMeta(property, canonicalLink);
    const slices =
        get(props, "preview.data.body") ||
        get(props, "data.amenities.data.body") ||
        get(amenities, "data.body") ||
        [];

    const SliceComp = slices
        .map((slice, index) => {
            const Component = componentMapper(slice.slice_type);
            if (!Component) {
                return null;
            }

            return (
                <Component
                    key={`${slice.slice_type}_${index}`}
                    slice={slice}
                    property={property}
                />
            );
        })
        .filter(slice => !!slice);

    return (
        <>
            <Helmet>
                {meta}
            </Helmet>
            <EntrataWidget doNotIgnore={true} {...props} />
            {SliceComp}
        </>
    )
};

export const amenitiesHOC = graphql(amenitiesQuery, {
    options: props => ({
        variables: {
            propertyPageId: props.property.id
        }
    })
});

export const amenitiesSlices = amenitiesHOC(amenitiesPage);
