import gql from 'graphql-tag';

export const propertyReviewsQuery = gql`
  query propertyReviews(
    $id: ID!
    $review_push_id: ID
    $reputationdotcom_id: ID
    $reputationdotcom_reviews_enabled: Boolean
    $review_sources: Object
    $limit: Int!
  ) {
    propertyReviews(
      id: $id
      review_push_id: $review_push_id
      reputationdotcom_id: $reputationdotcom_id
      reputationdotcom_reviews_enabled: $reputationdotcom_reviews_enabled
      review_sources: $review_sources
      limit: $limit
    ) {
      edges {
        id
        propertyId
        review
        rating
        reviewer
        review_url
        share_url
        review_date
        review_time
        user_profile_image
        published
      }
      pages {
        cursor
        current
        pages
        items
      }
    }
  }
`;
