import React from "react";
import get from "lodash/get";
import { withRouter } from "react-router";
import GridHighlightImagesView from "../../components/GridHighlightImagesView";
import PrismicDom from "prismic-dom";
import MultiLineString from "../../helpers/mutli-line-string";
import styles from "./styles.module.css";
import "./index.css";
import { personaColorStyle } from "../../helpers/property/persona";
import { htmlSerializerFunc } from "../../helpers/prismic/richTextField";
import { handlePrismicLink } from "../../helpers/link-helper";
import { addOrReplaceUrlParam, addQualityParam } from "../../helpers/add-or-replace-url-param";
import { LazyLoaderElement, LazyLoader } from "../../helpers/lazy-loader";

class GridHighlightImages extends React.Component {
    componentDidMount() {
        //#region SETUP lazy loading
        const { data } = this.props;

        const image1 = get(data, "primary.image_1.url");
        const image2 = get(data, "primary.image_2.url");
        const image3 = get(data, "primary.image_3.url");
        const image4 = get(data, "primary.image_4.url");
        const imageTagline1 = get(data, "primary.image_tagline_1");
        const imageTagline2 = get(data, "primary.image_tagline_2");
        const imageTagline3 = get(data, "primary.image_tagline_3");
        const imageTagline4 = get(data, "primary.image_tagline_4");
        const linkImage = get(data, "primary.link_background_image.url");

        const mainImageLarge = get(data, "primary.main_image.url");
        const mainImageAlt = get(data, "primary.main_image_alt_text");

        const width = this.props.pageWidth
        const mainImage = addOrReplaceUrlParam(mainImageLarge, 'w', width > 1024 ? width * 1.25 : width * 2);

        let lazyLoaderElementList = [];

        let lazyLoaderElementItem = new LazyLoaderElement();

        //STORE images TO lazy load
        lazyLoaderElementItem = new LazyLoaderElement();
        lazyLoaderElementItem.Id = "grid_highlight_image1";
        lazyLoaderElementItem.BackgroundImage = `url(${addQualityParam(image1, 5)})`;

        lazyLoaderElementList.push(lazyLoaderElementItem);

        lazyLoaderElementItem = new LazyLoaderElement();
        lazyLoaderElementItem.Id = "grid_highlight_image2";
        lazyLoaderElementItem.BackgroundImage = `url(${addQualityParam(image2, 5)})`;

        lazyLoaderElementList.push(lazyLoaderElementItem);

        lazyLoaderElementItem = new LazyLoaderElement();
        lazyLoaderElementItem.Id = "grid_highlight_image3";
        lazyLoaderElementItem.BackgroundImage = `url(${addQualityParam(image3, 5)})`;

        lazyLoaderElementList.push(lazyLoaderElementItem);

        lazyLoaderElementItem = new LazyLoaderElement();
        lazyLoaderElementItem.Id = "grid_highlight_image4";
        lazyLoaderElementItem.BackgroundImage = `url(${addQualityParam(image4, 5)})`;

        lazyLoaderElementList.push(lazyLoaderElementItem);

        lazyLoaderElementItem = new LazyLoaderElement();
        lazyLoaderElementItem.Id = "grid_highlight_link_background_image";
        lazyLoaderElementItem.BackgroundImage = `linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(${addQualityParam(linkImage, 5)})`;

        lazyLoaderElementList.push(lazyLoaderElementItem);

        lazyLoaderElementItem = new LazyLoaderElement();
        lazyLoaderElementItem.Id = "sustainablity_highlights_image";
        lazyLoaderElementItem.Src = addQualityParam(mainImage, 5);
        lazyLoaderElementItem.SrcSet = addQualityParam(mainImage, 5);
        lazyLoaderElementItem.Alt = mainImageAlt || "";

        //lazyLoaderElementList.push(lazyLoaderElementItem);

        const lazyLoader = new LazyLoader();
        lazyLoader.LazyLoad(lazyLoaderElementList);

        //#endregion
    }

    render() {
        const { data } = this.props;
        const subtitle = get(data, "primary.subtitle");
        const title = get(data, "primary.title");
        const blurb = get(data, "primary.amenities_blurb", []);
        const image1 = get(data, "primary.image_1.url");
        const image2 = get(data, "primary.image_2.url");
        const image3 = get(data, "primary.image_3.url");
        const image4 = get(data, "primary.image_4.url");
        const imageTagline1 = get(data, "primary.image_tagline_1");
        const imageTagline2 = get(data, "primary.image_tagline_2");
        const imageTagline3 = get(data, "primary.image_tagline_3");
        const imageTagline4 = get(data, "primary.image_tagline_4");
        const linkImage = get(data, "primary.link_background_image.url");
        const property =
            get(this.props, "property") || get(this.props, "page.data");
        const linkData = handlePrismicLink(
            this.props,
            get(data, "primary.link_url"),
            { propertyData: get(this.props, "page") }
        );

        const linkText = get(data, "primary.link_text");

        const secondaryColor = personaColorStyle(property, 'secondary');
        const htmlSerializer = htmlSerializerFunc(secondaryColor.color);
        const blurbHtml = PrismicDom.RichText.asHtml(blurb, undefined, htmlSerializer);

        return (
            <GridHighlightImagesView>
                <subtitle style={personaColorStyle(property, "secondary")}>
                    {subtitle}
                </subtitle>
                <title className={styles.title} style={personaColorStyle(property)}>
                    {title}
                </title>
                <blurb>
                    <wrapper>
                        <div className={styles.bodyContainer} dangerouslySetInnerHTML={{ __html: blurbHtml }} />
                    </wrapper>
                </blurb>
                <image-1
                    className={styles.image}
                >
                    <image-tagline-1 className={styles.text}>
                        {imageTagline1}
                    </image-tagline-1>
                </image-1>
                <image-2
                    className={styles.image}
                >
                    <image-tagline-2 className={styles.text}>
                        {imageTagline2}
                    </image-tagline-2>
                </image-2>
                <image-3
                    className={styles.image}
                >
                    <image-tagline-3 className={styles.text}>
                        {imageTagline3}
                    </image-tagline-3>
                </image-3>
                <image-4
                    className={styles.image}
                >
                    <image-tagline-4 className={styles.text}>
                        {imageTagline4}
                    </image-tagline-4>
                </image-4>
                <link-background-image>
                    <link-url className={styles.button} {...linkData}>
                        <link-text
                            style={personaColorStyle(property)}
                            className={styles.buttonText}
                        >
                            {MultiLineString(linkText)}
                        </link-text>
                        <link-arrow className={styles.buttonArrow} alt="Arrow icon." />
                    </link-url>
                </link-background-image>
            </GridHighlightImagesView>
        );
    }
}

export default withRouter(GridHighlightImages);
