import React from "react";
import get from "lodash/get";
import { withRouter } from "react-router-dom";
import ContentWithFactView from "../../components/ContentWithFactView";
import BackgroundImageHandler from "../../helpers/components-data-handlers/background-image-handler";
import styles from './styles.module.css';
import HighlightWords from "../../helpers/highlight-words";
import TextWritter from "../../helpers/text-writter";
import { handlePrismicLink } from "../../helpers/link-helper";
import './index.css'

export default withRouter(props => {
    const image = get(props, "slice.primary.image");
    const imageProps = BackgroundImageHandler(image);
    const titleValue = get(props, "slice.primary.title", "");
    const titleHighlighedWord = get(props, "slice.primary.title_highlight_word", "");
    const title = HighlightWords(
        titleValue || "Greener.",
        titleHighlighedWord,
        "green"
    );
    const body = get(props, "slice.primary.body1", []).map(bd => TextWritter(bd));
    const fact = get(props, "slice.primary.fact", "");
    const link_text = get(props, "slice.primary.link_text", "");
    const linkData = handlePrismicLink(props, get(props, "slice.primary.link_url"));
    const items = get(props, "slice.items", []);

    const reportItems = [...new Set(items.map(report => report.archived_category_name))];

    const esg_report = get(props, "slice.primary.corporate_responsibility_report.url", "");   
    const esg_report_text = get(props, "slice.primary.link_text", "");

    const tcfd_report = get(props, "slice.primary.tcfd_report.url", "");
    const tcfd_report_text = get(props, "slice.primary.link_text_2", "");

    return (
        <ContentWithFactView>
            <image {...imageProps} />
            <title className={styles.title}>{title}</title>
            <body>
            <span>{body}</span>
            </body>

            <fact>                
                {<div className={styles.section_tagline}>{fact}</div>}
             
                <fact-archived></fact-archived>      
            </fact>

            <fact-details>
                <div className={styles.reports_container}>
                    {reportItems.map((category, categoryIndex) => {
                        // Filter the items for the current category
                        const reports = items.filter(report => report.archived_category_name === category);

                        // Determine if the current category is ESG to conditionally render the image
                        const isESG = (category) ? category.toUpperCase() === "ESG" : true;

                        return (
                            <div key={categoryIndex} className={styles.reports_columns}>
                                <div className={styles.reports_item_image_container}>
                                    <img
                                        src={isESG ? esg_report : tcfd_report}
                                        alt={isESG ? "ESG Report Thumbnail" : "TCFD Report Thumbnail"}
                                        className={styles.reports_item_image}
                                    />
                                </div>
                                <div className={styles.reports_items}>
                                    <div className={styles.reports_item_title}>{isESG ? esg_report_text : tcfd_report_text}</div>

                                    <div>
                                        {reports.map((report, index) => (
                                            <div key={index}>
                                                <a className={"af-class-content-with-fact-grid-item-link"} href={report.archived_version_weblink.url} target="_blank" rel="noopener noreferrer">
                                                    {report.archived_version_year[0].text}
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </fact-details>
        </ContentWithFactView>
    );
});
