const BackgroundImageHandlerSocialFeed = (backgroundImage) =>
  backgroundImage && backgroundImage.url
    ? {
        style: {
          backgroundImage: `url(${backgroundImage.url})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          height: "280",
          objectFit: "contain"
        }
      }
    : {};
export default BackgroundImageHandlerSocialFeed;