import React from "react";
import get from "lodash/get";
import Helmet from "react-helmet-async";
import ExtractMeta from '../../extract-meta';
import { graphql } from "react-apollo";
import componentMapper from "../../component-mapper";
import BuildCanonicalLink from "../../build-canonical-link";
import { furnishedQuery } from "../../../graphql/queries/furnishedQuery";
import { getRelatedFurnishedPage } from "../../get-related-property-pages";

const furnished = props => {
    const { property } = props;
    const furnished = getRelatedFurnishedPage(props.property.id, props.appdata);
    const canonicalLink = BuildCanonicalLink(props, 'property:furnished');
    const meta = ExtractMeta(get(props, "data.furnished"), canonicalLink) || ExtractMeta(property, canonicalLink);
    const slices =
        get(props, "preview.data.body") ||
        get(props, "data.furnished.data.body") ||
        get(furnished, "data.body") ||
        [];

    try {
        if ((props.data.furnished.data == null) || (props.data.furnished.data.body == null)) {
            let redirectUrl = window.location.href.substring(0, window.location.href.lastIndexOf('/'));
            window.location.replace(redirectUrl);
        }
    } catch (ex) {
        //console.log("error in collecting furnished data");
    }

    const SliceComp = slices
        .map((slice, index) => {
            const Component = componentMapper(slice.slice_type);
            if (!Component) {
                return null;
            }

            return (
                <Component
                    key={`${slice.slice_type}_${index}`}
                    slice={slice}
                    property={property}
                    route={props.configs.subroute}
                />
            );
        })
        .filter(slice => !!slice);

    return (
        <>
            <Helmet>
                {meta}
            </Helmet>

            {SliceComp}
        </>
    );
};

export const furnishedHOC = graphql(furnishedQuery, {
    name: "furnished",
    options: props => ({
        variables: {
            propertyPageId: props.property.id
        }
    })
});

export const furnishedSlices = furnishedHOC(furnished);
