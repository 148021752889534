import get from 'lodash/get';
import React from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import Select from 'react-select';
import FooterView from '../../components/FooterView';
import NavigationHeaderView from '../../components/NavigationHeaderView';
import ResidentPortalFeedbackView from '../../components/ResidentPortalFeedbackView';
import { pagesQuery } from '../../graphql/queries';
import GatherNavData from '../../helpers/gather-navbar-data';
import { ViewCapture } from '../../helpers/plugins/guestAnalytics';
import './index.css';
import { dropdownStyles } from './selectStyles';

import { getItemString } from '../../helpers/cookies';
import TopAlertBanner from '../../helpers/top-alert-banner';
import styles from './styles.module.css';

const regionSort = (a, b) => (a.label > b.label ? 1 : -1);

class ResidentPortalFeedback extends React.Component {
  componentDidMount() {
    ViewCapture('Apply Now');
    try {
      if (window) {
        if (window.location.search) {
          const urlParams = new URLSearchParams(window.location.search);
          let smartofficeGeneratedQuoteId = Number(urlParams.get('smartofficeGeneratedQuoteId'));
          this.props.history.push('/create-profile', { smartofficeGeneratedQuoteId: smartofficeGeneratedQuoteId });
        }
      }
    } catch (ex) {
      this.props.history.push('/create-profile');
    }
  }

  state = {
    selectedRegion: null,
    showmenu: false
  };

  render() {
    const { props } = this;
    let title = 'WELCOME TO ONLINE LEASING!';
    let text = 'Choose your community and log in to manage your AMLI quotes. Applying for a new apartment has never been easier!';
    let htmlTitle = 'Apply For Your New Apartment | AMLI Residential';
    // TODO: This legacy app should ideally use ExtractMeta and BuildCanonicalLink helpers for meta/link tags,
    // but since this app will likely be deprecated, we're keeping the direct meta tag approach for now
    let metaDescription =
      'Ready to find your new home? Applying for a new apartment has never been easier. Select your community, manage quotes online, and apply online.';
    const navData = GatherNavData(props);
    const sessionId = getItemString('session_uuid');

    const communityOptions = this.state.selectedRegion
      ? [
          {
            label: `←  ${this.state.selectedRegion.label}`,
            value: null
          },
          ...(props.communities || [])
            .find(r => r.uid === this.state.selectedRegion.uid)
            .children.filter(item => item.data.header === 'Property: Full' && item.data.go_dark !== 'Yes')
            .sort((a, b) => (a.value > b.value ? 1 : -1))
        ]
      : get(props, 'communities', [])
          .sort(regionSort)
          .map(i => ({ ...i, label: i.label, value: i.uid }));

    const pages = get(props, 'data.pages', []);
    const homePage = pages.find(pg => pg.uid === 'home');

    const showAlertBanner = get(homePage, 'data.show_alert_banner');
    return (
      <React.Fragment>
        {showAlertBanner && <TopAlertBanner data={homePage} />}
        <span className="af-view" style={{ width: '100%', height: '100%', flex: 1 }}>
          <div className={showAlertBanner && styles.hasAlertBanner}>
            <NavigationHeaderView.Controller {...props} links={navData} />
            <div className="w-embed">
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "\n  .af-view .af-class-list-blocks-b-section-label::after {\n    content: '';\n    display: block;\n    background-color: #ECD925;\n    position: absolute;\n    bottom: 5px;\n    height: 3px;\n    left: -3px;\n    right: -3px;\n  }\n  @media (min-width: 1700px) {\n    .af-view .af-class-about-header {\n      height: 50vh;\n      min-height: 500px;\n      max-height: 50vh;\n    }\n    .af-view .af-class-list-blocks-blurb-content {\n      padding-top: 100px;\n      padding-right: 100px;\n      padding-bottom: 100px;\n    }\n    .af-view .af-class-list-blocks-b-content-container {\n      flex-basis: 450px;\n    }\n    .af-view .af-class-list-blocks-blurb-tagline {\n      font-size: 48px;\n      line-height: 58px;\n    }\n    .af-view .af-class-list-blocks-blurb-tagline {\n      max-width: 700px;\n    }\n    .af-view .af-class-list-detailed-lists-column {\n      justify-content: flex-start;\n      padding-left: 100px;\n    }\n  }\n"
                }}
              />
            </div>
            <ResidentPortalFeedbackView>
              <title className={styles.title}>{title}</title>
              <text>{text}</text>
              <htmlTitle>{htmlTitle}</htmlTitle>
              <metaDescription>{metaDescription}</metaDescription>
              <metaCanonical>https://www.amli.com/applynow</metaCanonical>
              <community-dropdown
                onClick={() => this.setState({ showmenu: true })}
                onTouchStart={() => this.setState({ showmenu: true })}
                onKeyDown={e => (e.keyCode === 13 && this.setState({ showmenu: true })) || (e.keyCode === 27 && this.setState({ showmenu: false }))}
                tabIndex={0}
              >
                <Select
                  id="community-dropdown"
                  className={styles.dropdownContainer}
                  closeMenuOnSelect={false}
                  menuIsOpen={this.state.showmenu}
                  styles={dropdownStyles}
                  blurInputOnSelect={false}
                  classNamePrefix="dropdown"
                  placeholder="Select a Community"
                  aria-label="Select a Community"
                  onBlur={() => this.setState({ showmenu: false })}
                  options={communityOptions}
                  onChange={e => {
                    document.querySelector('#community-dropdown .dropdown__menu-list').scrollTop = 0;
                    if (!!e.children && e.children.length) {
                      this.setState({ selectedRegion: e });
                    } else {
                      this.setState({
                        selectedRegion: e.value ? this.state.selectedRegion : null,
                        showmenu: e.value === null
                      });

                      if (window && window.open && e.data && e.data.lease_service !== 'RealPage' && e.data.entrata_domain) {
                        window.open(
                          `https://${e.data.entrata_domain}-prospectportal.amli.com/Apartments/module/application_authentication?external_session_tracking_id=${sessionId}`
                        );
                      } else if (e.data && e.data.lease_service === 'RealPage') {
                        props.history.push('/create-profile', { property: e, loginForm: true });
                      }
                    }
                  }}
                />
              </community-dropdown>
            </ResidentPortalFeedbackView>
            <FooterView.Controller {...props} links={navData.footerLinks} />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </React.Fragment>
    );
  }
}

export default withRouter(graphql(pagesQuery)(ResidentPortalFeedback));
