/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from '../helpers'



let Controller

class QuoteSummaryCardPrintView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/QuoteSummaryCardPrintController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = QuoteSummaryCardPrintView

        return Controller
      }

      throw e
    }
  }

  render() {
    const proxies = Controller !== QuoteSummaryCardPrintView ? transformProxies(this.props.children) : {
      'floorplan-details': [],
      'floorplan-name': [],
      'unit-name': [],
      'plant-image': [],
      'beds': [],
      'baths': [],
      'sqft': [],
      'floor': [],
    }

    return (
      <React.Fragment>

        <span className="af-view" style={{width: '100%', height: '100%', flex: 1}}>
          <div className="af-class-quote-summary-unti-col">
            {map(proxies['floorplan-details'], props => <div {...{...props, className: `af-class-floorplan-card-profile af-class-quote-summary af-class-available ${props.className || ''}`}}>{createScope(props.children, proxies => <React.Fragment>
                        {props.topelement ? props.topelement() : null}

              <div className="af-class-floorplan-card-content">
                <div className="af-class-div-block-272 top-row-print-summary">
                  <div className="af-class-floorplan-card-header-col af-class-left-aligned">
                    <div className="af-class-floorplan-card-label">Floorplan</div>
                    {map(proxies['floorplan-name'], props => <div {...{...props, className: `af-class-floorplan-card-bold-heading ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>E210</React.Fragment>}</div>)}
                  </div>
                  <div className="af-class-floorplan-card-header-col af-class-right-aligned">
                    <div className="af-class-floorplan-card-label">Unit</div>
                    {map(proxies['unit-name'], props => <div {...{...props, className: `af-class-floorplan-card-bold-heading ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Polk - 916</React.Fragment>}</div>)}
                  </div>
                </div>
                <div className="af-class-floorplan-card-image-col af-class-profile image-print-summary">{map(proxies['plant-image'], props => <img src="/images/Studio2x.png" alt="" {...{...props, className: `af-class-floorplan-card-preview ${props.className || ''}`}}>{props.children}</img>)}</div>
                <div className="af-class-card-details-row row-print-summary">
                  <div className="af-class-floorplan-card-col af-class-padded-small">
                    {map(proxies['beds'], props => <div {...{...props, className: `af-class-floorplan-card-profile-col-label ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>1 Bedrooms</React.Fragment>}</div>)}
                  </div>
                  <div className="af-class-floorplan-card-col af-class-padded-small">
                    {map(proxies['baths'], props => <div {...{...props, className: `af-class-floorplan-card-profile-col-label ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>1 Bath</React.Fragment>}</div>)}
                  </div>
                  <div className="af-class-floorplan-card-col af-class-padded-small">
                    {map(proxies['sqft'], props => <div {...{...props, className: `af-class-floorplan-card-profile-col-label ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>4,695 Sq Ft</React.Fragment>}</div>)}
                  </div>
                  <div className="af-class-floorplan-card-col af-class-padded-small af-class-no-border">
                    {map(proxies['floor'], props => <div {...{...props, className: `af-class-floorplan-card-profile-col-label ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>4th Floor</React.Fragment>}</div>)}
                  </div>
                </div>
              </div>
            </React.Fragment>)}</div>)}
          </div>
        </span>
      </React.Fragment>
    )
  }
}

export default QuoteSummaryCardPrintView

/* eslint-enable */
