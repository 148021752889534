import React from "react";
import { get } from "lodash";

import styles from "./styles.module.css";
import { personaColorStyle } from "../../helpers/property/persona";

const CorporateFurnishedBlockController = (props) => {
    const primaryColor = get(props, "property.data.primary_color");
    const propertyUID = get(props, "property.uid");
    const propertyName = get(props, "property.data.name");
    let dailyRate, blockTitle, blurbText, imageSrc, imageAltText;
    const contactHref = `/contact?topic=community&propertyUid=${propertyUID}&propertyName=${propertyName}&residentOption=nonResident&nonResidentOptionSelect=short-term-housing`;
    const property = get(props, 'property');

    if (!props.data.loading) {
        blurbText = get(props, "data.furnished.data.body[0].primary.text_blurb[0].text");
        blockTitle = get(props, "data.furnished.data.body[0].primary.title");
        dailyRate = get(props, "data.furnished.data.daily_rate").toLocaleString();
        imageSrc = get(props, "data.furnished.data.header_image.url");
        imageAltText = get(props, "data.furnished.data.header_image.alt");
    }

    const getTintColor = (color) => {
        const [red, green, blue] = color.slice(1).match(/.{1,2}/g).map(hex => parseInt(hex, 16));
        return `rgba(${red}, ${green}, ${blue}, 0.1)`
    };

    return (
        <span class="af-view" style={{ width: "100%", height: "100%", flex: "1 1 0%" }}>
            <section id="short-term-furnished" className={styles.furnishedBlockContainer} style={{ backgroundColor: getTintColor(primaryColor) }}>
                <div className={styles.imageContainer}>
                    <img src={imageSrc} alt={imageAltText}></img>
                </div>
                <div className={styles.content}>
                    <h2 style={personaColorStyle(property, 'secondary')} className={styles.contentLabel}>Corporate Furnished</h2>
                    <h1 className={styles.contentHeader}>{blockTitle}</h1>
                    <p className={styles.contentBlurb}>{blurbText}</p>
                    <a href={contactHref} className={styles.requestInfoLink}>
                        <div className={styles.requestInfo} style={{ backgroundColor: primaryColor }}>
                            <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.625 1.4L2.14732 0L9.7589 7L2.14732 14L0.625 12.6L6.6667 7L0.625 1.4Z" fill="white" />
                            </svg>
                            <p>Request Information</p>
                        </div>
                    </a>
                </div>
                <div className={styles.priceTag} style={{ backgroundColor: primaryColor }}>
                    <h2>Starting at</h2>
                    <p>{`$${dailyRate}/day`}</p>
                </div>
            </section>
        </span>
    )
}

export default CorporateFurnishedBlockController;