import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { get, capitalize } from 'lodash';
import { getItem } from '../../helpers/cookies';
import styles from './styles.module.css';
import QuoteSummaryCardPrintView from '../../components/QuoteSummaryCardPrintView';
import QuoteSummaryColumnPrintView from '../../components/QuoteSummaryColumnPrintView';
import LeaseAddonsPrint from '../LeaseAddonsPrint';
import PaymentBreakdownPrint from '../PaymentBreakdownPrint';

import GatherNavData from '../../helpers/gather-navbar-data';
import { ViewCapture } from '../../helpers/plugins/guestAnalytics';
const moment = require('moment-timezone');
function UserQuoteDetailsPrint(props) {
  const user = getItem('user');
  const navData = GatherNavData(props);

  //#region 1. HANDLE STARTUP
  useEffect(() => {
    ViewCapture('Quote Details');
  }, []);
  if (!user || !user.userId) {
    props.history.push(`/create-profile`);
    return null;
  }
  //#endregion

  //#region 2. DEFINE MEMORIES

  //#region Apartment Location Settting
  const regions = get(props, 'data.pages', []).filter(page => page && page.type === 'region');
  const subregions = get(props, 'data.pages', []).filter(page => page && page.type === 'sub-region');
  const properties = get(props, 'data.pages', []).filter(page => !!page && !!page.data && !!page.type && page.type === 'property');
  //#endregion

  //#region Apartment Webpage Experience
  const standard_page = get(props, 'data.pages', []).filter(page => !!page && !!page.data && !!page.type && page.type === 'standard_page');
  //#endregion

  //#region Apartment Financial Experience
  const quoteId = get(props, 'match.params.id');
  const quote = (props.quotesQuery.quotes || []).find(quote => quote.quoteId === quoteId);
  if (!quote || !properties) {
    return null;
  }

  const quoteProp = { quote: quote };

  const isExpired = moment(quote.quoteExpirationDate).isBefore(moment());
  if (isExpired) {
    return null;
  }
  //#endregion

  //#region Apartment Branding Experience
  const apartmentPropertyId = quote.property.propertyId;

  let apartmentLogo = '/images/Logo_Dark.svg'; //IN CASE OF ERRORS
  let apartmentPropertyDetails = null;
  if (apartmentPropertyId) {
    apartmentPropertyDetails = properties.find(property => property.data.amli_id === apartmentPropertyId);
    if (apartmentPropertyDetails) apartmentLogo = apartmentPropertyDetails.data.logo.url;
  }
  //#endregion

  //#region Apartment Sales Experience

  let specials = [];

  if (apartmentPropertyDetails) {
    specials = apartmentPropertyDetails.data.specials.filter(special =>
      moment
        .tz(special.expiration_date, 'MM/DD/YYYY', 'America/Chicago')
        .endOf('day')
        .isAfter(moment().tz('America/Chicago'))
    );
  }

  const propertyData = get(quote, 'property');
  //#endregion

  //#endregion

  return (
    <div id="container" className={styles.container_1}>
      {/* ROW 1 */}
      <div className={styles.row_1_1}>
        <img src={apartmentLogo} className={styles.logo} alt="Property Logo" />
        <div className={styles.content_1_1_1_apartment_address}>
          <p className={styles.addressRow}>{propertyData.propertyAddress1}</p>
          <p className={styles.addressRow}>{`${propertyData.city},${propertyData.state} ${propertyData.zipCode.split('-')[0]}`}</p>
          <p className={styles.addressPhone}>{propertyData.phoneMain}</p>
        </div>
      </div>

      {/* ROW 2 */}
      {specials && specials.length && (
        <div className={styles.row_1_2}>
          <div className={styles.content_1_2_1_apartment_greeting}>
            Hey {capitalize(user.firstName)},<p>Here's your quote summary.</p>
          </div>

          <div className={styles.content_1_2_2_apartment_specials_1}>
            <div className={styles.specialsLabel}>
              <img src="/images/icon-special-fire3x.svg" alt="specials icon" className={styles.specialsImg} />
              <div>Current Specials</div>
            </div>
            <div className={styles.content_1_2_2_apartment_specials_1_1}>{specials[0].special_text}</div>
          </div>
        </div>
      )}

      {/* ROW 3 */}
      <div className={styles.row_1_3}>
        <div style={{ width: '60%', maxHeight: '400px !important' }}>
          <QuoteSummaryCardPrintView.Controller {...quoteProp} />
        </div>
        <div style={{ width: '40%', maxHeight: '400px !important' }}>
          <QuoteSummaryColumnPrintView.Controller {...quoteProp} />
        </div>
      </div>

      {/* ROW 4 */}
      <div className={styles.row_1_4}>
        <div className={styles.bottomColumnLeft}>
          <div className={styles.paymentHeader}>Payment Breakdown</div>
          <PaymentBreakdownPrint quote={quote} />
        </div>
        <div className={styles.bottomColumnRight}>
          <div className={styles.paymentHeader}>Lease Add-ons</div>
          <LeaseAddonsPrint quote={quote} />
        </div>
      </div>
    </div>
  );
}
export default withRouter(UserQuoteDetailsPrint);
