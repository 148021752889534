/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from '../helpers'



let Controller

class GridSocialCardTaglineView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/GridSocialCardTaglineController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = GridSocialCardTaglineView

        return Controller
      }

      throw e
    }
  }

  render() {
    const proxies = Controller !== GridSocialCardTaglineView ? transformProxies(this.props.children) : {
      'wrapper': [],
      'description': [],
      'button': [],
      'label': [],
      'arrow': [],
    }




    return (
      <React.Fragment>

        <span className="af-view" style={{width: '100%', height: '100%', flex: 1}}>
          <div className="af-class-div-block-458">
            {map(proxies['wrapper'], props => <a href="/#" {...{...props, className: `af-class-link-block-10 af-class-factiod w-inline-block ${props.className || ''}`}}>{createScope(props.children, proxies => <React.Fragment>
                        {props.topelement ? props.topelement() : null}

              <div className="af-class-div-block-459 af-class-blur af-class-cover">
                {map(proxies['description'], props => <div {...{...props, className: `af-class-social-grid-factoid-title ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Apartments are the answer for how to have a <span className="af-class-green-highlight">greener</span>, healthier and more <span className="af-class-green-highlight">responsible</span> life. </React.Fragment>}</div>)}
                {map(proxies['button'], props => <div {...{...props, className: `af-class-div-block-460 ${props.className || ''}`}}>{createScope(props.children, proxies => <React.Fragment>
                        {props.topelement ? props.topelement() : null}

                  {map(proxies['label'], props => <div {...{...props, className: `af-class-text-block-131 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Learn More</React.Fragment>}</div>)}{map(proxies['arrow'], props => <img src="/../images/arrow--long-right.png" alt="Arrow icon." {...{...props, className: `af-class-image-60 ${props.className || ''}`}}>{props.children}</img>)}</React.Fragment>)}</div>)}
              </div>
            </React.Fragment>)}</a>)}
          </div>
        </span>
      </React.Fragment>
    )
  }
}

export default GridSocialCardTaglineView

/* eslint-enable */