import React, { useState, useEffect } from "react";
import GetParentRegion from "../get-parent-region";
import { bodyBuilder, HOCgetter, BodyHOCGetter } from "./property/index";
import componentMapper from "../component-mapper";
import PropertyHeaderRender from "./property/header-renderer";
import PropertyNavRender from "./property/nav-renderer";
import { ViewCapture, EventViewProperty } from "../plugins/guestAnalytics.js";
import { canUseDOM } from "../../helpers/utils";
import get from "lodash/get";
import { FloorplansMeta } from "./property/floorplans";
import { ContactMeta } from "./property/contact";
import { FloorPlanMeta } from "./property/floorplan";
import TopAlertBanner from "../top-alert-banner";
import PropertyBannerController from "../../controllers/PropertyBannerController";
import styles from './style.module.css';
import { addQualityParam } from "../add-or-replace-url-param";

import EntrataWidget from "../entrata-widget";
import EliseChatWidget from "../elisechat-widget";

const propertyStates = { maps: null };
export default function _propertyRenderer({
    property,
    preview,
    components,
    configs,
    refresh,
    ...props
}) {
    const [mapState, setMapState] = useState(propertyStates);
    const [pageWidth, setPageWidth] = useState(null);
    const [headerReady, setHeaderReady] = useState(true);

    let showEntrataOnOverViewPage = false;
    let referralPage;
    
    if (canUseDOM) {
        // Check if we're on a short-url
        const isShortURL = window.location.pathname.split('/').filter(part => part).length === 1;

        if (isShortURL) {
          // We're on a short-url
          // Find the full path to the property...
          // ...Which will be the longest path in the 'match' object
          const fullPath = props.match.path.sort((a, b) => b.length - a.length)[0];
          // Reload the page with the full URL
          window.location.href = fullPath;
        }
        
        try {
            referralPage = JSON.parse(localStorage.getItem("referralPage"));
        } catch (e) {
            console.log(e)
        }
    }
    const updatePropertyMapState = newMapState =>
        setMapState({ ...mapState, ...newMapState });
    useEffect(() => {
        if (canUseDOM) {
            setPageWidth(window.innerWidth);
        }
    }, []);
    useEffect(() => {
        ViewCapture("Property/Floor/Unit"); //this is the only place that needs debounce in guestAnalytics.js
        EventViewProperty(get(property, "data.amliPropertyId"));
    }, []);
    let Header = PropertyHeaderRender(property, configs);
    const Nav = PropertyNavRender(property, configs);

    let Body, Slices, HOC, BodyHOC;
    let Components = components;
    if (configs && configs.subroute) {
        configs = { ...configs, mapState, updatePropertyMapState };
        Body = bodyBuilder[configs.subroute];
        HOC = HOCgetter[configs.subroute];
        BodyHOC = BodyHOCGetter[configs.subroute];
    } else
    {
        Slices = (property.data.body || []).map((slice, index) => {
            const Component = componentMapper(slice.slice_type);
            if (!Component) {
                return null;
            }

            return (
                <Component
                    key={`${slice.slice_type}_${index}`}
                    data={slice}
                    slice={slice}
                    page={property}
                    pageWidth={pageWidth}
                />
            );
        });

        showEntrataOnOverViewPage = true;
        HOC = HOCgetter.overview;
    }
    Components = {
        ...Components
    };

    if (HOC) {
        Header = HOC(Header);
    }

    if (BodyHOC) {
       Body = BodyHOC(Body);
    };

    const hasOwnMetaFromOwnDocument =
        configs.subroute && ["amenities", "location", "furnished"].includes(configs.subroute);
    const hasSpecificMetaFromPropertyDocument =
        configs.subroute && ["floorplans", "contact", "floorplan", "grid"].includes(configs.subroute);
    let SpecificMeta = undefined;
    if (hasSpecificMetaFromPropertyDocument) {
        if (configs.subroute === "floorplans") {
            SpecificMeta = FloorplansMeta;
        }
        if (configs.subroute === "contact") {
            SpecificMeta = ContactMeta;
        }
        if (configs.subroute === "floorplan" || configs.subroute === "grid") {
            SpecificMeta = FloorPlanMeta;
        }
    }

    const pages = get(props, "nav.standard_page", []);
    const homePage = pages.find(pg => pg.uid === "home");

    const showAlertBanner = get(homePage, "data.show_alert_banner");
    const showPropertyBanner = get(property, "data.property_banner[0].show_property_banner", "");
    const showPropertyBannerDefault = get(property, "data.property_banner", "");
    const mainPropertyImageUrl = get(property, "data.primary_property_image.url", "");
    const headerType = get(property, "data.header");
    const subrouteWithNoHeader = configs.subroute === "floorplan" || configs.subroute === "grid";
    if (!headerReady) {
        setTimeout(() => {
            setHeaderReady(true);
        }, 850);
    }
    const hideEntrataWidget = get(property, "data.hide_entrata_widget");

    return <>
        {showAlertBanner && <TopAlertBanner data={homePage} />}
        {hideEntrataWidget &&
            <style
                dangerouslySetInnerHTML={{
                    __html: `
            #ppcb_btn, #ppcb_notification {
              display: none !important;
            }
          `
                }}
            />
        }
        <div className={showAlertBanner && styles.hasAlertBanner}>
            {
                hasSpecificMetaFromPropertyDocument
                    ? <SpecificMeta page={property} props={props} />
                    : hasOwnMetaFromOwnDocument
                        ? null
                        : !!Components.Metadata
                            ? <Components.Metadata page={property} />
                            : null
            }
            {Nav ? (
                <Nav
                    onMeasure={(width, height) =>
                        configs.updateSize("nav", width, height)
                    }
                    links={configs.headerNav}
                    page={property}
                    homepage={configs.navigation === "Home Page"}
                    route={configs.subroute}
                    mapState={mapState}
                    showAlertBanner={showAlertBanner}
                />
            ) : null}
            {headerType === "Property: Full" &&
                !subrouteWithNoHeader &&
                (showPropertyBanner || showPropertyBannerDefault.length === 0)
                ? <PropertyBannerController property={property} />
                : (headerType !== 'Property: Early Development' 
                    ? <div className={styles.headerSpacer}/> 
                    : null)
            }
            <div
                id="propertyJS"
                className={
                    !configs.subroute &&
                    headerType === "Property: Full" &&
                    styles.wrapper
                }
                style={{
                    width: '100%',
                    height: '100%',
                    flex: '1 1 0%',
                    position: 'relative'
                }}
            >
                {showEntrataOnOverViewPage ? (<EntrataWidget doNotIgnore={true} {...property} />) : null}
                <EliseChatWidget {...property} />

                {Header && !configs.subroute && headerType === "Property: Full" && (
                    <header
                        className={["af-class-property-header", styles.container].join(" ")}
                        style={{
                            zIndex: `${headerReady ? 0 : 2}`,
                            opacity: `${headerReady ? 0 : 1}`,
                            position: 'absolute',
                            backgroundImage: 'none'
                        }}
                    >
                        <img
                            className={styles.image}
                            src={addQualityParam(mainPropertyImageUrl, 5)}
                            alt="property header"
                        />
                    </header>
                )}
                {Header ? (
                    <Header
                        referralPage={referralPage}
                        links={configs.headerNav}
                        page={property.data}
                        property={property}
                        parentRegion={GetParentRegion(property, configs.appdata)}
                        sizes={configs.state.size}
                        route={configs.subroute}
                        appdata={configs.appdata}
                        pageWidth={pageWidth}
                        showAlertBanner={showAlertBanner}
                    />
                ) : null}
            </div>
            {!!Body && (
                <Body
                    property={property}
                    preview={preview}
                    configs={configs}
                    appdata={configs.appdata}
                    pageWidth={pageWidth}
                />
            )}
            {!!Slices && Slices}
            {Components.Footer ? (
                <Components.Footer
                    links={configs.footerNav}
                    data={property}
                    page={property}
                />
            ) : null}
        </div>
    </>;
}
