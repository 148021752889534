import React from "react";
import get from "lodash/get";
import { canUseDOM } from "../../helpers/utils";
import {
  EventViewUnit,
  EventRequestQuoteUnit,
  EventViewPropertyUnits,
  EventViewUnitDetails,
  EventChangeFilters,
} from "../../helpers/plugins/guestAnalytics";

export default class InteractiveMap extends React.Component {
  componentDidMount() {
    const { property } = this.props;

    if (canUseDOM) {
      this.getMetrics(property);
    }
  }

  getMetrics(property) {
    const loadUnitList = () => {
      window.dataLayer.push({
        event: "interact.engrainMap",
        "interact.engrainMap.property": property.data.name,
      });
      const propertyId = get(property, "data.amli_id");
      EventViewPropertyUnits(propertyId);
    };
    const clickOnUnit = (event) => {
      const propertyId = get(property, "data.amli_id");
      const floorplanId = get(event, "data.unit.floorPlan.id");
      const unitId = get(event, "data.unit.id");
      const sqFt = get(event, "data.unit.area");
      const bedrooms = get(event, "data.unit.floorPlan.bedroomCount");
      EventViewUnit(propertyId, floorplanId, unitId, sqFt, bedrooms);
    };

    const tourClicked = (event) => {
      const floorplanId = get(event, "data.unit.floorPlan.id");
      const unitId = get(event, "data.unit.id");

      window.dataLayer.push({
        event: "clickOnScheduleATour",
        floorplan: floorplanId,
        unit: unitId,
      });
      if (!!window && window.ppcb) {
        window.ppcb.open("schedule-tour");
      }
    };

    const clickOnOutbound = (event) => {
      const propertyId = get(property, "data.amli_id");
      const floorplanId = get(event, "data.unit.floorPlan.id");
      const unitId = get(event, "data.unit.id");

      // Check if event is a contact us
      const eventLabel = get(event, "data.label", "");
      if (eventLabel.toLowerCase() === "contact us") {
        window.dataLayer.push({
          event: "clickOnContactUs",
          property: propertyId,
          floorplan: floorplanId,
          unit: unitId,
        });
      }

      const sqFt = get(event, "data.unit.area");
      const bedrooms = get(event, "data.unit.floorPlan.bedroomCount");
      EventViewUnitDetails(propertyId, floorplanId, unitId, sqFt, bedrooms);
    };

    const changedFilter = () => {
      const propertyId = get(property, "data.amli_id");
      EventChangeFilters(propertyId);
    };

    const clickOnQuote = (event) => {
      window.dataLayer.push({
        event: "clickGetQuote",
        "clickGetQuote.property": property.data.name,
        "clickGetQuote.context": "Engrain",
      });
      const propertyId = get(property, "data.amli_id");
      const floorplanId = get(event, "data.unit.floorPlan.id");
      const unitId = get(event, "data.unit.id");
      const sqFt = get(event, "data.unit.area");
      const bedrooms = get(event, "data.unit.floorPlan.bedroomCount");
      EventRequestQuoteUnit(propertyId, floorplanId, unitId, sqFt, bedrooms);
    };

    // eslint-disable-next-line no-undef
    const embed = new SightMap.Embed("sightmap");

    embed.on("metrics.unitList.impression", loadUnitList);
    embed.on("metrics.unitMap.unit.click", clickOnUnit);
    embed.on("metrics.unitList.unit.click", clickOnUnit);
    embed.on("metrics.unitDetails.apply.click", clickOnQuote);
    embed.on("metrics.unitDetails.outbound.click", clickOnOutbound);
    embed.on("metrics.filters.change", changedFilter);
    // custom events
    embed.on("outbound.entrata.tour.click", tourClicked);
  }

  render() {
    let url;
    const { engrainId } = this.props;
    if (!canUseDOM) {
      return null;
    } else {
      url = window.origin;
      return (
        <div
          style={{
            height: "100vh",
            width: "95vw",
            margin: "auto",
            fallbacks: [
              { height: "100vh" },
              { height: "100vh" },
              { height: "100vh" },
            ],
          }}
        >
          <iframe
            title={`interactive_map_${engrainId}`}
            id="sightmap"
            src={`https://sightmap.com/embed/${engrainId}?enable_api=1&origin=${url}`}
            frameBorder="0"
            width="100%"
            height="100%"
          />
        </div>
      );
    }
  }
}
