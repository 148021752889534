import React, { useEffect } from "react";
import get from "lodash/get";
import ContentIntroImageView from "../../components/ContentIntroImageView";
import BackgroundImageHandler from '../../helpers/components-data-handlers/background-image-handler';
import { LazyLoaderElement, LazyLoader } from "../../helpers/lazy-loader";

export default props => {
  const image = get(props, "data.primary.image", "");
  const image_title = get(props, "data.primary.title1", "");
    const imageProps = BackgroundImageHandler(image);

    let lazyLoaderElementList = [];

    useEffect(() => {

        //#region SETUP lazy loading

        //STORE background image TO lazy load
        let lazyLoaderElementItem = new LazyLoaderElement();

        lazyLoaderElementItem.Id = "content_intro_image";
        lazyLoaderElementItem.BackgroundImage = imageProps.style.backgroundImage;
        lazyLoaderElementItem.BackgroundPosition = imageProps.style.backgroundPosition;
        lazyLoaderElementItem.BackgroundSize = imageProps.style.backgroundSize;

        lazyLoaderElementList.push(lazyLoaderElementItem);

        const lazyLoader = new LazyLoader();
        lazyLoader.LazyLoad(lazyLoaderElementList);

        //#endregion
    }, []);

  return (
    <ContentIntroImageView {...props}>
      <bg-image>
        <title>{image_title}</title>
      </bg-image>
    </ContentIntroImageView>
  );
};
