import React, { useRef, useState, useEffect } from 'react';
import styles from './styles.module.css';

const PropertyComparisonFilter = ({
  properties,
  selectedProperties,
  setSelectedProperties,
  setMobileSelectedProperty,
}) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const wrapperRef = useRef(null);

  const initialFilter = {};
  properties.forEach(({ uid }) => {
    initialFilter[uid] = true;
  });
  const [propertyFilter, setPropertyFilter] = useState(initialFilter);

  const handleDropdown = (e) => {
    e.preventDefault();
    setDropdownActive(!dropdownActive);
  };

  const handleClickOutside = (e) => {
    if (dropdownActive && wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setDropdownActive(!dropdownActive);
    }
  };

  const handleCheck = ({
    target: {
      attributes: {
        uid: { value },
      },
    },
  }) => {
    if (selectedProperties.length <= 2 && propertyFilter[value]) {
      setErrorText('At least two properties must be selected');
      return;
    }
    setErrorText(null);
    setPropertyFilter({ ...propertyFilter, [value]: !propertyFilter[value] });
  };

  useEffect(() => {
    setSelectedProperties(
      properties.filter((property) => {
        return propertyFilter[property.uid];
      })
    );
    setMobileSelectedProperty(0);
  }, [propertyFilter]);

  useEffect(() => {
    document.addEventListener('pointerdown', handleClickOutside);
    return () => document.removeEventListener('pointerdown', handleClickOutside);
  }, [dropdownActive]);

  return (
    <div className={styles.dropdownWrapper} ref={wrapperRef}>
      <div onClick={handleDropdown} className={styles.dropdownHeader}>
        <span className={styles.headerText}>Filter Property View </span>
        <span className={styles.dropdownArrow} active={String(dropdownActive)}></span>
        <span className={styles.dropdownError}>{errorText}</span>
      </div>
      <ul className={styles.dropdown} active={String(dropdownActive)}>
        {properties.map(({ uid }) => {
          return (
            <li className={styles.dropdownItem} key={uid}>
              <div
                className={styles.dropdownItemCustomCheckbox}
                ischecked={String(propertyFilter[uid])}
                onClick={handleCheck}
                {...{ uid }}
              />
              <label className={styles.dropdownItemLabel + ' ' + styles.capitalize}>
                {uid.replaceAll('-', ' ').replaceAll('amli', 'AMLI')}
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PropertyComparisonFilter;
