import React from "react";
import { get } from "lodash";
import TourModal from "../TourModalController";
import { EventViewMedia } from "../../helpers/plugins/guestAnalytics.js";
import "./index.css"
import truncate from "lodash/truncate";

class PropertyBannner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTourModal: false,
      tourSelected: 0,
      playTour: false,
    };
  }

  componentDidMount() {
  }
  render() {
    const name = get(this.props, "property.data.name");
    const customHeader = get(this.props.property, "data.property_banner[0].banner_title", "");
    const customBody = get(this.props.property, "data.property_banner[0].banner_body", "");
    const truncateBody = truncate(customBody, {
      length: 320,
      separator: ' ',
      omission: '...',
    });
    const tours = get(this.props, "property.data.3d_tours", []).filter(
      tour => !!tour && !!tour.enabled && tour.enabled === "True"
    );
    const propertyColor = get(this.props.property, "data.primary_color", "");
    const hasTourLinks =
      tours.find(
        tour =>
          tour.enabled === "True" &&
          tour.tour_link &&
          tour.tour_link.url
      );

    function showOtherLang() {
      switch (name) {
        default:
          return false;
        case "AMLI Arista":
          return true;
        case 'AMLI Riverfront Green':
          return true;
        case 'AMLI Littleton Village':
          return true;
        case "AMLI at Inverness":
          return true;
        case 'AMLI Dry Creek':
          return true;
        case 'AMLI Park Avenue':
          return true;
        case "AMLI Denargo Market":
          return true;
        case 'AMLI South Shore':
          return true;
        case 'AMLI Addison':
          return true;
        case "AMLI River Oaks":
          return true;
        case 'AMLI Uptown':
          return true;
        case 'AMLI Memorial Heights':
          return true;
        case 'AMLI at Escena':
          return true;
        case "AMLI Arc":
          return true;
        case 'AMLI Quadrangle':
          return true;
        case 'AMLI Marina Del Rey':
          return true;
        case "AMLI Park Broadway":
          return true;
        case 'AMLI Uptown Orange':
          return true;
      }
    }
    const showOtherLangBool = showOtherLang();

    const isHexColorDark = (color) => {
      const [red, green, blue] = color.slice(1).match(/.{1,2}/g).map(hex => parseInt(hex, 16));
      const luma = (red + green + blue) / 3;
      return luma <= 128;
    }

    const bannerHeadlineLight = "#FFFFFF";
    const bannerHeadlineDark = "#000000";
    const bannerTextLight = "#FCFBFA";
    const bannerTextDark = "#030405";

    if (customHeader || customBody) {
      return (
        <div className="property-banner-custom" style={{ backgroundColor: propertyColor }}>
        <div className="banner-text-container">
          <div className="banner-headline" style={{ color: isHexColorDark(propertyColor) ? bannerHeadlineLight : bannerHeadlineDark }}>
            {customHeader}
          </div>
          <div className="banner-text" style={{ color: isHexColorDark(propertyColor) ? bannerTextLight : bannerTextDark }}>
            {truncateBody}
          </div>
        </div>
        </div>
      )
    }

    return (
      <div className="property-banner">
        <TourModal
          tours={tours}
          selected={this.state.tourSelected}
          open={this.state.showTourModal}
          onRequestClose={() => this.setState({ showTourModal: false })}
          play={this.state.playTour}
          setSelected={selected =>
            this.setState({ tourSelected: selected, playTour: false })
          }
          startPlay={() => this.setState({ playTour: true })}
          title={name}
        />
        <div className="banner-headline" style={{ color: propertyColor }}>
          {showOtherLangBool ? 'Now Offering Virtual & Self-Guided Tours!' : 'Our communities are hosting live virtual tours!'}
        </div>
        <div className="banner-spacer"/>
        <div className="banner-text">
          {showOtherLangBool ?
            'We are available during regular business hours for live virtual tours and contact-free visits with an appointment. Contact us today!' :
            'While our offices are closed due to ongoing concerns related to COVID-19, our leasing team is available during regular business hours for live virtual tours. If you would like to look around on your own,'
          }
          &nbsp;If you would like to look around {showOtherLangBool ? 'first' : ''} on your own, our
        {hasTourLinks && (
            <div className='banner-tour-text'>
              <div
                className='tour-modal-link'
                href="javascript:void(0);" // eslint-disable-line
                onClick={e => {
                  e.preventDefault();
                  this.setState({ showTourModal: true, playTour: false });
                  const { amliPropertyId } = this.props.property.data;
                  EventViewMedia(amliPropertyId, "virtual tour");
                }}
              >
                &nbsp;3D Tours

              </div>
              &nbsp;and
            </div>
          )}
        </div>
        &nbsp;Media Gallery {hasTourLinks ? 'are' : 'is'} available {showOtherLangBool ? 'near the top of the Overview page.' : 'below.'}
      </div>
    );
  };
}

export default PropertyBannner;
