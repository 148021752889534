import { useState, useEffect } from 'react';
import axios from 'axios';

export const useGeolocation = () => {
  const [region, setRegion] = useState([]);

  useEffect(() => {
    axios.get('https://get.geojs.io/v1/ip/geo.json').then((response) => setRegion(response.data.region));
  }, []);

  return { region };
};
