import React from 'react';
import get from "lodash/get";
import { formatDollar } from "../../helpers/utils";
import styles from './styles.module.css';

function renderOption(data, text){
  const { description, amount } = data;
  return (
    <div className={styles.optionWrapper}>
      <div className={styles.name}>{text || description}</div>
      <div className={styles.fee}>${formatDollar(amount, 2)}/mo.</div>
      <div className={styles.background}></div>
    </div>
  )
}
function LeaseAddonsPrint(props) {
  const customRent = get(props, "quote.paymentSummary.customRentSummary", []);
  const rentSchedule = get(props, "quote.paymentSummary.rentSchedule", []);

  return (
    <div className={styles.addonsContainer}>
      {customRent && customRent.length > 0 && customRent.map(cost => renderOption(cost))}
      <div className={styles.rentSchedule}>
        <span>{rentSchedule.length - 2} Monthly Payments of</span>
        <span>${formatDollar(rentSchedule[1].amount, 0)}</span>
      </div>
      {renderOption(rentSchedule[0], "Last Month Payment")}
      {renderOption(rentSchedule[rentSchedule.length-1], "First Month Payment")}
    </div>
  );
}

export default LeaseAddonsPrint;
