import React from 'react';

import styles from './styles.module.css';

const PropertyComparisonTable = ({ title, children, link }) => {
  return (
    <div className={styles.propertyStatsWrapper}>
      {link ? (
        <a href={link}>
          <h4 className={styles.propertySubtitle}>{title}</h4>
        </a>
      ) : (
        <h4 className={styles.propertySubtitle}>{title}</h4>
      )}
      <ul className={styles.propertyStats}>{children}</ul>
    </div>
  );
};

export default PropertyComparisonTable;
